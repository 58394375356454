import { RequestStatus } from "types/common.types";
import { O365UsageModel, UsageAndClientsModel } from "types/startPageApi.types";
import { UsageRequestConfig, O365UsersModel, DetailedUsageModel, UsageType } from "types/usageReports.types";

export interface WithRequestStatus {
  requestStatus: RequestStatus;
}

export type SortFieldType = keyof O365UsageModel | "";
export type SortOrderType = "asc" | "desc";

export interface Usage {
  list: UsageAndClientsModel[] | DetailedUsageModel[];
  type: UsageType;
  config: UsageRequestConfig;
  filters: Record<keyof DetailedUsageModel, string>;
}

export interface O365Usage {
  list: O365UsageModel[];
  config: UsageRequestConfig;
}

export interface O365Users {
  list: O365UsersModel[];
  date: Date;
  filters: { emailAddress: string };
}

export interface UsagePageStateSlice {
  readonly usage: Usage & WithRequestStatus;
  readonly o365Usage: O365Usage & WithRequestStatus;
  readonly o365Users: O365Users & WithRequestStatus;
  readonly sortField: SortFieldType;
  readonly sortOrder: SortOrderType;
  readonly currentPage: number;
  readonly perPage: number;
  readonly sendEmailReportRequestStatus: RequestStatus;
}

export const USAGE_REQUEST = "USAGE_REQUEST";
export type UsageRequestAction = {
  type: typeof USAGE_REQUEST;
};
export type UsageRequestAC = () => UsageRequestAction;

export const USAGE_REQUEST_SUCCESS = "USAGE_REQUEST_SUCCESS";
export type UsageRequestSuccessAction = {
  type: typeof USAGE_REQUEST_SUCCESS;
  payload: UsageAndClientsModel[] | DetailedUsageModel[];
};
export type UsageRequestSuccessAC = (
  payload: UsageAndClientsModel[] | DetailedUsageModel[]
) => UsageRequestSuccessAction;

export const USAGE_REQUEST_FAILURE = "USAGE_REQUEST_FAILURE";
export type UsageRequestFailureAction = {
  type: typeof USAGE_REQUEST_FAILURE;
};
export type UsageRequestFailureAC = () => UsageRequestFailureAction;

export const SET_USAGE_CONFIG = "SET_USAGE_CONFIG";
export type SetUsageConfigAction = { type: typeof SET_USAGE_CONFIG; payload: UsageRequestConfig };
export type SetUsageConfigAC = (payload: UsageRequestConfig) => SetUsageConfigAction;

export const SET_USAGE_TYPE = "SET_USAGE_TYPE";
export type SetUsageTypeAction = { type: typeof SET_USAGE_TYPE; payload: UsageType };
export type SetUsageTypeAC = (payload: UsageType) => SetUsageTypeAction;

export const SET_USAGE_FILTERS = "SET_USAGE_FILTERS";
export type SetUsageFiltersAction = { type: typeof SET_USAGE_FILTERS; payload: Usage["filters"] };
export type SetUsageFiltersAC = (payload: Usage["filters"]) => SetUsageFiltersAction;

export const O365_USAGE_REQUEST = "O365_USAGE_REQUEST";
export type O365UsageRequestAction = {
  type: typeof O365_USAGE_REQUEST;
};
export type O365UsageRequestAC = () => O365UsageRequestAction;

export const O365_USAGE_REQUEST_SUCCESS = "O365_USAGE_REQUEST_SUCCESS";
export type O365UsageRequestSuccessAction = {
  type: typeof O365_USAGE_REQUEST_SUCCESS;
  payload: O365UsageModel[];
};
export type O365UsageRequestSuccessAC = (payload: O365UsageModel[]) => O365UsageRequestSuccessAction;

export const O365_USAGE_REQUEST_FAILURE = "O365_USAGE_REQUEST_FAILURE";
export type O365UsageRequestFailureAction = {
  type: typeof O365_USAGE_REQUEST_FAILURE;
};
export type O365UsageRequestFailureAC = () => O365UsageRequestFailureAction;

export const SET_O365_USAGE_CONFIG = "SET_O365_USAGE_CONFIG";
export type SetO365UsageConfigAction = { type: typeof SET_O365_USAGE_CONFIG; payload: UsageRequestConfig };
export type SetO365UsageConfigAC = (payload: UsageRequestConfig) => SetO365UsageConfigAction;

export const O365_USERS_REQUEST = "O365_USERS_REQUEST";
export type O365UsersRequestAction = {
  type: typeof O365_USERS_REQUEST;
};
export type O365UsersRequestAC = () => O365UsersRequestAction;

export const O365_USERS_REQUEST_SUCCESS = "O365_USERS_REQUEST_SUCCESS";
export type O365UsersRequestSuccessAction = {
  type: typeof O365_USERS_REQUEST_SUCCESS;
  payload: O365UsersModel[];
};
export type O365UsersRequestSuccessAC = (payload: O365UsersModel[]) => O365UsersRequestSuccessAction;

export const O365_USERS_REQUEST_FAILURE = "O365_USERS_REQUEST_FAILURE";
export type O365UsersRequestFailureAction = {
  type: typeof O365_USERS_REQUEST_FAILURE;
};
export type O365UsersRequestFailureAC = () => O365UsersRequestFailureAction;

export const SET_O365_USERS_DATE = "SET_O365_USERS_DATE";
export type SetO365UsersDateAction = { type: typeof SET_O365_USERS_DATE; payload: Date };
export type SetO365UsersDateAC = (payload: Date) => SetO365UsersDateAction;

export const SET_O365_USERS_FILTERS = "SET_O365_USERS_FILTERS";
export type SetO365UsersFiltersAction = { type: typeof SET_O365_USERS_FILTERS; payload: O365Users["filters"] };
export type SetO365UsersFiltersAC = (payload: O365Users["filters"]) => SetO365UsersFiltersAction;

export const SET_USAGE_REPORT_SORT_FIELD = "SET_USAGE_REPORT_SORT_FIELD";
export type SetUsageReportSortFieldAction = {
  type: typeof SET_USAGE_REPORT_SORT_FIELD;
  payload: SortFieldType;
};
export type SetUsageReportSortFieldAC = (payload: SortFieldType) => SetUsageReportSortFieldAction;

export const SET_USAGE_REPORT_PER_PAGE = "SET_USAGE_REPORT_PER_PAGE";
export type SetUsageReportPerPageAction = {
  type: typeof SET_USAGE_REPORT_PER_PAGE;
  payload: number;
};
export type SetUsageReportPerPageAC = (payload: number) => SetUsageReportPerPageAction;

export const SET_USAGE_REPORT_CURRENT_PAGE = "SET_USAGE_REPORT_CURRENT_PAGE";
export type SetUsageReportCurrentPageAction = {
  type: typeof SET_USAGE_REPORT_CURRENT_PAGE;
  payload: number;
};
export type SetUsageReportCurrentPageAC = (payload: number) => SetUsageReportCurrentPageAction;

export const SEND_EMAIL_REPORT_REQUEST = "SEND_EMAIL_REPORT_REQUEST";
export type SendEmailReportRequestAction = {
  type: typeof SEND_EMAIL_REPORT_REQUEST;
};
export type SendEmailReportRequestAC = () => SendEmailReportRequestAction;

export const SEND_EMAIL_REPORT_REQUEST_SUCCESS = "SEND_EMAIL_REPORT_REQUEST_SUCCESS";
export type SendEmailReportRequestSuccessAction = {
  type: typeof SEND_EMAIL_REPORT_REQUEST_SUCCESS;
};
export type SendEmailReportRequestSuccessAC = () => SendEmailReportRequestSuccessAction;

export const SEND_EMAIL_REPORT_REQUEST_FAILED = "SEND_EMAIL_REPORT_REQUEST_FAILED";
export type SendEmailReportRequestFailedAction = {
  type: typeof SEND_EMAIL_REPORT_REQUEST_FAILED;
};
export type SendEmailReportRequestFailedAC = () => SendEmailReportRequestFailedAction;

export type UsageReportPageAction =
  | UsageRequestAction
  | UsageRequestSuccessAction
  | UsageRequestFailureAction
  | SetUsageConfigAction
  | SetUsageTypeAction
  | SetUsageFiltersAction
  | O365UsageRequestAction
  | O365UsageRequestSuccessAction
  | O365UsageRequestFailureAction
  | SetO365UsageConfigAction
  | O365UsersRequestAction
  | O365UsersRequestSuccessAction
  | O365UsersRequestFailureAction
  | SetO365UsersDateAction
  | SetO365UsersFiltersAction
  | SetUsageReportSortFieldAction
  | SetUsageReportPerPageAction
  | SetUsageReportCurrentPageAction
  | SendEmailReportRequestAction
  | SendEmailReportRequestSuccessAction
  | SendEmailReportRequestFailedAction;
