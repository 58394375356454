import { RootState } from "../index";
import { StartPageStateSlice } from "./startPage.types";

export const getSlice = (state: RootState): StartPageStateSlice => state.startPage;

export const getCommCellLinkUrl = (state: RootState) => getSlice(state).commCellLinkUrl;
export const getBackupSize = (state: RootState) => getSlice(state).backupSize;
export const getJobStatusDay = (state: RootState) => getSlice(state).jobStatusDay;
export const getJobStatusWeek = (state: RootState) => getSlice(state).jobStatusWeek;
export const getJobStatusMonth = (state: RootState) => getSlice(state).jobStatusMonth;
export const getClients = (state: RootState) => getSlice(state).clients;
export const getSuspiciousFiles = (state: RootState) => getSlice(state).suspiciousFiles;
export const getUsageAndClients = (state: RootState) => getSlice(state).usageAndClients;
export const getClientSize = (state: RootState) => getSlice(state).clientSize;
export const getO365Usage = (state: RootState) => getSlice(state).o365Usage;
