import { RestoreJobsFiltersType, RestoreJobsPageStateSlice } from "./restoreJobs.types";
import { RootState } from "../index";
import { getPaginationSlice, makeUniversalSort } from "utils/table";
import { RestoreJobsModel } from "types/jobs.types";

export const getSlice = (state: RootState): RestoreJobsPageStateSlice => state.restoreJobs;

export const getRestoreJobsList = (state: RootState) => getSlice(state).list;
export const getRestoreJobsStatuses = (state: RootState) => getSlice(state).statuses;

const makeJobsStatusSortNormalizer = (item: RestoreJobsModel) => item.jobStatus;
const makeSourceClientSortNormalizer = (item: RestoreJobsModel) => item.sourceClient;
const makeDestinationClientSortNormalizer = (item: RestoreJobsModel) => item.destinationClient;
const makeApplicationSortNormalizer = (item: RestoreJobsModel) => item.application;
const makeInstanceSortNormalizer = (item: RestoreJobsModel) => item.instance;
const makeUserSortNormalizer = (item: RestoreJobsModel) => item.user;
const makeRestoreSizeSortNormalizer = (item: RestoreJobsModel) => Number(item.restoreSize);
const makeStartTimeSortNormalizer = (item: RestoreJobsModel) => item.startTime;
const makeEndTimeSortNormalizer = (item: RestoreJobsModel) => item.endTime;
const makeElapsedTimeSizeSortNormalizer = (item: RestoreJobsModel) => Number(item.elapsedTime);

const restoreJobsComposer = ({
  list,
  sortField,
  sortOrder,
  currentPage,
  perPage,
  filters,
}: RestoreJobsPageStateSlice) => {
  let _list = [...list];

  Object.keys(filters).forEach((filterName) => {
    if (filters[filterName as keyof RestoreJobsFiltersType]) {
      _list = _list.filter((item) =>
        item[filterName as keyof RestoreJobsFiltersType]
          ? item[filterName as keyof RestoreJobsFiltersType]
              .toString()
              .toLowerCase()
              .includes(filters[filterName as keyof RestoreJobsFiltersType].toLowerCase())
          : null
      );
    }
  });

  const sortHandler: any = {
    jobStatus: makeUniversalSort(makeJobsStatusSortNormalizer),
    sourceClient: makeUniversalSort(makeSourceClientSortNormalizer),
    destinationClient: makeUniversalSort(makeDestinationClientSortNormalizer),
    application: makeUniversalSort(makeApplicationSortNormalizer),
    instance: makeUniversalSort(makeInstanceSortNormalizer),
    user: makeUniversalSort(makeUserSortNormalizer),
    restoreSize: makeUniversalSort(makeRestoreSizeSortNormalizer),
    startTime: makeUniversalSort(makeStartTimeSortNormalizer),
    endTime: makeUniversalSort(makeEndTimeSortNormalizer),
    elapsedTime: makeUniversalSort(makeElapsedTimeSizeSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getRestoreJobsTable = (state: RootState) => {
  const restoreJobsSlice = getSlice(state);
  return restoreJobsComposer(restoreJobsSlice);
};
