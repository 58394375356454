import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import { composeChartOptions } from "./utils";
import { Card, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { clientsSelectors, clientsThunks } from "store/clients";
import CardFallback from "containers/CardFallback";
import { ClientModel } from "types/clients.types";

import styles from "./styles.module.css";

interface ClientUsageChartProps {
  client?: ClientModel;
}

const ClientUsageChart = ({ client }: ClientUsageChartProps) => {
  const { t } = useTranslation("clientDetailsPage");
  const { list, requestStatus } = useSelector(clientsSelectors.getClientUsage);

  const chartOptions = useMemo(() => composeChartOptions(list), [list]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(
    () => thunkDispatch(clientsThunks.getClientUsageInfo(selectedContractId, (client?.clientId as number) || "")),
    [client?.clientId, selectedContractId, thunkDispatch]
  );

  return (
    <Card className={styles.card} title={t("clientUsageChartTitle")} data-testid="clientUsageChartContent">
      <div className={styles.cardContent}>
        {(requestStatus === RequestStatus.PENDING || requestStatus === RequestStatus.UNCALLED) && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && <HighchartsReact highcharts={Highcharts} options={chartOptions} />}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default ClientUsageChart;
