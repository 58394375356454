import * as types from "./restoreJobs.types";

export const restoreJobsRequestAC: types.RestoreJobsRequestAC = () => ({
  type: types.RESTORE_JOBS_REQUEST,
});

export const restoreJobsRequestSuccessAC: types.RestoreJobsRequestSuccessAC = (payload) => ({
  type: types.RESTORE_JOBS_REQUEST_SUCCESS,
  payload,
});

export const restoreJobsRequestFailureAC: types.RestoreJobsRequestFailureAC = () => ({
  type: types.RESTORE_JOBS_REQUEST_FAILURE,
});

export const setRestoreJobsConfigAC: types.SetRestoreJobsConfigAC = (payload) => ({
  type: types.SET_RESTORE_JOBS_CONFIG,
  payload,
});

export const setRestoreJobsFiltersAC: types.SetRestoreJobsFiltersAC = (payload) => ({
  type: types.SET_RESTORE_JOBS_FILTERS,
  payload,
});

export const setRestoreJobsSortFieldAC: types.SetRestoreJobsSortFieldAC = (payload) => ({
  type: types.SET_RESTORE_JOBS_SORT_FIELD,
  payload,
});

export const setRestoreJobsPerPageAC: types.SetRestoreJobsPerPageAC = (payload) => ({
  type: types.SET_RESTORE_JOBS_PER_PAGE,
  payload,
});

export const setRestoreJobsCurrentPageAC: types.SetRestoreJobsCurrentPageAC = (payload) => ({
  type: types.SET_RESTORE_JOBS_CURRENT_PAGE,
  payload,
});
