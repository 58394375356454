import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Select, Spinner, TextInput } from "@skyportal/ui-kit";
import { contractSelectors } from "store/contracts";
import { getSendEmailReportRequestStatus } from "store/usageReports/usageReports.selectors";
import { sendReportEmailRequest } from "store/usageReports/usageReports.thunk.actions";
import { RequestStatus } from "types/common.types";
import { isValidEmail } from "utils/validation";
import { CSV_REPORT_ACCEPT_TYPE, HTML_REPORT_ACCEPT_TYPE, PDF_REPORT_ACCEPT_TYPE } from "./ExportModal";

import styles from "./styles.module.css";

const EXPORT_TYPES = [PDF_REPORT_ACCEPT_TYPE, CSV_REPORT_ACCEPT_TYPE, HTML_REPORT_ACCEPT_TYPE];

export interface SendModalProps {
  open: boolean;
  onClose: () => void;
  reportType: string;
  reportParams: object;
}

const SendModal = ({ open, onClose, reportParams, reportType }: SendModalProps) => {
  const onCloseRef = useRef(onClose);
  const { t } = useTranslation("baasSendReportModal");
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const mailReportRequestStatus = useSelector(getSendEmailReportRequestStatus);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailValidMsg, setEmailValidMsg] = useState("");
  const [acceptType, setAcceptType] = useState(PDF_REPORT_ACCEPT_TYPE);
  const typeOptions = EXPORT_TYPES.map((type) => ({
    label: t(type),
    value: type,
  }));

  const handleEmailChange = (event: any) => setEmail(event.target.value);
  const handleAcceptTypeChange = (value: string) => setAcceptType(value);

  const handleSendBtnClick = () => {
    if (!isValidEmail(email)) {
      setEmailValidMsg(t("invalidEmail"));
      return;
    }

    dispatch(
      sendReportEmailRequest(selectedContractId, reportType, {
        ...reportParams,
        accept: acceptType,
        email,
      })
    );
  };

  useEffect(() => {
    if (mailReportRequestStatus === RequestStatus.SUCCESS) {
      onCloseRef.current();
    }
  }, [mailReportRequestStatus]);

  useEffect(() => {
    setEmailValidMsg("");
  }, [email]);

  return (
    <Modal open={open} onClose={onClose} size="small" title={t("title")}>
      <Spinner show={mailReportRequestStatus === RequestStatus.PENDING} />
      <div className={styles.content}>
        <label className={styles.controlRow}>
          <span className={styles.controlLabel}>{t("Email")}</span>
          <TextInput
            data-testid="sendModal-email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="example@gmail.com"
            className={styles.input}
          />
          <div className={styles.errMsg}>{emailValidMsg}</div>
        </label>
        <div className={styles.controlRow}>
          <span className={styles.controlLabel}>{t("Format")}</span>
          <Select
            data-testid="sendModal-format"
            disableUnderline
            value={acceptType}
            options={typeOptions}
            onChange={handleAcceptTypeChange}
          ></Select>
        </div>
      </div>
      <div className={styles.cardFooter}>
        <Button data-testid="sendModal-closeBtn" type="secondary" onClick={onClose}>
          {t("common:Cancel")}
        </Button>
        <Button data-testid="sendModal-sendBtn" onClick={handleSendBtnClick} type="primary">
          <span>{t("common:Send")}</span>
        </Button>
      </div>
    </Modal>
  );
};

export default SendModal;
