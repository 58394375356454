import React from "react";
import { useTranslation } from "react-i18next";
import useFetchClientsList from "hooks/useFetchClientsList";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader";
import ClientListPageContent from "components/ClientListPageContent";

const ClientListPage: React.FC = () => {
  const { t } = useTranslation("clientListPage");
  useFetchClientsList();

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      <ClientListPageContent />
    </PageLayout>
  );
};

export default ClientListPage;
