import { combineReducers } from "redux";
import { reducer as app } from "./app";
import { reducer as contracts } from "./contracts";
import { reducer as startPage } from "./startPage";
import { reducer as usageReports } from "./usageReports";
import { reducer as clientsPage } from "./clients";
import { reducer as backupJobs } from "./backupJobs";
import { reducer as restoreJobs } from "./restoreJobs";
import { reducer as notification } from "./notification";

const rootReducer = combineReducers({
  app,
  contracts,
  startPage,
  usageReports,
  clientsPage,
  backupJobs,
  restoreJobs,
  notification,
});

export default rootReducer;
