import React from "react";
import InfoBoxCard from "./InfoBoxCard";
import { INFO_BOXES_VARIANTS } from "./constants";

import styles from "./styles.module.css";

const InfoBoxCards = () => {
  return (
    <div className={styles.infoBoxContainer}>
      {INFO_BOXES_VARIANTS.map((variant) => (
        <div key={variant} className={styles.infoBoxItem}>
          <div data-testid={`infoBoxCard-${variant}`}>
            <InfoBoxCard variant={variant} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default InfoBoxCards;
