import { Button } from "@skyportal/ui-kit";
import { clearSuspiciousFiles } from "api/startPage.api";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { contractSelectors } from "store/contracts";
import { notificationErrorAC } from "store/notification/notification.actions";
import { startPageSelectors } from "store/startPage";
import { parseApiErrorMessage } from "utils/helpers";

import styles from "./styles.module.css";

const SuspiciousFilesCard: React.FC = () => {
  const { t } = useTranslation("backupStartPage");
  const dispatch = useDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { list: suspiciousFiles } = useSelector(startPageSelectors.getSuspiciousFiles);
  const [isCardShown, setIsCardShown] = useState(true);
  const [isClearingInProgress, setIsClearingInProgress] = useState(false);

  const handleClearCardBtn = useCallback(async () => {
    setIsClearingInProgress(true);
    try {
      await Promise.all(suspiciousFiles.map((file) => clearSuspiciousFiles(selectedContractId, file.id)));
      setIsCardShown(false);
    } catch (apiErrorMessage) {
      dispatch(
        notificationErrorAC({
          message: t("notification:clearSuspiciousFilesFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
    }
    setIsClearingInProgress(false);
  }, [dispatch, selectedContractId, suspiciousFiles, t]);

  return isCardShown && suspiciousFiles.length ? (
    <div className={styles.suspiciousFilesCard} data-testid="suspiciousFiles">
      <div className={styles.content}>
        <h4 className={styles.title}>{t("suspiciousFilesTitle")}</h4>
        {suspiciousFiles.map((file) => (
          <div
            key={file.id}
            className={styles.fileDescription}
            dangerouslySetInnerHTML={{ __html: file.description.replaceAll("[", "<b>").replaceAll("]", "</b>") }}
          />
        ))}
      </div>
      <div className={styles.hideCardBtn}>
        <Button onClick={handleClearCardBtn} loading={isClearingInProgress} data-testid="markAsClearedBtn">
          {t("markAsClearedBtn")}
        </Button>
      </div>
    </div>
  ) : null;
};

export default SuspiciousFilesCard;
