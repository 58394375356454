import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { usageReportsSelectors, usageReportsThunks } from "store/usageReports";

const useFetchO365UsageReport = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { config } = useSelector(usageReportsSelectors.getO365Usage);

  useEffect(() => {
    if (selectedContractId) {
      thunkDispatch(usageReportsThunks.getO365UsageInfo(selectedContractId, config));
    }
  }, [thunkDispatch, selectedContractId, config]);

  return useSelector(usageReportsSelectors.getSlice);
};

export default useFetchO365UsageReport;
