import React from "react";
import { useTranslation } from "react-i18next";
import { WEEK_LABEL_LIST } from "./constants";

export interface DatePickerWeekElementProps {
  weekday: number;
}

const DatePickerWeekElement = ({ weekday }: DatePickerWeekElementProps) => {
  const { t } = useTranslation("shortDayOfWeek");

  return <div className="DayPicker-Weekday">{t(WEEK_LABEL_LIST[weekday])}</div>;
};

export default DatePickerWeekElement;
