import React from "react";
import UsageSummaryChart from "./UsageSummaryChart";
import UsageSummaryTable from "./UsageSummaryTable";

const UsageSummaryReport = () => {
  return (
    <>
      <UsageSummaryChart />
      <UsageSummaryTable />
    </>
  );
};

export default UsageSummaryReport;
