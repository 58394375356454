import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { backupJobsSelectors, backupJobsThunks } from "store/backupJobs";
import { contractSelectors } from "store/contracts";

const useFetchBackupJobs = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { config } = useSelector(backupJobsSelectors.getSlice);

  useEffect(() => {
    if (selectedContractId) {
      thunkDispatch(backupJobsThunks.getBackupJobsInfo(selectedContractId, config));
    }
  }, [thunkDispatch, selectedContractId, config]);

  return useSelector(backupJobsSelectors.getSlice);
};

export default useFetchBackupJobs;
