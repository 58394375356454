import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { startPageThunks } from "store/startPage";
import { Card, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";
import { startPageSelectors } from "store/startPage";
import { convertMegabytes } from "utils/helpers";

import styles from "../../styles.module.css";

const TopClientsBySizeCard = () => {
  const { t } = useTranslation("backupStartPage");
  const { list, requestStatus } = useSelector(startPageSelectors.getClientSize);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(
    () => thunkDispatch(startPageThunks.getClientSizeInfo(selectedContractId)),
    [selectedContractId, thunkDispatch]
  );

  return (
    <Card
      className={styles.card}
      title={
        <h3 className={styles.customCardTitle}>
          {t("topClientsBySizeCardTitle")}{" "}
          <span className={styles.customCardSubTitle}>{t("topClientsBySizeCardSubTitle")}</span>
        </h3>
      }
      data-testid="topClientsBySizeCardContent"
    >
      <div className={styles.cardContent}>
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS &&
          list.map((client) => (
            <Link
              to={`/baas/clients/${client.clientId}?contractId=${selectedContractId}`}
              className={styles.clientLink}
            >
              <div key={client.clientName} className={styles.clientSizeRow}>
                <span>{client.clientName}</span>
                <span>{convertMegabytes(client.size)}</span>
              </div>
            </Link>
          ))}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default TopClientsBySizeCard;
