import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { startPageSelectors, startPageThunks } from "store/startPage";

const useFetchStartPageData = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const commCellLinkUrl = useSelector(startPageSelectors.getCommCellLinkUrl);

  useEffect(() => {
    if (selectedContractId) {
      if (!commCellLinkUrl) {
        thunkDispatch(startPageThunks.getCommCellLinkUrlInfo(selectedContractId));
      }
      thunkDispatch(startPageThunks.getBackupSizeInfo(selectedContractId));
      thunkDispatch(startPageThunks.getJobStatusMonthInfo(selectedContractId));
      thunkDispatch(startPageThunks.getClientsInfo(selectedContractId));
      thunkDispatch(startPageThunks.getSuspiciousFilesInfo(selectedContractId));
      thunkDispatch(startPageThunks.getJobStatusDayInfo(selectedContractId));
      thunkDispatch(startPageThunks.getUsageAndClientsInfo(selectedContractId));
      thunkDispatch(startPageThunks.getClientSizeInfo(selectedContractId));
      thunkDispatch(startPageThunks.getJobStatusWeekInfo(selectedContractId));
      thunkDispatch(startPageThunks.getO365UsageInfo(selectedContractId));
    }
  }, [thunkDispatch, selectedContractId]);

  return useSelector(startPageSelectors.getSlice);
};

export default useFetchStartPageData;
