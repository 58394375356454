import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@skyportal/ui-kit";
import { MONTH_LABEL_LIST } from "./constants";

import styles from "./styles.module.css";

export interface DatePickerNavBarProps {
  onPreviousClick: () => void;
  onNextClick: () => void;
  month: Date;
}

const DatePickerNavBar = ({ onPreviousClick, onNextClick, month }: DatePickerNavBarProps) => {
  const { t } = useTranslation("months");

  return (
    <div className={styles.root}>
      <button data-testid="previousMonthBtn" className={styles.navBtn} type="button" onClick={() => onPreviousClick()}>
        <Icon type="fontawesome" className={styles.icon} icon="chevron-left" />
      </button>
      <div data-testid="monthLabel" className={styles.label}>{`${t(
        MONTH_LABEL_LIST[month.getMonth()]
      )} ${month.getFullYear()}`}</div>
      <button data-testid="nextMonthBtn" className={styles.navBtn} type="button" onClick={() => onNextClick()}>
        <Icon type="fontawesome" className={styles.icon} icon="chevron-right" />
      </button>
    </div>
  );
};

export default DatePickerNavBar;
