import React, { useCallback, useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import { composeChartOptions } from "./utils";
import { Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";
import { usageReportsSelectors, usageReportsThunks } from "store/usageReports";
import { UsageAndClientsModel } from "types/startPageApi.types";

import styles from "../styles.module.css";

const UsageSummaryChart = () => {
  const { list, config, requestStatus } = useSelector(usageReportsSelectors.getUsage);

  const chartOptions = useMemo(() => composeChartOptions(list as UsageAndClientsModel[]), [list]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(
    () => thunkDispatch(usageReportsThunks.getUsageSummaryInfo(selectedContractId, config)),
    [selectedContractId, config, thunkDispatch]
  );

  return (
    <div className={styles.chart}>
      {requestStatus === RequestStatus.PENDING && <Spinner show />}
      {requestStatus === RequestStatus.SUCCESS && <HighchartsReact highcharts={Highcharts} options={chartOptions} />}
      {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
    </div>
  );
};

export default UsageSummaryChart;
