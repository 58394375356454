import { AppThunk } from "store";
import * as usageReportsApi from "api/usageReports.api";
import * as usageReportsActions from "./usageReports.actions";
import i18n from "../../locales";
import { notificationAC, notificationErrorAC } from "../notification/notification.actions";
import { parseApiErrorMessage } from "utils/helpers";
import { UsageRequestConfig } from "types/usageReports.types";

export const getUsageSummaryInfo =
  (selectedContractId: string, requestConfig: UsageRequestConfig): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageReportsActions.usageRequestAC());
      const { data } = await usageReportsApi.getUsageSummary(selectedContractId, requestConfig);
      dispatch(usageReportsActions.usageRequestSuccessAC(data.usage));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "USAGE SUMMARY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:usageSummaryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageReportsActions.usageRequestFailureAC());
    }
  };

export const getUsageDetailedInfo =
  (selectedContractId: string, requestConfig: UsageRequestConfig): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageReportsActions.usageRequestAC());
      const { data } = await usageReportsApi.getUsageDetailed(selectedContractId, requestConfig);
      dispatch(usageReportsActions.usageRequestSuccessAC(data.usage));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "DETAILED USAGE REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:usageDetailedRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageReportsActions.usageRequestFailureAC());
    }
  };

export const getO365UsageInfo =
  (selectedContractId: string, requestConfig: UsageRequestConfig): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageReportsActions.o365UsageRequestAC());
      const { data } = await usageReportsApi.getO365Usage(selectedContractId, requestConfig);
      dispatch(usageReportsActions.o365UsageRequestSuccessAC(data.usage));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "O365 USAGE REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:o365UsageRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageReportsActions.o365UsageRequestFailureAC());
    }
  };

export const getO365UsersInfo =
  (selectedContractId: string, date: Date): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageReportsActions.o365UsersRequestAC());
      const { data } = await usageReportsApi.getO365Users(selectedContractId, date);
      dispatch(usageReportsActions.o365UsersRequestSuccessAC(data.users));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "O365 USERS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:o365UsersRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageReportsActions.o365UsersRequestFailureAC());
    }
  };

export const sendReportEmailRequest =
  (contractId: string, type: string, params: object): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageReportsActions.sendEmailReportRequestAC());
      await usageReportsApi.sendReportViaEmail(contractId, type, params);
      dispatch(usageReportsActions.sendEmailReportRequestSuccessAC());
      dispatch(notificationAC({ message: i18n.t("notification:emailReportSendSuccess") }));
    } catch (error) {
      dispatch(usageReportsActions.sendEmailReportRequestFailureAC());
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:emailReportSendFailed"),
          description: parseApiErrorMessage(error),
        })
      );
    }
  };
