import * as types from "./usageReports.types";

export const usageRequestAC: types.UsageRequestAC = () => ({
  type: types.USAGE_REQUEST,
});

export const usageRequestSuccessAC: types.UsageRequestSuccessAC = (payload) => ({
  type: types.USAGE_REQUEST_SUCCESS,
  payload,
});

export const usageRequestFailureAC: types.UsageRequestFailureAC = () => ({
  type: types.USAGE_REQUEST_FAILURE,
});

export const setUsageConfigAC: types.SetUsageConfigAC = (payload) => ({
  type: types.SET_USAGE_CONFIG,
  payload,
});

export const setUsageTypeAC: types.SetUsageTypeAC = (payload) => ({
  type: types.SET_USAGE_TYPE,
  payload,
});

export const setUsageFiltersAC: types.SetUsageFiltersAC = (payload) => ({
  type: types.SET_USAGE_FILTERS,
  payload,
});

export const o365UsageRequestAC: types.O365UsageRequestAC = () => ({
  type: types.O365_USAGE_REQUEST,
});

export const o365UsageRequestSuccessAC: types.O365UsageRequestSuccessAC = (payload) => ({
  type: types.O365_USAGE_REQUEST_SUCCESS,
  payload,
});

export const o365UsageRequestFailureAC: types.O365UsageRequestFailureAC = () => ({
  type: types.O365_USAGE_REQUEST_FAILURE,
});

export const setO365UsageConfigAC: types.SetO365UsageConfigAC = (payload) => ({
  type: types.SET_O365_USAGE_CONFIG,
  payload,
});

export const o365UsersRequestAC: types.O365UsersRequestAC = () => ({
  type: types.O365_USERS_REQUEST,
});

export const o365UsersRequestSuccessAC: types.O365UsersRequestSuccessAC = (payload) => ({
  type: types.O365_USERS_REQUEST_SUCCESS,
  payload,
});

export const o365UsersRequestFailureAC: types.O365UsersRequestFailureAC = () => ({
  type: types.O365_USERS_REQUEST_FAILURE,
});

export const setO365UsersDateAC: types.SetO365UsersDateAC = (payload) => ({
  type: types.SET_O365_USERS_DATE,
  payload,
});

export const setO365UsersFiltersAC: types.SetO365UsersFiltersAC = (payload) => ({
  type: types.SET_O365_USERS_FILTERS,
  payload,
});

export const setUsageReportSortFieldAC: types.SetUsageReportSortFieldAC = (payload) => ({
  type: types.SET_USAGE_REPORT_SORT_FIELD,
  payload,
});

export const setUsageReportPerPageAC: types.SetUsageReportPerPageAC = (payload) => ({
  type: types.SET_USAGE_REPORT_PER_PAGE,
  payload,
});

export const setUsageReportCurrentPageAC: types.SetUsageReportCurrentPageAC = (payload) => ({
  type: types.SET_USAGE_REPORT_CURRENT_PAGE,
  payload,
});

export const sendEmailReportRequestAC: types.SendEmailReportRequestAC = () => ({
  type: types.SEND_EMAIL_REPORT_REQUEST,
});

export const sendEmailReportRequestSuccessAC: types.SendEmailReportRequestSuccessAC = () => ({
  type: types.SEND_EMAIL_REPORT_REQUEST_SUCCESS,
});

export const sendEmailReportRequestFailureAC: types.SendEmailReportRequestFailedAC = () => ({
  type: types.SEND_EMAIL_REPORT_REQUEST_FAILED,
});
