import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { clientsSelectors, clientsThunks } from "store/clients";
import { ClientModel } from "types/clients.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { date30DaysAgoAtNoon, dateTodayAtNoon } from "utils/time";
import AlertBoxCards from "components/AlertBoxCards";
import ClientUsageChart from "./ClientUsageChart";
import ClientBackupJobsTable from "./ClientBackupJobsTable";
import ClientRestoreJobsTable from "./ClientRestoreJobsTable";

interface ClientDetailsPageContentProps {
  client?: ClientModel;
}

const ClientDetailsPageContent = ({ client }: ClientDetailsPageContentProps) => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const {
    backupJobsStatuses,
    backupJobs: { requestStatus },
  } = useSelector(clientsSelectors.getClientDetails);
  const reloadClientBackupJobsFunc = useCallback(
    () =>
      thunkDispatch(
        clientsThunks.getClientBackupJobsInfo(selectedContractId, (client?.clientId as number) || "", {
          startDate: date30DaysAgoAtNoon,
          endDate: dateTodayAtNoon,
        })
      ),
    [client, selectedContractId, thunkDispatch]
  );

  return (
    <>
      <AlertBoxCards
        statuses={backupJobsStatuses}
        requestStatus={requestStatus}
        reloadDataFunc={reloadClientBackupJobsFunc}
        last30Days
      />
      <ClientUsageChart client={client} />
      <ClientBackupJobsTable client={client} />
      <ClientRestoreJobsTable client={client} />
    </>
  );
};

export default ClientDetailsPageContent;
