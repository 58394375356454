import * as types from "./startPage.types";

export const setCommCellLinkUrlAC: types.SetCommCellLinkUrlAC = (payload) => ({
  type: types.SET_COMMCELL_LINK_URL,
  payload,
});

export const sizeRequestAC: types.SizeRequestAC = () => ({
  type: types.SIZE_REQUEST,
});

export const sizeRequestSuccessAC: types.SizeRequestSuccessAC = (payload) => ({
  type: types.SIZE_REQUEST_SUCCESS,
  payload,
});

export const sizeRequestFailureAC: types.SizeRequestFailureAC = () => ({
  type: types.SIZE_REQUEST_FAILURE,
});

export const jobStatusDayRequestAC: types.JobStatusDayRequestAC = () => ({
  type: types.JOB_STATUS_DAY_REQUEST,
});

export const jobStatusDayRequestSuccessAC: types.JobStatusDayRequestSuccessAC = (payload) => ({
  type: types.JOB_STATUS_DAY_REQUEST_SUCCESS,
  payload,
});

export const jobStatusDayRequestFailureAC: types.JobStatusDayRequestFailureAC = () => ({
  type: types.JOB_STATUS_DAY_REQUEST_FAILURE,
});

export const jobStatusWeekRequestAC: types.JobStatusWeekRequestAC = () => ({
  type: types.JOB_STATUS_WEEK_REQUEST,
});

export const jobStatusWeekRequestSuccessAC: types.JobStatusWeekRequestSuccessAC = (payload) => ({
  type: types.JOB_STATUS_WEEK_REQUEST_SUCCESS,
  payload,
});

export const jobStatusWeekRequestFailureAC: types.JobStatusWeekRequestFailureAC = () => ({
  type: types.JOB_STATUS_WEEK_REQUEST_FAILURE,
});

export const jobStatusMonthRequestAC: types.JobStatusMonthRequestAC = () => ({
  type: types.JOB_STATUS_MONTH_REQUEST,
});

export const jobStatusMonthRequestSuccessAC: types.JobStatusMonthRequestSuccessAC = (payload) => ({
  type: types.JOB_STATUS_MONTH_REQUEST_SUCCESS,
  payload,
});

export const jobStatusMonthRequestFailureAC: types.JobStatusMonthRequestFailureAC = () => ({
  type: types.JOB_STATUS_MONTH_REQUEST_FAILURE,
});

export const clientsRequestAC: types.ClientsRequestAC = () => ({
  type: types.CLIENTS_REQUEST,
});

export const clientsRequestSuccessAC: types.ClientsRequestSuccessAC = (payload) => ({
  type: types.CLIENTS_REQUEST_SUCCESS,
  payload,
});

export const clientsRequestFailureAC: types.ClientsRequestFailureAC = () => ({
  type: types.CLIENTS_REQUEST_FAILURE,
});

export const suspiciousFilesRequestAC: types.SuspiciousFilesRequestAC = () => ({
  type: types.SUSPICIOUS_FILES_REQUEST,
});

export const suspiciousFilesRequestSuccessAC: types.SuspiciousFilesRequestSuccessAC = (payload) => ({
  type: types.SUSPICIOUS_FILES_REQUEST_SUCCESS,
  payload,
});

export const suspiciousFilesRequestFailureAC: types.SuspiciousFilesRequestFailureAC = () => ({
  type: types.SUSPICIOUS_FILES_REQUEST_FAILURE,
});

export const usageAndClientsRequestAC: types.UsageAndClientsRequestAC = () => ({
  type: types.USAGE_AND_CLIENTS_REQUEST,
});

export const usageAndClientsRequestSuccessAC: types.UsageAndClientsRequestSuccessAC = (payload) => ({
  type: types.USAGE_AND_CLIENTS_REQUEST_SUCCESS,
  payload,
});

export const usageAndClientsRequestFailureAC: types.UsageAndClientsRequestFailureAC = () => ({
  type: types.USAGE_AND_CLIENTS_REQUEST_FAILURE,
});

export const clietSizeRequestAC: types.ClientSizeRequestAC = () => ({
  type: types.CLIENT_SIZE_REQUEST,
});

export const clietSizeRequestSuccessAC: types.ClientSizeRequestSuccessAC = (payload) => ({
  type: types.CLIENT_SIZE_REQUEST_SUCCESS,
  payload,
});

export const clietSizeRequestFailureAC: types.ClientSizeRequestFailureAC = () => ({
  type: types.CLIENT_SIZE_REQUEST_FAILURE,
});

export const o365UsageRequestAC: types.O365UsageRequestAC = () => ({
  type: types.O365_USAGE_REQUEST,
});

export const o365UsageRequestSuccessAC: types.O365UsageRequestSuccessAC = (payload) => ({
  type: types.O365_USAGE_REQUEST_SUCCESS,
  payload,
});

export const o365UsageRequestFailureAC: types.O365UsageRequestFailureAC = () => ({
  type: types.O365_USAGE_REQUEST_FAILURE,
});
