import React, { useCallback, useMemo } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { Icon, Spinner } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { startPageSelectors, startPageThunks } from "store/startPage";
import CardFallback from "containers/CardFallback";
import { RequestStatus } from "types/common.types";

import styles from "./styles.module.css";

interface AlertBoxCardsProps {
  isLiftedUp: boolean;
}

export const StartPageAlertBoxCards = ({ isLiftedUp }: AlertBoxCardsProps) => {
  const { t } = useTranslation("backupStartPage");
  const { list: alertsData, requestStatus } = useSelector(startPageSelectors.getJobStatusWeek);

  const successStatusData = useMemo(() => alertsData.find((item) => item.status === "Completed"), [alertsData]);
  const warningStatusData = useMemo(
    () => alertsData.find((item) => item.status === "Completed with errors"),
    [alertsData]
  );
  const failedStatusData = useMemo(() => alertsData.find((item) => item.status === "Failed"), [alertsData]);
  const killedStatusData = useMemo(() => alertsData.find((item) => item.status === "Killed"), [alertsData]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(startPageThunks.getJobStatusWeekInfo(selectedContractId));
    }
  }, [selectedContractId, thunkDispatch]);

  return (
    <div className={styles.alertBoxContainer}>
      <div
        className={cn(styles.alertBoxItem, { [styles.alertBoxItemLiftedUp]: isLiftedUp })}
        data-testid="alertBoxSuccess"
      >
        <div className={cn(styles.root, styles.successBox)}>
          {requestStatus === RequestStatus.PENDING && <Spinner data-testid="alertBoxSpinner" show />}
          {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
          <div className={styles.alertBox}>
            <Icon icon="thumbs-up" className={styles.icon} />
            <div className={styles.contentBlock}>
              <h4>{t("alertBoxesBlock.SUCCESS")}</h4>
              <span className={styles.jobsQtty}>
                {t("alertBoxesBlock.jobsQtty", { count: successStatusData?.count ?? 0 })}
              </span>
              <div className={styles.horizontalLine} />
              <div className={styles.rateLast7Days}>
                {t("alertBoxesBlock.rateInLast7days", {
                  number: successStatusData?.rate.toFixed(2) ?? "0.00",
                  status: "success",
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={cn(styles.alertBoxItem, { [styles.alertBoxItemLiftedUp]: isLiftedUp })}
        data-testid="alertBoxWarning"
      >
        <div className={cn(styles.root, styles.warningBox)}>
          {requestStatus === RequestStatus.PENDING && <Spinner data-testid="alertBoxSpinner" show />}
          {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
          <div className={styles.alertBox}>
            <Icon icon="info-attention" className={styles.icon} />
            <div className={styles.contentBlock}>
              <h4>{t("alertBoxesBlock.WARNING")}</h4>
              <span className={styles.jobsQtty}>
                {t("alertBoxesBlock.jobsQtty", { count: warningStatusData?.count ?? 0 })}
              </span>
              <div className={styles.horizontalLine} />
              <div className={styles.rateLast7Days}>
                {t("alertBoxesBlock.rateInLast7days", {
                  number: warningStatusData?.rate.toFixed(2) ?? "0.00",
                  status: "warning",
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={cn(styles.alertBoxItem, { [styles.alertBoxItemLiftedUp]: isLiftedUp })}
        data-testid="alertBoxFailed"
      >
        <div className={cn(styles.root, styles.failedBox)}>
          {requestStatus === RequestStatus.PENDING && <Spinner data-testid="alertBoxSpinner" show />}
          {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
          <div className={styles.alertBox}>
            <Icon icon="thumbs-down" className={styles.icon} />
            <div className={styles.contentBlock}>
              <h4>{t("alertBoxesBlock.FAILED")}</h4>
              <span className={styles.jobsQtty}>
                {t("alertBoxesBlock.jobsQtty", { count: failedStatusData?.count ?? 0 })}
              </span>
              <div className={styles.horizontalLine} />
              <div className={styles.rateLast7Days}>
                {t("alertBoxesBlock.rateInLast7days", {
                  number: failedStatusData?.rate.toFixed(2) ?? "0.00",
                  status: "failed",
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={cn(styles.alertBoxItem, { [styles.alertBoxItemLiftedUp]: isLiftedUp })}
        data-testid="alertBoxKilled"
      >
        <div className={cn(styles.root, styles.killedBox)}>
          {requestStatus === RequestStatus.PENDING && <Spinner data-testid="alertBoxSpinner" show />}
          {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
          <div className={styles.alertBox}>
            <Icon icon="stop" className={styles.icon} />
            <div className={styles.contentBlock}>
              <h4>{t("alertBoxesBlock.KILLED")}</h4>
              <span className={styles.jobsQtty}>
                {t("alertBoxesBlock.jobsQtty", { count: killedStatusData?.count ?? 0 })}
              </span>
              <div className={styles.horizontalLine} />
              <div className={styles.rateLast7Days}>
                {t("alertBoxesBlock.rateInLast7days", {
                  number: killedStatusData?.rate.toFixed(2) ?? "0.00",
                  status: "killed",
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
