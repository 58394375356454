import { backupApiClient } from "./clients";
import {
  ClientSizeResponse,
  ClientsResponse,
  CommCellLinkUrlResponse,
  JobStatusResponse,
  O365UsageResponse,
  SizeResponse,
  SuspiciousFilesResponse,
  UsageAndClientsResponse,
} from "types/startPageApi.types";
import { AxiosResponse } from "axios";

export const getCommCellLinkUrl = (contractId: string) =>
  backupApiClient.request<CommCellLinkUrlResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/commvault-link`,
  });

export const getSize = (contractId: string) =>
  backupApiClient.request<SizeResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/size`,
  });

export const getClients = (contractId: string) =>
  backupApiClient.request<ClientsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/clients`,
  });

export const getSuspiciousFiles = (contractId: string) =>
  backupApiClient.request<SuspiciousFilesResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/suspicious-files`,
  });

export const clearSuspiciousFiles = (contractId: string, fileId: number) =>
  backupApiClient.request<AxiosResponse>({
    method: "POST",
    url: `/v1/contracts/${contractId}/backup/clear-suspicious-file/${fileId}`,
  });

export const getJobStatus = (contractId: string, period: string) =>
  backupApiClient.request<JobStatusResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/job-status?period=${period}`,
  });

export const getUsageAndClients = (contractId: string) =>
  backupApiClient.request<UsageAndClientsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/usage-and-clients`,
  });

export const getClientSize = (contractId: string) =>
  backupApiClient.request<ClientSizeResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/client-size`,
  });

export const getO365Usage = (contractId: string) =>
  backupApiClient.request<O365UsageResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/o365-usage`,
  });
