import { ClientBackupJobModel, ClientModel, ClientRestoreJobModel, LabelModel } from "types/clients.types";
import { getPaginationSlice, makeUniversalSort } from "utils/table";
import { RootState } from "../index";
import { ClientPageStateSlice } from "./clients.types";

export const getSlice = (state: RootState): ClientPageStateSlice => state.clientsPage;

export const getClients = (state: RootState) => getSlice(state).clients;
export const getLabels = (state: RootState) => getSlice(state).labels;
export const getClientDetails = (state: RootState) => getSlice(state).clientDetails;
export const getClientUsage = (state: RootState) => getSlice(state).clientDetails.usage;
export const getClientBackupJobs = (state: RootState) => getSlice(state).clientDetails.backupJobs;
export const getClientRestoreJobs = (state: RootState) => getSlice(state).clientDetails.restoreJobs;

const makeClientNameSortNormalizer = (item: ClientModel) => item.clientName;
const makeApplicationSortNormalizer = (item: ClientModel) => item.application;
const makeCurrentSizeSortNormalizer = (item: ClientModel) => Number(item.currentSize);
const makeLabelsSortNormalizer = (item: ClientModel) => (item.labels as string).toLowerCase();

const clientsListComposer = ({
  clients: { list, sortField, sortOrder, currentPage, perPage, filters },
}: ClientPageStateSlice) => {
  // stringify labels for sorting and filtering
  let _list = [...list].map((client) => ({
    ...client,
    labels: (client.labels as LabelModel[])
      .sort(makeUniversalSort((item: LabelModel) => item.name.toLowerCase())("asc"))
      .map((label) => label.name)
      .join(", "),
  }));

  Object.keys(filters).forEach((filterName) => {
    if (filters[filterName as keyof Omit<ClientModel, "clientId">]) {
      _list = _list.filter((item) =>
        item[filterName as keyof Omit<ClientModel, "clientId">]
          ? item[filterName as keyof Omit<ClientModel, "clientId" | "currentSize" | "labels">]
              .toString()
              .toLowerCase()
              .includes(filters[filterName as keyof Omit<ClientModel, "clientId">].toLowerCase())
          : null
      );
    }
  });

  const sortHandler: any = {
    clientName: makeUniversalSort(makeClientNameSortNormalizer),
    application: makeUniversalSort(makeApplicationSortNormalizer),
    currentSize: makeUniversalSort(makeCurrentSizeSortNormalizer),
    labels: makeUniversalSort(makeLabelsSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getClientsListTable = (state: RootState) => {
  const clientsSlice = getSlice(state);
  return clientsListComposer(clientsSlice);
};

const makeJobStatusSortNormalizer = (item: ClientBackupJobModel) => item.jobStatus;
const makeLevelSortNormalizer = (item: ClientBackupJobModel) => item.level;
const makeBackupSizeSortNormalizer = (item: ClientBackupJobModel) => Number(item.backupSize);
const makeStartTimeSortNormalizer = (item: ClientBackupJobModel) => item.startTime;
const makeEndTimeSortNormalizer = (item: ClientBackupJobModel) => item.endTime;
const makeElapsedTimeSortNormalizer = (item: ClientBackupJobModel) => Number(item.elapsedTime);

const clientBackupJobsComposer = ({
  clientDetails: {
    backupJobs: { list, sortField, sortOrder, currentPage, perPage },
  },
}: ClientPageStateSlice) => {
  let _list = [...list];

  const sortHandler: any = {
    jobStatus: makeUniversalSort(makeJobStatusSortNormalizer),
    application: makeUniversalSort(makeApplicationSortNormalizer),
    level: makeUniversalSort(makeLevelSortNormalizer),
    backupSize: makeUniversalSort(makeBackupSizeSortNormalizer),
    startTime: makeUniversalSort(makeStartTimeSortNormalizer),
    endTime: makeUniversalSort(makeEndTimeSortNormalizer),
    elapsedTime: makeUniversalSort(makeElapsedTimeSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getClientBackupJobsTable = (state: RootState) => {
  const clientsSlice = getSlice(state);
  return clientBackupJobsComposer(clientsSlice);
};

const makeInstanceSortNormalizer = (item: ClientRestoreJobModel) => item.instance;
const makeRestoreSizeSortNormalizer = (item: ClientRestoreJobModel) => Number(item.restoreSize);
const makeUserSortNormalizer = (item: ClientRestoreJobModel) => item.user;

const clientRestoreJobsComposer = ({
  clientDetails: {
    restoreJobs: { list, sortField, sortOrder, currentPage, perPage },
  },
}: ClientPageStateSlice) => {
  let _list = [...list];

  const sortHandler: any = {
    jobStatus: makeUniversalSort(makeJobStatusSortNormalizer),
    application: makeUniversalSort(makeApplicationSortNormalizer),
    instance: makeUniversalSort(makeInstanceSortNormalizer),
    user: makeUniversalSort(makeUserSortNormalizer),
    restoreSize: makeUniversalSort(makeRestoreSizeSortNormalizer),
    startTime: makeUniversalSort(makeStartTimeSortNormalizer),
    endTime: makeUniversalSort(makeEndTimeSortNormalizer),
    elapsedTime: makeUniversalSort(makeElapsedTimeSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getClientRestoreJobsTable = (state: RootState) => {
  const clientsSlice = getSlice(state);
  return clientRestoreJobsComposer(clientsSlice);
};
