import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { restoreJobsSelectors, restoreJobsThunks } from "store/restoreJobs";
import { contractSelectors } from "store/contracts";

const useFetchRestoreJobs = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { config } = useSelector(restoreJobsSelectors.getSlice);

  useEffect(() => {
    if (selectedContractId) {
      thunkDispatch(restoreJobsThunks.getRestoreJobsInfo(selectedContractId, config));
    }
  }, [thunkDispatch, selectedContractId, config]);

  return useSelector(restoreJobsSelectors.getSlice);
};

export default useFetchRestoreJobs;
