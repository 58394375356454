import { RequestStatus } from "types/common.types";
import { dateTodayAtNoon, date7DaysAgoAtNoon } from "utils/time";
import * as types from "./backupJobs.types";

const initState: types.BackupJobsPageStateSlice = {
  statuses: {
    successCount: 0,
    successRate: 0,
    warningCount: 0,
    warningRate: 0,
    failedCount: 0,
    failedRate: 0,
    killedCount: 0,
    killedRate: 0,
  },
  list: [],
  config: {
    startDate: date7DaysAgoAtNoon,
    endDate: dateTodayAtNoon,
  },
  filters: { jobStatus: "", client: "", application: "", labels: "", level: "", backupSize: "", elapsedTime: "" },
  requestStatus: RequestStatus.UNCALLED,
  sortField: "",
  sortOrder: "desc",
  currentPage: 1,
  perPage: 10,
};

export default function contractReducer(
  state: types.BackupJobsPageStateSlice = initState,
  action: types.UsageReportPageAction
): types.BackupJobsPageStateSlice {
  switch (action.type) {
    case types.BACKUP_JOBS_REQUEST: {
      return {
        ...initState,
        config: state.config,
        requestStatus: RequestStatus.PENDING,
      };
    }
    case types.BACKUP_JOBS_REQUEST_SUCCESS: {
      return {
        ...state,
        statuses: action.payload.statuses,
        list: action.payload.backupJobs,
        requestStatus: RequestStatus.SUCCESS,
      };
    }
    case types.BACKUP_JOBS_REQUEST_FAILURE: {
      return {
        ...state,
        requestStatus: RequestStatus.FAILURE,
      };
    }
    case types.SET_BACKUP_JOBS_CONFIG: {
      return {
        ...state,
        config: action.payload,
      };
    }
    case types.SET_BACKUP_JOBS_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case types.SET_BACKUP_JOBS_SORT_FIELD: {
      return {
        ...state,
        sortField: state.sortField === action.payload && state.sortOrder === "asc" ? "" : action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "desc" ? "asc" : "desc",
        currentPage: 1,
      };
    }
    case types.SET_BACKUP_JOBS_PER_PAGE: {
      return {
        ...state,
        perPage: action.payload,
        currentPage: 1,
      };
    }
    case types.SET_BACKUP_JOBS_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    default:
      return state;
  }
}
