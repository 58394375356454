import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Button, Icon, Spinner } from "@skyportal/ui-kit";
import { InfoBoxesCardData, InfoBoxesCardsConfig } from "./constants";
import { InfoBoxesCardConfigType, InfoBoxesCardType, InfoBoxesCardVariantDataType } from "./types";
import { RequestStatus } from "types/common.types";
import CardFallback from "containers/CardFallback";
import { contractSelectors } from "store/contracts";
import { useThunkDispatch } from "store";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

interface InfoBoxesCardProps {
  variant: InfoBoxesCardType;
}

const InfoBoxesCard: React.FC<InfoBoxesCardProps> = ({ variant }) => {
  const { t } = useTranslation("backupStartPage");
  const cardData = useSelector(InfoBoxesCardsConfig[variant].selector);
  const cardThunk = InfoBoxesCardsConfig[variant].thunk;
  const cardDataNames: InfoBoxesCardVariantDataType = InfoBoxesCardData[variant];
  const cardConfig: InfoBoxesCardConfigType = InfoBoxesCardsConfig[variant];
  const wrapperClasses = cn(styles.root, styles[cardConfig.style]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(cardThunk(selectedContractId));
    }
  }, [cardThunk, selectedContractId, thunkDispatch]);

  return (
    <div className={wrapperClasses}>
      {cardData.requestStatus === RequestStatus.PENDING && <Spinner data-testid="infoBoxSpinner" show />}
      {cardData.requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      <div className={styles.infoBox}>
        <Icon type="fontawesome" className={styles.bgIcon} icon={cardConfig.icon} />
        <div className={styles.label}>{t(cardConfig.labelPhrase, { number: cardData[cardDataNames[0]] })}</div>
        <div className={styles.subLabel}>{t(cardConfig.subLabelPhrase)}</div>
        <Link data-testid="moreInfoLink" to={cardConfig.linkTo}>
          <Button primaryTypeColor={cardConfig.buttonColor} rightIcon="arrow-right" className={styles.moreInfoBtn}>
            {t("infoBoxesBlock.moreInfoLink")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default InfoBoxesCard;
