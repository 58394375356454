import * as types from "./backupJobs.types";

export const backupJobsRequestAC: types.BackupJobsRequestAC = () => ({
  type: types.BACKUP_JOBS_REQUEST,
});

export const backupJobsRequestSuccessAC: types.BackupJobsRequestSuccessAC = (payload) => ({
  type: types.BACKUP_JOBS_REQUEST_SUCCESS,
  payload,
});

export const backupJobsRequestFailureAC: types.BackupJobsRequestFailureAC = () => ({
  type: types.BACKUP_JOBS_REQUEST_FAILURE,
});

export const setBackupJobsConfigAC: types.SetBackupJobsConfigAC = (payload) => ({
  type: types.SET_BACKUP_JOBS_CONFIG,
  payload,
});

export const setBackupJobsFiltersAC: types.SetBackupJobsFiltersAC = (payload) => ({
  type: types.SET_BACKUP_JOBS_FILTERS,
  payload,
});

export const setBackupJobsSortFieldAC: types.SetBackupJobsSortFieldAC = (payload) => ({
  type: types.SET_BACKUP_JOBS_SORT_FIELD,
  payload,
});

export const setBackupJobsPerPageAC: types.SetBackupJobsPerPageAC = (payload) => ({
  type: types.SET_BACKUP_JOBS_PER_PAGE,
  payload,
});

export const setBackupJobsCurrentPageAC: types.SetBackupJobsCurrentPageAC = (payload) => ({
  type: types.SET_BACKUP_JOBS_CURRENT_PAGE,
  payload,
});
