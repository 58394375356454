import { InfoBoxesCardConfigType, InfoBoxesCardDataType, InfoBoxesCardType } from "./types";
import { startPageSelectors, startPageThunks } from "store/startPage";

export const SIZE_VARIANT = "SIZE_VARIANT";
export const SUCCESS_RATE_VARIANT = "SUCCESS_RATE_VARIANT";
export const CLIENTS_VARIANT = "CLIENTS_VARIANT";
export const JOBS_LAST_MONTH_VARIANT = "JOBS_LAST_MONTH_VARIANT";

export const INFO_BOXES_VARIANTS: Array<InfoBoxesCardType> = [
  SIZE_VARIANT,
  SUCCESS_RATE_VARIANT,
  CLIENTS_VARIANT,
  JOBS_LAST_MONTH_VARIANT,
];

export const InfoBoxesCardData: InfoBoxesCardDataType = {
  [SIZE_VARIANT]: ["size"],
  [SUCCESS_RATE_VARIANT]: ["successRate"],
  [CLIENTS_VARIANT]: ["count"],
  [JOBS_LAST_MONTH_VARIANT]: ["totalJobs"],
};

export const InfoBoxesCardsConfig: Record<InfoBoxesCardType, InfoBoxesCardConfigType> = {
  [SIZE_VARIANT]: {
    selector: startPageSelectors.getBackupSize,
    thunk: startPageThunks.getBackupSizeInfo,
    labelPhrase: "infoBoxesBlock.sizeLabel",
    subLabelPhrase: "infoBoxesBlock.sizeSubLabel",
    linkTo: "/baas/usage-report",
    style: "sizeVariant",
    icon: "maximize",
    buttonColor: "lightBlue",
  },
  [SUCCESS_RATE_VARIANT]: {
    selector: startPageSelectors.getJobStatusMonth,
    thunk: startPageThunks.getJobStatusMonthInfo,
    labelPhrase: "infoBoxesBlock.successRateLabel",
    subLabelPhrase: "infoBoxesBlock.successRateSubLabel",
    linkTo: "/baas/backup-jobs",
    style: "successRateVariant",
    icon: "circle-check",
    buttonColor: "lightGreen",
  },
  [CLIENTS_VARIANT]: {
    selector: startPageSelectors.getClients,
    thunk: startPageThunks.getClientsInfo,
    labelPhrase: "infoBoxesBlock.clientsLabel",
    subLabelPhrase: "infoBoxesBlock.clientsSubLabel",
    linkTo: "/baas/clients",
    style: "clientsVariant",
    icon: "user-tie",
    buttonColor: "lightPurple",
  },
  [JOBS_LAST_MONTH_VARIANT]: {
    selector: startPageSelectors.getJobStatusMonth,
    thunk: startPageThunks.getJobStatusMonthInfo,
    labelPhrase: "infoBoxesBlock.jobsLastMonthLabel",
    subLabelPhrase: "infoBoxesBlock.jobsLastMonthSubLabel",
    linkTo: "/baas/usage-report",
    style: "jobsLastMonthVariant",
    icon: "calendar",
    buttonColor: "lightBrown",
  },
};
