import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import { startPageThunks } from "store/startPage";
import { composeChartOptions } from "./utils";
import { Card, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";
import { startPageSelectors } from "store/startPage";

import styles from "../../styles.module.css";

const JobStatusDayChart = () => {
  const { t } = useTranslation("backupStartPage");
  const { list, requestStatus } = useSelector(startPageSelectors.getJobStatusDay);

  const chartOptions = useMemo(() => composeChartOptions(list), [list]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(startPageThunks.getJobStatusDayInfo(selectedContractId));
    }
  }, [selectedContractId, thunkDispatch]);

  return (
    <Card
      className={styles.card}
      title={
        <h3 className={styles.customCardTitle}>
          {t("jobStatusDayChartTitle")}{" "}
          <span className={styles.customCardSubTitle}>{t("jobStatusDayChartSubTitle")}</span>
        </h3>
      }
      data-testid="jobStatusDayChartContent"
    >
      <div className={styles.cardContent}>
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && <HighchartsReact highcharts={Highcharts} options={chartOptions} />}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default JobStatusDayChart;
