import { RequestStatus } from "types/common.types";
import * as types from "./startPage.types";
import { convertMegabytes } from "utils/helpers";

const initState: types.StartPageStateSlice = {
  commCellLinkUrl: "",
  backupSize: {
    size: convertMegabytes(0),
    requestStatus: RequestStatus.UNCALLED,
  },
  jobStatusDay: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  jobStatusWeek: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  jobStatusMonth: {
    list: [],
    successRate: 0,
    totalJobs: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  clients: {
    count: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  suspiciousFiles: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  usageAndClients: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  clientSize: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  o365Usage: {
    list: [],
    requestStatus: RequestStatus.UNCALLED,
  },
};

export default function contractReducer(
  state: types.StartPageStateSlice = initState,
  action: types.StartPageAction
): types.StartPageStateSlice {
  switch (action.type) {
    case types.SET_COMMCELL_LINK_URL: {
      return {
        ...state,
        commCellLinkUrl: action.payload,
      };
    }
    case types.SIZE_REQUEST: {
      return {
        ...state,
        backupSize: { ...initState.backupSize, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.SIZE_REQUEST_SUCCESS: {
      return {
        ...state,
        backupSize: {
          size: convertMegabytes(action.payload.size),
          requestStatus: RequestStatus.SUCCESS,
        },
      };
    }
    case types.SIZE_REQUEST_FAILURE: {
      return {
        ...state,
        backupSize: { ...initState.backupSize, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.JOB_STATUS_DAY_REQUEST: {
      return {
        ...state,
        jobStatusDay: { ...initState.jobStatusDay, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.JOB_STATUS_DAY_REQUEST_SUCCESS: {
      return {
        ...state,
        jobStatusDay: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.JOB_STATUS_DAY_REQUEST_FAILURE: {
      return {
        ...state,
        jobStatusDay: { ...initState.jobStatusDay, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.JOB_STATUS_WEEK_REQUEST: {
      return {
        ...state,
        jobStatusWeek: { ...initState.jobStatusWeek, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.JOB_STATUS_WEEK_REQUEST_SUCCESS: {
      return {
        ...state,
        jobStatusWeek: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.JOB_STATUS_WEEK_REQUEST_FAILURE: {
      return {
        ...state,
        jobStatusWeek: { ...initState.jobStatusWeek, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.JOB_STATUS_MONTH_REQUEST: {
      return {
        ...state,
        jobStatusMonth: { ...initState.jobStatusMonth, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.JOB_STATUS_MONTH_REQUEST_SUCCESS: {
      return {
        ...state,
        jobStatusMonth: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.JOB_STATUS_MONTH_REQUEST_FAILURE: {
      return {
        ...state,
        jobStatusMonth: { ...initState.jobStatusMonth, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.CLIENTS_REQUEST: {
      return {
        ...state,
        clients: { ...initState.clients, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.CLIENTS_REQUEST_SUCCESS: {
      return {
        ...state,
        clients: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.CLIENTS_REQUEST_FAILURE: {
      return {
        ...state,
        clients: { ...initState.clients, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.SUSPICIOUS_FILES_REQUEST: {
      return {
        ...state,
        suspiciousFiles: { ...initState.suspiciousFiles, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.SUSPICIOUS_FILES_REQUEST_SUCCESS: {
      return {
        ...state,
        suspiciousFiles: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.SUSPICIOUS_FILES_REQUEST_FAILURE: {
      return {
        ...state,
        suspiciousFiles: { ...initState.suspiciousFiles, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.USAGE_AND_CLIENTS_REQUEST: {
      return {
        ...state,
        usageAndClients: { ...initState.usageAndClients, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.USAGE_AND_CLIENTS_REQUEST_SUCCESS: {
      return {
        ...state,
        usageAndClients: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.USAGE_AND_CLIENTS_REQUEST_FAILURE: {
      return {
        ...state,
        usageAndClients: { ...initState.usageAndClients, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.CLIENT_SIZE_REQUEST: {
      return {
        ...state,
        clientSize: { ...initState.clientSize, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.CLIENT_SIZE_REQUEST_SUCCESS: {
      return {
        ...state,
        clientSize: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.CLIENT_SIZE_REQUEST_FAILURE: {
      return {
        ...state,
        clientSize: { ...initState.clientSize, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.O365_USAGE_REQUEST: {
      return {
        ...state,
        o365Usage: { ...initState.o365Usage, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.O365_USAGE_REQUEST_SUCCESS: {
      return {
        ...state,
        o365Usage: { list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.O365_USAGE_REQUEST_FAILURE: {
      return {
        ...state,
        o365Usage: { ...initState.o365Usage, requestStatus: RequestStatus.FAILURE },
      };
    }
    default:
      return state;
  }
}
