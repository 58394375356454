import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { startPageSelectors } from "store/startPage";
import JobStatusDayChart from "./charts/JobStatusDayChart";
import UsageAndClientsChart from "./charts/UsageAndClientsChart";
import O365UsageChart from "./charts/O365UsageChart";
import TopClientsBySizeCard from "./charts/TopClientsBySizeCard";
import { StartPageAlertBoxCards } from "components/StartPageAlertBoxCards";

import styles from "./styles.module.css";

const StartPageCharts = () => {
  const { list: o365UsageData } = useSelector(startPageSelectors.getO365Usage);
  const isO365UsedByCustomer = o365UsageData.length > 0;

  return (
    <div className={styles.chartsContainer}>
      <div className={cn(styles.baasChartsItem, styles.baasSmallCard)}>
        <JobStatusDayChart />
      </div>
      <div className={cn(styles.baasChartsItem, styles.baasBigCard)}>
        <UsageAndClientsChart />
      </div>
      <div className={cn(styles.baasChartsItem, styles.baasSmallCard)}>
        <TopClientsBySizeCard />
      </div>
      {isO365UsedByCustomer && (
        <div className={cn(styles.baasChartsItem, styles.baasBigCard)}>
          <O365UsageChart />
        </div>
      )}
      <div className={cn(styles.baasChartsItem, styles.baasBigCard)}>
        <StartPageAlertBoxCards isLiftedUp={!isO365UsedByCustomer} />
      </div>
    </div>
  );
};

export default StartPageCharts;
