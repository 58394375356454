import React, { useCallback, useMemo } from "react";
import { Card, Spinner, Table } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useThunkDispatch } from "store";
import { clientsSelectors, clientsThunks } from "store/clients";
import { RequestStatus } from "types/common.types";
import { ClientBackupJobModel, ClientModel, JobStatus } from "types/clients.types";
import { contractSelectors } from "store/contracts";
import {
  setClientBackupJobsCurrentPageAC,
  setClientBackupJobsPerPageAC,
  setClientBackupJobsSortFieldAC,
} from "store/clients/clients.actions";
import PaginationBox from "containers/PaginationBox";
import CardFallback from "containers/CardFallback";
import { date30DaysAgoAtNoon, dateTodayAtNoon, formatDateAndTime, formatHoursAndMinutes } from "utils/time";
import { convertBytes } from "utils/helpers";

import styles from "./styles.module.css";

export interface ClientBackupJobsTableProps {
  client?: ClientModel;
}

const jobStatusColor = {
  [JobStatus.Success]: styles.successBadge,
  [JobStatus.Warning]: styles.warningBadge,
  [JobStatus.Failed]: styles.failedBadge,
  [JobStatus.Killed]: styles.killedBadge,
};

const ClientBackupJobsTable = ({ client }: ClientBackupJobsTableProps) => {
  const { t } = useTranslation("clientDetailsPage");
  const dispatch = useDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { sortField, sortOrder, perPage, currentPage, requestStatus } = useSelector(
    clientsSelectors.getClientBackupJobs
  );
  const { list, pageCount } = useSelector(clientsSelectors.getClientBackupJobsTable);

  const columnsConfig = useMemo(
    () => [
      {
        key: "jobStatus",
        dataIndex: "jobStatus",
        title: t("clientBackupJobsTable.jobStatus"),
        render: (jobStatus: ClientBackupJobModel["jobStatus"]) => (
          <span className={cn(styles.jobStatusBadge, { [jobStatusColor[jobStatus as JobStatus]]: jobStatus })}>
            {jobStatus}
          </span>
        ),
      },
      { key: "application", dataIndex: "application", title: t("clientBackupJobsTable.application") },
      { key: "level", dataIndex: "level", title: t("clientBackupJobsTable.level") },
      {
        key: "backupSize",
        dataIndex: "backupSize",
        title: t("clientBackupJobsTable.backupSize"),
        render: (backupSize: ClientBackupJobModel["backupSize"]) => convertBytes(backupSize),
      },
      {
        key: "startTime",
        dataIndex: "startTime",
        title: t("clientBackupJobsTable.startTime"),
        render: (time: ClientBackupJobModel["startTime"]) => formatDateAndTime(time),
      },
      {
        key: "endTime",
        dataIndex: "endTime",
        title: t("clientBackupJobsTable.endTime"),
        render: (time: ClientBackupJobModel["endTime"]) => formatDateAndTime(time),
      },
      {
        key: "elapsedTime",
        dataIndex: "elapsedTime",
        title: t("clientBackupJobsTable.elapsedTime"),
        render: (elapsedTime: ClientBackupJobModel["elapsedTime"]) => formatHoursAndMinutes(elapsedTime),
      },
    ],
    [t]
  );

  const handleSortFieldChange = useCallback((field) => dispatch(setClientBackupJobsSortFieldAC(field)), [dispatch]);
  const handlePerPageChange = useCallback((value) => dispatch(setClientBackupJobsPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setClientBackupJobsCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const reloadData = useCallback(() => {
    thunkDispatch(
      clientsThunks.getClientBackupJobsInfo(selectedContractId, (client?.clientId as number) || "", {
        startDate: date30DaysAgoAtNoon,
        endDate: dateTodayAtNoon,
      })
    );
  }, [client?.clientId, selectedContractId, thunkDispatch]);

  return (
    <Card title={t("clientBackupJobsTableTitle")} className={styles.card} data-testid="clientBackupJobsPageContent">
      <div className={styles.cardContent}>
        {(requestStatus === RequestStatus.PENDING || requestStatus === RequestStatus.UNCALLED) && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && (
          <>
            <div className={styles.tableBlock}>
              <Table
                dataSource={list}
                columns={columnsConfig}
                sortField={sortField}
                sortOrder={sortOrder}
                onSortFieldChange={handleSortFieldChange}
              />
            </div>
            <PaginationBox
              perPage={perPage}
              currentPage={currentPage}
              pageCount={pageCount}
              onPerPageChange={handlePerPageChange}
              onChangePage={handlePageChange}
            />
          </>
        )}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default ClientBackupJobsTable;
