import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Select } from "@skyportal/ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { usageReportsActions, usageReportsSelectors } from "store/usageReports";
import { ReportType } from "types/usageReports.types";
import DatePicker from "containers/DatePicker";
import O365UsageChart from "./O365UsageChart";
import O365UsageTable from "./O365UsageTable";
import ExportModal from "containers/ExportModals/ExportModal";
import SendModal from "containers/ExportModals/SendModal";
import { formatDate, isDateEarlierThanTodaysNoon, isFirstDateNotLaterThanSecond } from "utils/time";
import { RequestStatus } from "types/common.types";
import { initialUsageConfig } from "store/usageReports/usageReports.reducer";

import styles from "./styles.module.css";

export type SelectOptionItem = {
  value: string;
  label: string;
};

export const reportTypeOptions: SelectOptionItem[] = [
  { label: "Daily", value: ReportType.Daily },
  { label: "Weekly", value: ReportType.Weekly },
  { label: "Monthly", value: ReportType.Monthly },
  { label: "Quarterly", value: ReportType.Quarterly },
  { label: "Yearly", value: ReportType.Yearly },
];

const O365UsageReport = () => {
  const { t } = useTranslation("o365UsagePage");
  const dispatch = useDispatch();
  const { config, requestStatus } = useSelector(usageReportsSelectors.getO365Usage);
  const [selectedReportType, setSelectedReportType] = useState<ReportType>(initialUsageConfig.reportType);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(initialUsageConfig.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(initialUsageConfig.endDate);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);

  const handleReportTypeChange = useCallback((value: string) => setSelectedReportType(value as ReportType), []);
  const handleStartDateChange = useCallback((value: Date) => setSelectedStartDate(value), []);
  const handleEndDateChange = useCallback((value: Date) => setSelectedEndDate(value), []);

  const handleShowReportBtnClick = useCallback(() => {
    dispatch(
      usageReportsActions.setO365UsageConfigAC({
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        reportType: selectedReportType,
      })
    );
  }, [dispatch, selectedEndDate, selectedReportType, selectedStartDate]);

  const handleExportBtnClick = useCallback(() => setIsExportModalOpen(true), []);
  const handleExportModalClose = useCallback(() => setIsExportModalOpen(false), []);
  const handleSendBtnClick = useCallback(() => setIsSendModalOpen(true), []);
  const handleSendModalClose = useCallback(() => setIsSendModalOpen(false), []);

  const isStartEarlierThanEndDate = useMemo(
    () => isFirstDateNotLaterThanSecond(selectedStartDate, selectedEndDate),
    [selectedEndDate, selectedStartDate]
  );
  const isStartDateValid = useMemo(() => isDateEarlierThanTodaysNoon(selectedStartDate), [selectedStartDate]);
  const isEndDateValid = useMemo(() => isDateEarlierThanTodaysNoon(selectedEndDate), [selectedEndDate]);

  const isLoading = useMemo(() => requestStatus === RequestStatus.PENDING, [requestStatus]);

  const exportReportParams = useMemo(
    () => ({
      startDate: formatDate(config.startDate),
      endDate: formatDate(config.endDate),
      reportType: config.reportType,
    }),
    [config.endDate, config.reportType, config.startDate]
  );

  useEffect(() => {
    dispatch(usageReportsActions.setO365UsageConfigAC(initialUsageConfig));
  }, [dispatch]);

  return (
    <>
      <div className={styles.filtersBlock}>
        <div className={styles.filterBar}>
          <div className={styles.filterLabel}>{t("filterLabels.reportType")}</div>
          <Select
            data-testid="reportTypeSelect"
            className={styles.select}
            options={reportTypeOptions}
            value={selectedReportType}
            onChange={handleReportTypeChange}
          />
        </div>
        <div className={styles.filterBar} data-testid="startDatePicker">
          <div className={styles.filterLabel}>{t("filterLabels.from")}</div>
          <DatePicker
            value={selectedStartDate}
            onDayChange={handleStartDateChange}
            isError={!isStartEarlierThanEndDate || !isStartDateValid}
          />
        </div>
        <div className={styles.filterBar} data-testid="endDatePicker">
          <div className={styles.filterLabel}>{t("filterLabels.to")}</div>
          <DatePicker
            value={selectedEndDate}
            onDayChange={handleEndDateChange}
            isError={!isStartEarlierThanEndDate || !isEndDateValid}
          />
        </div>
        <div className={styles.showReportBtnBlock}>
          <Button
            disabled={!isStartEarlierThanEndDate || !isStartDateValid || !isEndDateValid || isLoading}
            onClick={handleShowReportBtnClick}
          >
            {t("showReportBtn")}
          </Button>
        </div>
        <div className={styles.exportBlock}>
          <Button onClick={handleExportBtnClick} className={styles.reportBtn}>
            <span>{t("common:Export")}</span>
          </Button>
          <Button onClick={handleSendBtnClick} className={styles.reportBtn}>
            <span>{t("common:SendTo")}</span>
          </Button>
        </div>
      </div>
      <O365UsageChart />
      <O365UsageTable />

      <ExportModal
        open={isExportModalOpen}
        onClose={handleExportModalClose}
        reportType="o365-usage"
        reportParams={exportReportParams}
      />
      <SendModal
        open={isSendModalOpen}
        onClose={handleSendModalClose}
        reportType="o365-usage"
        reportParams={exportReportParams}
      />
    </>
  );
};

export default O365UsageReport;
