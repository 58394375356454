// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_tableBlock__2iN2Y {\n  margin-top: 8px;\n  margin-bottom: 8px;\n  overflow-x: auto;\n}\n\n.styles_tableBlock__2iN2Y::-webkit-scrollbar {\n  width: 3px;\n  height: 3px;\n  border-radius: 3px;\n}\n\n.styles_tableBlock__2iN2Y::-webkit-scrollbar-thumb {\n  border-radius: 3px;\n  background-color: rgb(0 0 0 / 80%);\n}\n\n.styles_tableBlock__2iN2Y::-webkit-scrollbar-track {\n  border-radius: 3px;\n  background-color: rgb(0 0 0 / 10%);\n}\n", "",{"version":3,"sources":["webpack://./src/components/O365UsagePageContent/tabs/O365UsageReport/O365UsageTable/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;AACpC","sourcesContent":[".tableBlock {\n  margin-top: 8px;\n  margin-bottom: 8px;\n  overflow-x: auto;\n}\n\n.tableBlock::-webkit-scrollbar {\n  width: 3px;\n  height: 3px;\n  border-radius: 3px;\n}\n\n.tableBlock::-webkit-scrollbar-thumb {\n  border-radius: 3px;\n  background-color: rgb(0 0 0 / 80%);\n}\n\n.tableBlock::-webkit-scrollbar-track {\n  border-radius: 3px;\n  background-color: rgb(0 0 0 / 10%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableBlock": "styles_tableBlock__2iN2Y"
};
export default ___CSS_LOADER_EXPORT___;
