import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Spinner, Table } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { usageReportsSelectors, usageReportsThunks } from "store/usageReports";
import {
  setUsageReportCurrentPageAC,
  setUsageReportPerPageAC,
  setUsageReportSortFieldAC,
} from "store/usageReports/usageReports.actions";
import PaginationBox from "containers/PaginationBox";
import { O365UsersModel } from "types/usageReports.types";
import { RequestStatus } from "types/common.types";
import CardFallback from "containers/CardFallback";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";

import styles from "./styles.module.css";

const tickOrCrossIcon = (value: boolean) =>
  value ? <Icon icon="check" height={27} /> : <Icon icon="close" height={18} />;

const O365UsersTable = () => {
  const { t } = useTranslation("o365UsagePage");
  const dispatch = useDispatch();
  const { date, requestStatus } = useSelector(usageReportsSelectors.getO365Users);
  const { sortField, sortOrder, perPage, currentPage } = useSelector(usageReportsSelectors.getSlice);
  const { list, pageCount } = useSelector(usageReportsSelectors.getO365UsersTable);

  const columnsConfig = useMemo(
    () => [
      { key: "emailAddress", dataIndex: "emailAddress", title: t("usersTable.emailAddress") },
      {
        key: "dynamics365",
        dataIndex: "dynamics365",
        title: t("usersTable.dynamics365"),
        render: (value: O365UsersModel["dynamics365"]) => tickOrCrossIcon(value),
      },
      {
        key: "exchangeMailbox",
        dataIndex: "exchangeMailbox",
        title: t("usersTable.exchangeMailbox"),
        render: (value: O365UsersModel["exchangeMailbox"]) => tickOrCrossIcon(value),
      },
      {
        key: "gDrive",
        dataIndex: "gDrive",
        title: t("usersTable.gDrive"),
        render: (value: O365UsersModel["gDrive"]) => tickOrCrossIcon(value),
      },
      {
        key: "gmail",
        dataIndex: "gmail",
        title: t("usersTable.gmail"),
        render: (value: O365UsersModel["gmail"]) => tickOrCrossIcon(value),
      },
      {
        key: "notes",
        dataIndex: "notes",
        title: t("usersTable.notes"),
        render: (value: O365UsersModel["notes"]) => tickOrCrossIcon(value),
      },
      {
        key: "oneDrive",
        dataIndex: "oneDrive",
        title: t("usersTable.oneDrive"),
        render: (value: O365UsersModel["oneDrive"]) => tickOrCrossIcon(value),
      },
      {
        key: "salesforce",
        dataIndex: "salesforce",
        title: t("usersTable.salesforce"),
        render: (value: O365UsersModel["salesforce"]) => tickOrCrossIcon(value),
      },
      {
        key: "sharepointOnline",
        dataIndex: "sharepointOnline",
        title: t("usersTable.sharepointOnline"),
        render: (value: O365UsersModel["sharepointOnline"]) => tickOrCrossIcon(value),
      },
      {
        key: "exchangeMailboxClassic",
        dataIndex: "exchangeMailboxClassic",
        title: t("usersTable.exchangeMailboxClassic"),
        render: (value: O365UsersModel["exchangeMailboxClassic"]) => tickOrCrossIcon(value),
      },
      {
        key: "caseManagerEnabled",
        dataIndex: "caseManagerEnabled",
        title: t("usersTable.caseManagerEnabled"),
        render: (value: O365UsersModel["caseManagerEnabled"]) => tickOrCrossIcon(value),
      },
      {
        key: "complianceSearchEnabled",
        dataIndex: "complianceSearchEnabled",
        title: t("usersTable.complianceSearchEnabled"),
        render: (value: O365UsersModel["complianceSearchEnabled"]) => tickOrCrossIcon(value),
      },
      {
        key: "contentIndexingEnabled",
        dataIndex: "contentIndexingEnabled",
        title: t("usersTable.contentIndexingEnabled"),
        render: (value: O365UsersModel["contentIndexingEnabled"]) => tickOrCrossIcon(value),
      },
    ],
    [t]
  );

  const handleSortFieldChange = useCallback((field) => dispatch(setUsageReportSortFieldAC(field)), [dispatch]);
  const handlePerPageChange = useCallback((value) => dispatch(setUsageReportPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setUsageReportCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(
    () => thunkDispatch(usageReportsThunks.getO365UsersInfo(selectedContractId, date)),
    [selectedContractId, date, thunkDispatch]
  );

  return (
    <div className={styles.table}>
      {requestStatus === RequestStatus.PENDING && <Spinner show />}
      {requestStatus === RequestStatus.SUCCESS && (
        <>
          <div className={styles.tableBlock}>
            <Table
              dataSource={list}
              columns={columnsConfig}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortFieldChange={handleSortFieldChange}
            />
          </div>
          <PaginationBox
            perPage={perPage}
            currentPage={currentPage}
            pageCount={pageCount}
            onPerPageChange={handlePerPageChange}
            onChangePage={handlePageChange}
          />
        </>
      )}
      {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
    </div>
  );
};

export default O365UsersTable;
