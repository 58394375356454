import React from "react";
import { useTranslation } from "react-i18next";
import useFetchStartPageData from "hooks/useFetchStartPageData";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader";
import InfoBoxCards from "components/InfoBoxCards";
import SuspiciousFilesCard from "components/SuspiciousFilesCard";
import StartPageCharts from "components/StartPageCharts";

const StartPage: React.FC = () => {
  const { t } = useTranslation("backupStartPage");
  useFetchStartPageData();

  return (
    <PageLayout>
      <PageHeader title={t("title")} withNavigationLinks />
      <InfoBoxCards />
      <SuspiciousFilesCard />
      <StartPageCharts />
    </PageLayout>
  );
};

export default StartPage;
