import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractActions, contractSelectors } from "store/contracts";
import { clientsSelectors, clientsThunks } from "store/clients";
import { useHistory, useLocation } from "react-router-dom";
import { date30DaysAgoAtNoon, dateTodayAtNoon } from "utils/time";
import { RequestStatus } from "types/common.types";
import { startPageThunks } from "store/startPage";

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const useFetchClientDetails = () => {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const contracts = useSelector(contractSelectors.getContractList);
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { list: clientList, requestStatus: clientListRequestStatus } = useSelector(clientsSelectors.getClients);
  const { pathname } = useLocation();
  const clientId = useMemo(() => Number(pathname.replace("/baas/clients/", "")), [pathname]);
  const client = useMemo(() => clientList.find((item) => item.clientId === clientId), [clientId, clientList]);
  const contractIdFromUrl = query.get("contractId") || "";
  const isExistingContract = useMemo(
    () => contracts.some((item) => item.name === contractIdFromUrl),
    [contractIdFromUrl, contracts]
  );

  useEffect(() => {
    if (selectedContractId) {
      if (selectedContractId !== contractIdFromUrl && isExistingContract) {
        // select correct contractId when loading page directly by URL
        dispatch(contractActions.selectedContractIdAC(contractIdFromUrl));
      } else if (!isExistingContract) {
        // redirect to client list if contract from URL query doesn't exist
        history.replace("/baas/clients");
      }

      if (clientListRequestStatus === RequestStatus.UNCALLED && selectedContractId === contractIdFromUrl) {
        // fetch clients list to get client data if such request hasn't happened before and correct contract is selected
        thunkDispatch(clientsThunks.getClientListInfo(selectedContractId));
        thunkDispatch(startPageThunks.getCommCellLinkUrlInfo(selectedContractId));
      }

      if (client) {
        // if client data is found fetch all usage, backup and restore jobs data to display on page
        thunkDispatch(clientsThunks.getClientUsageInfo(selectedContractId, clientId));
        thunkDispatch(
          clientsThunks.getClientBackupJobsInfo(selectedContractId, clientId, {
            startDate: date30DaysAgoAtNoon,
            endDate: dateTodayAtNoon,
          })
        );
        thunkDispatch(
          clientsThunks.getClientRestoreJobsInfo(selectedContractId, client.clientName, {
            startDate: date30DaysAgoAtNoon,
            endDate: dateTodayAtNoon,
          })
        );
      }
    }
  }, [
    dispatch,
    thunkDispatch,
    selectedContractId,
    clientId,
    client,
    history,
    clientListRequestStatus,
    contractIdFromUrl,
    isExistingContract,
  ]);

  return useSelector(clientsSelectors.getSlice);
};

export default useFetchClientDetails;
