import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { restoreJobsSelectors, restoreJobsThunks } from "store/restoreJobs";
import { contractSelectors } from "store/contracts";
import { date30DaysAgoAtNoon, dateTodayAtNoon } from "utils/time";
import AlertBoxCards from "components/AlertBoxCards";
import RestoreJobsTable from "./RestoreJobsTable";

const RestoreJobsPageContent = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { statuses, requestStatus } = useSelector(restoreJobsSelectors.getSlice);

  const reloadRestoreJobsFunc = useCallback(
    () =>
      thunkDispatch(
        restoreJobsThunks.getRestoreJobsInfo(selectedContractId, {
          startDate: date30DaysAgoAtNoon,
          endDate: dateTodayAtNoon,
        })
      ),
    [selectedContractId, thunkDispatch]
  );

  return (
    <>
      <AlertBoxCards statuses={statuses} requestStatus={requestStatus} reloadDataFunc={reloadRestoreJobsFunc} />
      <RestoreJobsTable />
    </>
  );
};

export default RestoreJobsPageContent;
