export enum ReportType {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Yearly = "Yearly",
}

export enum UsageType {
  Summary = "summary",
  Detailed = "detailed",
}

export interface UsageRequestConfig {
  startDate: Date;
  endDate: Date;
  reportType: ReportType;
}

export interface O365UsersModel {
  emailAddress: string;
  dynamics365: boolean;
  exchangeMailbox: boolean;
  gDrive: boolean;
  gmail: boolean;
  notes: boolean;
  oneDrive: boolean;
  salesforce: false;
  sharepointOnline: boolean;
  exchangeMailboxClassic: boolean;
  caseManagerEnabled: false;
  complianceSearchEnabled: false;
  contentIndexingEnabled: false;
}

export interface O365UsersResponse {
  users: O365UsersModel[];
}

export interface DetailedUsageModel {
  client: string;
  application: string;
  instance: string;
  minSize: unknown;
  maxSize: unknown;
}

export interface DetailedUsageResponse {
  usage: DetailedUsageModel[];
}
