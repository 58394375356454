import { AppThunk } from "store";
import * as jobsApi from "api/jobs.api";
import * as restoreJobsActions from "./restoreJobs.actions";
import i18n from "../../locales";
import { notificationErrorAC } from "../notification/notification.actions";
import { parseApiErrorMessage } from "utils/helpers";
import { JobsRequestConfig } from "types/clients.types";

export const getRestoreJobsInfo =
  (selectedContractId: string, requestConfig: JobsRequestConfig): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(restoreJobsActions.restoreJobsRequestAC());
      const { data } = await jobsApi.getRestoreJobs(selectedContractId, requestConfig);
      dispatch(restoreJobsActions.restoreJobsRequestSuccessAC(data));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "RESTORE JOBS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:restoreJobsRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(restoreJobsActions.restoreJobsRequestFailureAC());
    }
  };
