import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RadioMUI from "@material-ui/core/Radio";
import { Button, Card, Select } from "@skyportal/ui-kit";
import DatePicker from "containers/DatePicker";
import { RequestStatus } from "types/common.types";
import { reportTypeOptions } from "components/O365UsagePageContent/tabs/O365UsageReport/O365UsageReport";
import { usageReportsActions, usageReportsSelectors } from "store/usageReports";
import { ReportType, UsageType } from "types/usageReports.types";
import ExportModal from "containers/ExportModals/ExportModal";
import SendModal from "containers/ExportModals/SendModal";
import { formatDate, isDateEarlierThanTodaysNoon, isFirstDateNotLaterThanSecond } from "utils/time";
import UsageSummaryReport from "./reports/UsageSummaryReport";
import UsageDetailedReport from "./reports/UsageDetailedReport";
import { initialUsageConfig } from "store/usageReports/usageReports.reducer";

import styles from "./styles.module.css";

export const reportTypePath = {
  [UsageType.Summary]: "usage-and-clients",
  [UsageType.Detailed]: "detailed-usage",
};

const UsagePageContent = () => {
  const { t } = useTranslation("usagePage");
  const dispatch = useDispatch();
  const { type, config, requestStatus } = useSelector(usageReportsSelectors.getUsage);
  const [selectedReportType, setSelectedReportType] = useState<ReportType>(initialUsageConfig.reportType);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(initialUsageConfig.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(initialUsageConfig.endDate);
  const [selectedUsageType, setSelectedUsageType] = useState<UsageType>(UsageType.Summary);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);

  useEffect(() => {
    dispatch(usageReportsActions.setUsageTypeAC(UsageType.Summary));
    dispatch(usageReportsActions.setUsageConfigAC(initialUsageConfig));
  }, [dispatch]);

  const handleReportTypeChange = useCallback((value: string) => setSelectedReportType(value as ReportType), []);
  const handleStartDateChange = useCallback((value: Date) => setSelectedStartDate(value), []);
  const handleEndDateChange = useCallback((value: Date) => setSelectedEndDate(value), []);

  const handleShowReportBtnClick = useCallback(() => {
    dispatch(usageReportsActions.setUsageTypeAC(selectedUsageType));
    dispatch(
      usageReportsActions.setUsageConfigAC({
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        reportType: selectedReportType,
      })
    );
  }, [dispatch, selectedEndDate, selectedReportType, selectedStartDate, selectedUsageType]);

  const handleSwitchToSummmary = () => setSelectedUsageType(UsageType.Summary);
  const handleSwitchToDetailed = () => setSelectedUsageType(UsageType.Detailed);

  const handleExportBtnClick = useCallback(() => setIsExportModalOpen(true), []);
  const handleExportModalClose = useCallback(() => setIsExportModalOpen(false), []);
  const handleSendBtnClick = useCallback(() => setIsSendModalOpen(true), []);
  const handleSendModalClose = useCallback(() => setIsSendModalOpen(false), []);

  const isStartEarlierThanEndDate = useMemo(
    () => isFirstDateNotLaterThanSecond(selectedStartDate, selectedEndDate),
    [selectedEndDate, selectedStartDate]
  );
  const isStartDateValid = useMemo(() => isDateEarlierThanTodaysNoon(selectedStartDate), [selectedStartDate]);
  const isEndDateValid = useMemo(() => isDateEarlierThanTodaysNoon(selectedEndDate), [selectedEndDate]);

  const isLoading = useMemo(() => requestStatus === RequestStatus.PENDING, [requestStatus]);
  const isSummaryType = useMemo(() => selectedUsageType === UsageType.Summary, [selectedUsageType]);
  const isDetailedType = useMemo(() => selectedUsageType === UsageType.Detailed, [selectedUsageType]);

  const exportReportParams = useMemo(
    () => ({
      startDate: formatDate(config.startDate),
      endDate: formatDate(config.endDate),
      ...(isSummaryType && { reportType: config.reportType }),
    }),
    [config.endDate, config.reportType, config.startDate, isSummaryType]
  );

  return (
    <Card className={styles.card} data-testid="usagePageContent">
      <div className={styles.contentBlock}>
        <div className={styles.filtersBlock}>
          <div className={styles.filterBar}>
            <div className={styles.filterLabel}>{t("filterLabels.reportType")}</div>
            <Select
              data-testid="reportTypeSelect"
              className={styles.select}
              options={reportTypeOptions}
              value={selectedReportType}
              onChange={handleReportTypeChange}
              disabled={isDetailedType}
            />
          </div>
          <div className={styles.filterBar}>
            <div className={styles.filterLabel}>{t("filterLabels.from")}</div>
            <DatePicker
              value={selectedStartDate}
              onDayChange={handleStartDateChange}
              isError={!isStartEarlierThanEndDate || !isStartDateValid}
            />
          </div>
          <div className={styles.filterBar}>
            <div className={styles.filterLabel}>{t("filterLabels.to")}</div>
            <DatePicker
              value={selectedEndDate}
              onDayChange={handleEndDateChange}
              isError={!isStartEarlierThanEndDate || !isEndDateValid}
            />
          </div>
          <div className={styles.filterUsageType}>
            <div className={styles.usageType} onClick={handleSwitchToSummmary}>
              <RadioMUI
                data-testid="summaryReportBtn"
                color="default"
                size="small"
                disableRipple
                checked={isSummaryType}
                onChange={handleSwitchToSummmary}
                className={styles.radioBtnMui}
              />
              <span>{t("filterLabels.summary")}</span>
            </div>
            <div className={styles.usageType} onClick={handleSwitchToDetailed}>
              <RadioMUI
                data-testid="detailedReportBtn"
                color="default"
                size="small"
                disableRipple
                checked={isDetailedType}
                onChange={handleSwitchToDetailed}
                className={styles.radioBtnMui}
              />
              <span>{t("filterLabels.detailed")}</span>
            </div>
          </div>
          <div className={styles.showReportBtnBlock}>
            <Button
              data-testid="showReportBtn"
              disabled={!isStartEarlierThanEndDate || !isStartDateValid || !isEndDateValid || isLoading}
              onClick={handleShowReportBtnClick}
            >
              {t("showReportBtn")}
            </Button>
          </div>
          <div className={styles.exportBlock}>
            <Button onClick={handleExportBtnClick} className={styles.reportBtn}>
              <span>{t("common:Export")}</span>
            </Button>
            <Button onClick={handleSendBtnClick} className={styles.reportBtn}>
              <span>{t("common:SendTo")}</span>
            </Button>
          </div>
        </div>

        {type === UsageType.Summary && <UsageSummaryReport />}
        {type === UsageType.Detailed && <UsageDetailedReport />}

        <ExportModal
          open={isExportModalOpen}
          onClose={handleExportModalClose}
          reportType={reportTypePath[type]}
          reportParams={exportReportParams}
        />
        <SendModal
          open={isSendModalOpen}
          onClose={handleSendModalClose}
          reportType={reportTypePath[type]}
          reportParams={exportReportParams}
        />
      </div>
    </Card>
  );
};

export default UsagePageContent;
