import { JobsStatusModel, JobsRequestConfig } from "types/clients.types";
import { RequestStatus } from "types/common.types";
import { BackupJobsModel, BackupJobsResponse } from "types/jobs.types";

export type SortFieldType = keyof BackupJobsModel | "";
export type SortOrderType = "asc" | "desc";

export type BackupJobsFiltersType = Omit<Record<keyof BackupJobsModel, string>, "clientId" | "startTime" | "endTime">;

export interface BackupJobsPageStateSlice {
  readonly statuses: JobsStatusModel;
  readonly list: BackupJobsModel[];
  readonly config: JobsRequestConfig;
  readonly filters: BackupJobsFiltersType;
  readonly requestStatus: RequestStatus;
  readonly sortField: SortFieldType;
  readonly sortOrder: SortOrderType;
  readonly currentPage: number;
  readonly perPage: number;
}

export const BACKUP_JOBS_REQUEST = "BACKUP_JOBS_REQUEST";
export type BackupJobsRequestAction = {
  type: typeof BACKUP_JOBS_REQUEST;
};
export type BackupJobsRequestAC = () => BackupJobsRequestAction;

export const BACKUP_JOBS_REQUEST_SUCCESS = "BACKUP_JOBS_REQUEST_SUCCESS";
export type BackupJobsRequestSuccessAction = {
  type: typeof BACKUP_JOBS_REQUEST_SUCCESS;
  payload: BackupJobsResponse;
};
export type BackupJobsRequestSuccessAC = (payload: BackupJobsResponse) => BackupJobsRequestSuccessAction;

export const BACKUP_JOBS_REQUEST_FAILURE = "BACKUP_JOBS_REQUEST_FAILURE";
export type BackupJobsRequestFailureAction = {
  type: typeof BACKUP_JOBS_REQUEST_FAILURE;
};
export type BackupJobsRequestFailureAC = () => BackupJobsRequestFailureAction;

export const SET_BACKUP_JOBS_CONFIG = "SET_BACKUP_JOBS_CONFIG";
export type SetBackupJobsConfigAction = { type: typeof SET_BACKUP_JOBS_CONFIG; payload: JobsRequestConfig };
export type SetBackupJobsConfigAC = (payload: JobsRequestConfig) => SetBackupJobsConfigAction;

export const SET_BACKUP_JOBS_FILTERS = "SET_BACKUP_JOBS_FILTERS";
export type SetBackupJobsFiltersAction = { type: typeof SET_BACKUP_JOBS_FILTERS; payload: BackupJobsFiltersType };
export type SetBackupJobsFiltersAC = (payload: BackupJobsFiltersType) => SetBackupJobsFiltersAction;

export const SET_BACKUP_JOBS_SORT_FIELD = "SET_BACKUP_JOBS_SORT_FIELD";
export type SetBackupJobsSortFieldAction = {
  type: typeof SET_BACKUP_JOBS_SORT_FIELD;
  payload: SortFieldType;
};
export type SetBackupJobsSortFieldAC = (payload: SortFieldType) => SetBackupJobsSortFieldAction;

export const SET_BACKUP_JOBS_PER_PAGE = "SET_BACKUP_JOBS_PER_PAGE";
export type SetBackupJobsPerPageAction = {
  type: typeof SET_BACKUP_JOBS_PER_PAGE;
  payload: number;
};
export type SetBackupJobsPerPageAC = (payload: number) => SetBackupJobsPerPageAction;

export const SET_BACKUP_JOBS_CURRENT_PAGE = "SET_BACKUP_JOBS_CURRENT_PAGE";
export type SetBackupJobsCurrentPageAction = {
  type: typeof SET_BACKUP_JOBS_CURRENT_PAGE;
  payload: number;
};
export type SetBackupJobsCurrentPageAC = (payload: number) => SetBackupJobsCurrentPageAction;

export type UsageReportPageAction =
  | BackupJobsRequestAction
  | BackupJobsRequestSuccessAction
  | BackupJobsRequestFailureAction
  | SetBackupJobsConfigAction
  | SetBackupJobsFiltersAction
  | SetBackupJobsSortFieldAction
  | SetBackupJobsPerPageAction
  | SetBackupJobsCurrentPageAction;
