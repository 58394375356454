import React from "react";
import { Grid } from "@material-ui/core";
import DayPickerInput from "react-day-picker/DayPickerInput";
import DatePickerNavBar from "./DatePickerNavBar";
import DatePickerWeekElement from "./DatePickerWeekElement";
import { DATE_FORMAT, handleDateWrapperClick } from "./constants";
import { formatDate } from "utils/time";

import "react-day-picker/lib/style.css";
import "./overrideStyles.css";
import styles from "./styles.module.css";
import { Icon } from "@skyportal/ui-kit";

export interface DatePickerProps {
  onDayChange: (value: Date) => void;
  value: Date;
  dayPickerProps?: any;
  isError?: boolean;
  disabled?: boolean;
}

const DatePicker = ({ onDayChange, value, dayPickerProps, isError = false, disabled, ...rest }: DatePickerProps) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={styles.inputMask}
      onClick={handleDateWrapperClick}
    >
      <Icon icon="calendar" className={styles.calendarIcon} />
      <DayPickerInput
        style={{ border: isError && "1px solid #ff5a5a" }}
        onDayChange={onDayChange}
        value={value}
        inputProps={{ disabled }}
        dayPickerProps={{
          firstDayOfWeek: 1,
          format: DATE_FORMAT,
          selectedDays: value,
          showOutsideDays: true,
          navbarElement: DatePickerNavBar,
          weekdayElement: DatePickerWeekElement,
          ...dayPickerProps,
        }}
        format={DATE_FORMAT}
        formatDate={formatDate}
        placeholder={DATE_FORMAT}
        {...rest}
      />
      <Icon icon="arrow-down-small" className={styles.arrowDownIcon} />
    </Grid>
  );
};

export default DatePicker;
