import React, { useEffect, useMemo, useState } from "react";
import { Button, TextInput } from "@skyportal/ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { usageReportsActions, usageReportsSelectors } from "store/usageReports";
import DatePicker from "containers/DatePicker";
import ExportModal from "containers/ExportModals/ExportModal";
import SendModal from "containers/ExportModals/SendModal";
import { dateTodayAtNoon, formatDate, isDateEarlierThanTodaysNoon } from "utils/time";
import { RequestStatus } from "types/common.types";
import O365UsersTable from "./O365UsersTable";

import styles from "./styles.module.css";

const O365Users = () => {
  const { t } = useTranslation("o365UsagePage");
  const dispatch = useDispatch();
  const { list, date, filters, requestStatus } = useSelector(usageReportsSelectors.getO365Users);
  const [selectedDate, setSelectedDate] = useState(dateTodayAtNoon);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);

  const isDateValid = useMemo(() => isDateEarlierThanTodaysNoon(selectedDate), [selectedDate]);
  const handleDateChange = (value: Date) => setSelectedDate(value);
  const handleEmailFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(usageReportsActions.setO365UsersFiltersAC({ emailAddress: event.target.value }));
    dispatch(usageReportsActions.setUsageReportCurrentPageAC(1));
  };

  useEffect(() => {
    if (isDateValid) {
      dispatch(usageReportsActions.setO365UsersDateAC(selectedDate));
    }
  }, [dispatch, isDateValid, selectedDate]);

  const handleExportBtnClick = () => setIsExportModalOpen(true);
  const handleExportModalClose = () => setIsExportModalOpen(false);
  const handleSendBtnClick = () => setIsSendModalOpen(true);
  const handleSendModalClose = () => setIsSendModalOpen(false);

  const exportReportParams = useMemo(() => ({ date: formatDate(date) }), [date]);

  return (
    <>
      <div className={styles.filtersBlock}>
        <div className={styles.filterBar}>
          <div className={styles.filterLabel}>{t("filterLabels.date")}</div>
          <DatePicker
            data-testid="datePicker"
            value={selectedDate}
            onDayChange={handleDateChange}
            isError={!isDateValid}
            disabled={requestStatus === RequestStatus.PENDING}
          />
        </div>
        <div className={styles.filterBar}>
          <div className={styles.filterLabel}>{t("filterLabels.user")}</div>
          <TextInput
            data-testid="o365Users-emailAddress"
            placeholder={t("searchByEmailPlaceholder")}
            value={filters.emailAddress}
            onChange={handleEmailFilterChange}
          />
        </div>
        {requestStatus === RequestStatus.SUCCESS ? (
          <div className={styles.filterBar}>
            <div className={styles.filterLabel}>{t("filterLabels.total")}</div>
            <div className={styles.totalUsers}>{t("totalUsers", { count: list.length })}</div>
          </div>
        ) : null}
        <div className={styles.exportBlock}>
          <Button onClick={handleExportBtnClick} className={styles.reportBtn}>
            <span>{t("common:Export")}</span>
          </Button>
          <Button onClick={handleSendBtnClick} className={styles.reportBtn}>
            <span>{t("common:SendTo")}</span>
          </Button>
        </div>
      </div>
      <O365UsersTable />

      <ExportModal
        open={isExportModalOpen}
        onClose={handleExportModalClose}
        reportType="o365-users"
        reportParams={exportReportParams}
      />
      <SendModal
        reportType="o365-users"
        onClose={handleSendModalClose}
        open={isSendModalOpen}
        reportParams={exportReportParams}
      />
    </>
  );
};

export default O365Users;
