import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Card, Checkbox, Modal, Spinner, TextInput } from "@skyportal/ui-kit";
import * as clientsApi from "api/clients.api";
import i18n from "locales";
import { contractSelectors } from "store/contracts";
import { ClientModel, LabelModel } from "types/clients.types";
import ClientListTable from "./ClientListTable";
import { clientsActions, clientsSelectors } from "store/clients";
import { parseApiErrorMessage } from "utils/helpers";
import { notificationErrorAC } from "store/notification/notification.actions";
import { makeUniversalSort } from "utils/table";

import styles from "./styles.module.css";

const ClientListPageContent = () => {
  const { t } = useTranslation("clientListPage");
  const dispatch = useDispatch();

  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { list: clientList } = useSelector(clientsSelectors.getClients);
  const labelsList = useSelector(clientsSelectors.getLabels);

  const [isLabelsModalLoading, setIsLabelsModalLoading] = useState<boolean>(false);
  const [isLabelsModalOpen, setIsLabelsModalOpen] = useState<boolean>(false);

  const [isLabelDeleteModalOpen, setIsLabelDeleteModalOpen] = useState<boolean>(false);
  const [isLabelDeleteLoading, setIsLabelDeleteLoading] = useState<boolean>(false);

  const [selectedLabel, setSelectedLabel] = useState<LabelModel | null>(null);
  const [selectedClient, setSelectedClient] = useState<ClientModel | null>(null);
  const [selectedClientLabelIds, setSelectedClientLabelIds] = useState<number[]>([]);

  const [newLabelNameInput, setNewLabelNameInput] = useState<string>("");
  const [isNewLabelLoading, setIsNewLabelLoading] = useState<boolean>(false);
  const [isApplyLabelsLoading, setIsApplyLabelsLoading] = useState<boolean>(false);

  const handleAddNewLabelBtnClick = async () => {
    setIsNewLabelLoading(true);
    try {
      const { data } = await clientsApi.addLabel(selectedContractId, newLabelNameInput);
      dispatch(clientsActions.labelListRequestSuccessAC([...labelsList, { _id: data._id, name: newLabelNameInput }]));
      setNewLabelNameInput("");
    } catch (apiErrorMessage) {
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:addNewLabelRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
    }
    setIsNewLabelLoading(false);
  };

  const handleNewLabelInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && newLabelNameInput.length >= 3) handleAddNewLabelBtnClick();
  };

  const handleDeleteLabelBtnClick = useCallback((e, label) => {
    e.stopPropagation();
    setSelectedLabel(label);
    setIsLabelDeleteModalOpen(true);
  }, []);

  const handleConfirmDeletionBtnClick = async () => {
    setIsLabelDeleteLoading(true);
    try {
      await clientsApi.deleteLabel(selectedContractId, (selectedLabel as LabelModel)._id);
      dispatch(
        clientsActions.labelListRequestSuccessAC(
          labelsList.filter((label) => label._id !== (selectedLabel as LabelModel)._id)
        )
      );

      const clientListWithUpdatedLabels = clientList.map((client) => ({
        ...client,
        labels: (client.labels as LabelModel[]).filter(
          (clientLabel) => clientLabel._id !== (selectedLabel as LabelModel)._id
        ),
      }));
      dispatch(clientsActions.clientListRequestSuccessAC(clientListWithUpdatedLabels));
      setIsLabelDeleteModalOpen(false);
    } catch (apiErrorMessage) {
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:deleteLabelRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
    }
    setIsLabelDeleteLoading(false);
  };

  const handleLabelCheckboxClick = (labelId: number) => {
    selectedClientLabelIds.includes(labelId)
      ? setSelectedClientLabelIds(selectedClientLabelIds.filter((id) => id !== labelId))
      : setSelectedClientLabelIds([...selectedClientLabelIds, labelId]);
  };

  const handleOpenLabelsModal = useCallback(async () => {
    setIsLabelsModalLoading(true);
    try {
      const clientIndexInList = clientList.findIndex(
        (client) => client.clientId === (selectedClient as ClientModel).clientId
      );
      setSelectedClientLabelIds((clientList[clientIndexInList].labels as LabelModel[]).map((label) => label._id));
    } catch (apiErrorMessage) {
      setSelectedClientLabelIds([]);
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:clientsLabelsRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
    }
    setIsLabelsModalLoading(false);
  }, [dispatch, clientList, selectedClient]);

  const handleApplyLabelsBtnClick = async () => {
    setIsApplyLabelsLoading(true);
    try {
      await clientsApi.addLabelsToClient(
        selectedContractId,
        (selectedClient as ClientModel).clientId as number,
        selectedClientLabelIds
      );
      const clientIndexInList = clientList.findIndex(
        (client) => client.clientId === (selectedClient as ClientModel).clientId
      );
      const updatedClientList = [...clientList];
      updatedClientList[clientIndexInList] = {
        ...(selectedClient as ClientModel),
        labels: labelsList.filter((label) => selectedClientLabelIds.includes(label._id)),
      };
      dispatch(clientsActions.clientListRequestSuccessAC(updatedClientList));
      setIsLabelsModalOpen(false);
    } catch (apiErrorMessage) {
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:clientsLabelsApplyRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
    }
    setIsApplyLabelsLoading(false);
  };

  const handleCloseLabelsModal = useCallback(() => setIsLabelsModalOpen(false), []);
  const handleCloseLabelDeleteModal = useCallback(() => setIsLabelDeleteModalOpen(false), []);

  useEffect(() => {
    if (isLabelsModalOpen) handleOpenLabelsModal();
  }, [dispatch, handleOpenLabelsModal, isLabelsModalOpen]);

  return (
    <Card className={styles.card} data-testid="clientListPageContent">
      <ClientListTable setSelectedClient={setSelectedClient} setIsLabelsModalOpen={setIsLabelsModalOpen} />

      <Modal
        open={isLabelsModalOpen}
        onCloseBtnClick={handleCloseLabelsModal}
        title={t("labelsModalTitle", { clientName: selectedClient?.clientName })}
        controls={
          <>
            <Button data-testid="closeLabelsModalBtn" type="secondary" onClick={handleCloseLabelsModal}>
              {t("common:Close")}
            </Button>
            <Button
              data-testid="applyLabelsBtn"
              onClick={handleApplyLabelsBtnClick}
              disabled={isApplyLabelsLoading}
              loading={isApplyLabelsLoading}
            >
              {t("common:Apply")}
            </Button>
          </>
        }
      >
        <div data-testid="addLabelsModalContent" className={styles.modalContent}>
          {isLabelsModalLoading ? (
            <Spinner show={isLabelsModalLoading} />
          ) : (
            <>
              <div className={styles.newLabelBlock}>
                <TextInput
                  data-testid="newLabelInput"
                  placeholder={t("typeNewLabelName")}
                  value={newLabelNameInput}
                  onChange={(e) => setNewLabelNameInput(e.target.value)}
                  onKeyDown={handleNewLabelInputKeyDown}
                  autoFocus
                  minLength={3}
                  maxLength={70}
                />
                <Button
                  data-testid="addLabelBtn"
                  disabled={newLabelNameInput.length < 3 || isNewLabelLoading}
                  onClick={handleAddNewLabelBtnClick}
                  loading={isNewLabelLoading}
                >
                  {t("addNew")}
                </Button>
              </div>
              {labelsList.sort(makeUniversalSort((item: LabelModel) => item.name.toLowerCase())("asc")).map((label) => (
                <div key={label._id} className={styles.labelRow} onClick={() => handleLabelCheckboxClick(label._id)}>
                  <div>
                    <Checkbox
                      data-testid={`selectLabelCheckbox-${label._id}`}
                      checked={selectedClientLabelIds.includes(label._id)}
                      size="small"
                    />
                    <div className={styles.labelText}>{label.name}</div>
                  </div>
                  <Button
                    data-testid={`deleteLabelBtn-${label._id}`}
                    type="tertiary"
                    leftIcon="trash-bin"
                    size="small"
                    onClick={(e) => handleDeleteLabelBtnClick(e, label)}
                  ></Button>
                </div>
              ))}
            </>
          )}
        </div>
      </Modal>
      <Modal
        open={isLabelDeleteModalOpen}
        title={t("labelDeleteModalTitle", { labelName: selectedLabel?.name })}
        className={styles.labelDeleteModal}
        controls={
          <>
            <Button type="secondary" onClick={handleCloseLabelDeleteModal}>
              {t("common:Cancel")}
            </Button>
            <Button
              data-testid="deleteLabelBtn"
              type="primary"
              onClick={handleConfirmDeletionBtnClick}
              disabled={isLabelDeleteLoading}
              loading={isLabelDeleteLoading}
            >
              {t("common:Delete")}
            </Button>
          </>
        }
      >
        <span className={styles.labelDeleteModalDescr}>{t("labelDeleteModalDescription")}</span>
      </Modal>
    </Card>
  );
};

export default ClientListPageContent;
