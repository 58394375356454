import { AxiosResponse } from "axios";
import { backupApiClient } from "./clients";
import {
  AddNewLabelResponse,
  ClientBackupJobsResponse,
  JobsRequestConfig,
  ClientListResponse,
  ClientRestoreJobsResponse,
  ClientUsageResponse,
  LabelListResponse,
} from "types/clients.types";
import { formatDate } from "utils/time";

export const getClientList = (contractId: string) =>
  backupApiClient.request<ClientListResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/client-list`,
  });

export const getAllLabels = (contractId: string) =>
  backupApiClient.request<LabelListResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/labels`,
  });

export const getClientLabels = (contractId: string, clientId: number) =>
  backupApiClient.request<LabelListResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/labels/${clientId}`,
  });

export const addLabel = (contractId: string, name: string) =>
  backupApiClient.request<AddNewLabelResponse>({
    method: "POST",
    url: `/v1/contracts/${contractId}/backup/labels`,
    data: { name },
  });

export const addLabelsToClient = (contractId: string, clientId: number, labelIds: number[]) =>
  backupApiClient.request<AxiosResponse>({
    method: "POST",
    url: `/v1/contracts/${contractId}/backup/labels/${clientId}`,
    data: { labelIds },
  });

export const deleteLabel = (contractId: string, labelId: number) =>
  backupApiClient.request<AxiosResponse>({
    method: "DELETE",
    url: `/v1/contracts/${contractId}/backup/labels`,
    data: { labelIds: [labelId] },
  });

export const getClientUsage = (contractId: string, clientId: number | string) =>
  backupApiClient.request<ClientUsageResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/usage/${clientId}`,
  });

export const getClientBackupJobs = (contractId: string, clientId: number | string, requestConfig: JobsRequestConfig) =>
  backupApiClient.request<ClientBackupJobsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/backup-jobs/${clientId}?startDate=${formatDate(
      requestConfig.startDate
    )}&endDate=${formatDate(requestConfig.endDate)}`,
  });

export const getClientRestoreJobs = (contractId: string, clientName: string, requestConfig: JobsRequestConfig) =>
  backupApiClient.request<ClientRestoreJobsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/restore-jobs/${clientName}?startDate=${formatDate(
      requestConfig.startDate
    )}&endDate=${formatDate(requestConfig.endDate)}`,
  });
