import { O365UsageModel, UsageAndClientsModel } from "types/startPageApi.types";
import { DetailedUsageModel, O365UsersModel } from "types/usageReports.types";
import { makeUniversalSort, getPaginationSlice } from "utils/table";
import { RootState } from "../index";
import { UsagePageStateSlice } from "./usageReports.types";

export const getSlice = (state: RootState): UsagePageStateSlice => state.usageReports;

export const getUsage = (state: RootState) => getSlice(state).usage;
export const getO365Usage = (state: RootState) => getSlice(state).o365Usage;
export const getO365Users = (state: RootState) => getSlice(state).o365Users;
export const getSendEmailReportRequestStatus = (state: RootState) => getSlice(state).sendEmailReportRequestStatus;

const makeDateSortNormalizer = (item: O365UsageModel | UsageAndClientsModel) => item.date;
const makeSizeSortNormalizer = (item: O365UsageModel | UsageAndClientsModel) => Number(item.size);
const makeNumberOfUsersSortNormalizer = (item: O365UsageModel) => Number(item.numberOfUsers);

const o365UsageComposer = ({
  o365Usage: { list },
  sortField,
  sortOrder,
  currentPage,
  perPage,
}: UsagePageStateSlice) => {
  let _list = [...list.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0))];

  const sortHandler: any = {
    date: makeUniversalSort(makeDateSortNormalizer),
    size: makeUniversalSort(makeSizeSortNormalizer),
    numberOfUsers: makeUniversalSort(makeNumberOfUsersSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getO365UsageTable = (state: RootState) => {
  const usageReportSlice = getSlice(state);
  return o365UsageComposer(usageReportSlice);
};

const makeEmailAddressSortNormalizer = (item: O365UsersModel) => item.emailAddress;
const makeDynamics365SortNormalizer = (item: O365UsersModel) => item.dynamics365;
const makeExchangeMailboxSortNormalizer = (item: O365UsersModel) => item.exchangeMailbox;
const makeGDriveSortNormalizer = (item: O365UsersModel) => item.gDrive;
const makeGmailSortNormalizer = (item: O365UsersModel) => item.gmail;
const makeNotesSortNormalizer = (item: O365UsersModel) => item.notes;
const makeOneDriveSortNormalizer = (item: O365UsersModel) => item.oneDrive;
const makeSalesforceSortNormalizer = (item: O365UsersModel) => item.salesforce;
const makeSharepointOnlineSortNormalizer = (item: O365UsersModel) => item.sharepointOnline;
const makeExchangeMailboxClassicSortNormalizer = (item: O365UsersModel) => item.exchangeMailboxClassic;
const makeСaseManagerEnabledSortNormalizer = (item: O365UsersModel) => item.caseManagerEnabled;
const makeСomplianceSearchEnabledSortNormalizer = (item: O365UsersModel) => item.complianceSearchEnabled;
const makeСontentIndexinEnabledSortNormalizer = (item: O365UsersModel) => item.contentIndexingEnabled;

const o365UsersComposer = ({
  o365Users: { list, filters },
  sortField,
  sortOrder,
  currentPage,
  perPage,
}: UsagePageStateSlice) => {
  let _list = [...list];

  if (filters.emailAddress) {
    _list = _list.filter((item) => item.emailAddress.toLowerCase().includes(filters.emailAddress.toLowerCase()));
  }

  const sortHandler: any = {
    emailAddress: makeUniversalSort(makeEmailAddressSortNormalizer),
    dynamics365: makeUniversalSort(makeDynamics365SortNormalizer),
    exchangeMailbox: makeUniversalSort(makeExchangeMailboxSortNormalizer),
    gDrive: makeUniversalSort(makeGDriveSortNormalizer),
    gmail: makeUniversalSort(makeGmailSortNormalizer),
    notes: makeUniversalSort(makeNotesSortNormalizer),
    oneDrive: makeUniversalSort(makeOneDriveSortNormalizer),
    salesforce: makeUniversalSort(makeSalesforceSortNormalizer),
    sharepointOnline: makeUniversalSort(makeSharepointOnlineSortNormalizer),
    exchangeMailboxClassic: makeUniversalSort(makeExchangeMailboxClassicSortNormalizer),
    caseManagerEnabled: makeUniversalSort(makeСaseManagerEnabledSortNormalizer),
    complianceSearchEnabled: makeUniversalSort(makeСomplianceSearchEnabledSortNormalizer),
    contentIndexingEnabled: makeUniversalSort(makeСontentIndexinEnabledSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getO365UsersTable = (state: RootState) => {
  const usageReportSlice = getSlice(state);
  return o365UsersComposer(usageReportSlice);
};

const makeNumberOfClientsSortNormalizer = (item: UsageAndClientsModel) => Number(item.numberOfClients);

const usageSummaryComposer = ({ usage: { list }, sortField, sortOrder, currentPage, perPage }: UsagePageStateSlice) => {
  let _list = [...(list as UsageAndClientsModel[]).sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0))];

  const sortHandler: any = {
    date: makeUniversalSort(makeDateSortNormalizer),
    size: makeUniversalSort(makeSizeSortNormalizer),
    numberOfClients: makeUniversalSort(makeNumberOfClientsSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getUsageSummaryTable = (state: RootState) => {
  const usageReportSlice = getSlice(state);
  return usageSummaryComposer(usageReportSlice);
};

const makeClientsSortNormalizer = (item: DetailedUsageModel) => item.client;
const makeApplicationSortNormalizer = (item: DetailedUsageModel) => item.application;
const makeInstanceSortNormalizer = (item: DetailedUsageModel) => item.instance;
const makeMinSizeSortNormalizer = (item: DetailedUsageModel) => Number(item.minSize);
const makeMaxSizeSortNormalizer = (item: DetailedUsageModel) => Number(item.maxSize);

const usageDetailedComposer = ({
  usage: { list, filters },
  sortField,
  sortOrder,
  currentPage,
  perPage,
}: UsagePageStateSlice) => {
  let _list = [...list];

  Object.keys(filters).forEach((filterName) => {
    if (filters[filterName as keyof DetailedUsageModel]) {
      _list = _list.filter((item) =>
        (item as DetailedUsageModel)[filterName as keyof DetailedUsageModel]
          ? (item as Omit<DetailedUsageModel, "minSize" | "maxSize">)[
              filterName as keyof Omit<DetailedUsageModel, "minSize" | "maxSize">
            ]
              .toString()
              .toLowerCase()
              .includes(filters[filterName as keyof DetailedUsageModel].toLowerCase())
          : null
      );
    }
  });

  const sortHandler: any = {
    client: makeUniversalSort(makeClientsSortNormalizer),
    application: makeUniversalSort(makeApplicationSortNormalizer),
    instance: makeUniversalSort(makeInstanceSortNormalizer),
    minSize: makeUniversalSort(makeMinSizeSortNormalizer),
    maxSize: makeUniversalSort(makeMaxSizeSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getUsageDetailedTable = (state: RootState) => {
  const usageReportSlice = getSlice(state);
  return usageDetailedComposer(usageReportSlice);
};
