import { RequestStatus } from "types/common.types";
import { ReportType, UsageType } from "types/usageReports.types";
import { dateTodayAtNoon, dateYearAgoAtNoon } from "utils/time";
import * as types from "./usageReports.types";

export const initialUsageConfig = {
  startDate: dateYearAgoAtNoon,
  endDate: dateTodayAtNoon,
  reportType: ReportType.Daily,
};

const initState: types.UsagePageStateSlice = {
  usage: {
    list: [],
    type: UsageType.Summary,
    config: initialUsageConfig,
    filters: { client: "", application: "", instance: "", minSize: "", maxSize: "" },
    requestStatus: RequestStatus.UNCALLED,
  },
  o365Usage: {
    list: [],
    config: initialUsageConfig,
    requestStatus: RequestStatus.UNCALLED,
  },
  o365Users: {
    list: [],
    date: dateTodayAtNoon,
    filters: { emailAddress: "" },
    requestStatus: RequestStatus.UNCALLED,
  },
  sortField: "",
  sortOrder: "desc",
  currentPage: 1,
  perPage: 10,
  sendEmailReportRequestStatus: RequestStatus.UNCALLED,
};

export default function contractReducer(
  state: types.UsagePageStateSlice = initState,
  action: types.UsageReportPageAction
): types.UsagePageStateSlice {
  switch (action.type) {
    case types.USAGE_REQUEST: {
      return {
        ...state,
        sortField: initState.sortField,
        sortOrder: initState.sortOrder,
        currentPage: initState.currentPage,
        perPage: initState.perPage,
        usage: {
          ...initState.usage,
          type: state.usage.type,
          config: state.usage.config,
          requestStatus: RequestStatus.PENDING,
        },
      };
    }
    case types.USAGE_REQUEST_SUCCESS: {
      return {
        ...state,
        usage: { ...state.usage, list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.USAGE_REQUEST_FAILURE: {
      return {
        ...state,
        usage: {
          ...initState.usage,
          type: state.usage.type,
          config: state.usage.config,
          requestStatus: RequestStatus.FAILURE,
        },
      };
    }
    case types.SET_USAGE_CONFIG: {
      return {
        ...state,
        usage: { ...state.usage, config: action.payload },
      };
    }
    case types.SET_USAGE_TYPE: {
      return {
        ...state,
        usage: { ...state.usage, type: action.payload },
      };
    }
    case types.SET_USAGE_FILTERS: {
      return {
        ...state,
        usage: { ...state.usage, filters: action.payload },
      };
    }
    case types.O365_USAGE_REQUEST: {
      return {
        ...state,
        sortField: initState.sortField,
        sortOrder: initState.sortOrder,
        currentPage: initState.currentPage,
        perPage: initState.perPage,
        o365Usage: { ...initState.o365Usage, config: state.o365Usage.config, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.O365_USAGE_REQUEST_SUCCESS: {
      return {
        ...state,
        o365Usage: { ...state.o365Usage, list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.O365_USAGE_REQUEST_FAILURE: {
      return {
        ...state,
        o365Usage: { ...initState.o365Usage, config: state.o365Usage.config, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.SET_O365_USAGE_CONFIG: {
      return {
        ...state,
        o365Usage: { ...state.o365Usage, config: action.payload },
      };
    }
    case types.O365_USERS_REQUEST: {
      return {
        ...state,
        sortField: initState.sortField,
        sortOrder: initState.sortOrder,
        currentPage: initState.currentPage,
        perPage: initState.perPage,
        o365Users: {
          ...initState.o365Users,
          date: state.o365Users.date,
          filters: state.o365Users.filters,
          requestStatus: RequestStatus.PENDING,
        },
      };
    }
    case types.O365_USERS_REQUEST_SUCCESS: {
      return {
        ...state,
        o365Users: { ...state.o365Users, list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.O365_USERS_REQUEST_FAILURE: {
      return {
        ...state,
        o365Users: { ...initState.o365Users, date: state.o365Users.date, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.SET_O365_USERS_DATE: {
      return {
        ...state,
        o365Users: { ...state.o365Users, date: action.payload },
      };
    }
    case types.SET_O365_USERS_FILTERS: {
      return {
        ...state,
        o365Users: { ...state.o365Users, filters: action.payload },
      };
    }
    case types.SET_USAGE_REPORT_SORT_FIELD: {
      return {
        ...state,
        sortField: state.sortField === action.payload && state.sortOrder === "asc" ? "" : action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "desc" ? "asc" : "desc",
        currentPage: 1,
      };
    }
    case types.SET_USAGE_REPORT_PER_PAGE: {
      return {
        ...state,
        perPage: action.payload,
        currentPage: 1,
      };
    }
    case types.SET_USAGE_REPORT_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case types.SEND_EMAIL_REPORT_REQUEST:
      return {
        ...state,
        sendEmailReportRequestStatus: RequestStatus.PENDING,
      };

    case types.SEND_EMAIL_REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        sendEmailReportRequestStatus: RequestStatus.SUCCESS,
      };

    case types.SEND_EMAIL_REPORT_REQUEST_FAILED:
      return {
        ...state,
        sendEmailReportRequestStatus: RequestStatus.FAILURE,
      };
    default:
      return state;
  }
}
