import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { backupJobsSelectors, backupJobsThunks } from "store/backupJobs";
import { contractSelectors } from "store/contracts";
import { date7DaysAgoAtNoon, dateTodayAtNoon } from "utils/time";
import AlertBoxCards from "components/AlertBoxCards";
import BackupJobsTable from "./BackupJobsTable";

const BackupJobsPageContent = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { statuses, requestStatus } = useSelector(backupJobsSelectors.getSlice);

  const reloadBackupJobsFunc = useCallback(
    () =>
      thunkDispatch(
        backupJobsThunks.getBackupJobsInfo(selectedContractId, {
          startDate: date7DaysAgoAtNoon,
          endDate: dateTodayAtNoon,
        })
      ),
    [selectedContractId, thunkDispatch]
  );

  return (
    <>
      <AlertBoxCards statuses={statuses} requestStatus={requestStatus} reloadDataFunc={reloadBackupJobsFunc} />
      <BackupJobsTable />
    </>
  );
};

export default BackupJobsPageContent;
