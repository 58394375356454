import React from "react";
import { useTranslation } from "react-i18next";
import useFetchRestoreJobs from "hooks/useFetchRestoreJobs";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader";
import RestoreJobsPageContent from "components/RestoreJobsPageContent";

const RestoreJobsPage: React.FC = () => {
  const { t } = useTranslation("restoreJobsPage");
  useFetchRestoreJobs();

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      <RestoreJobsPageContent />
    </PageLayout>
  );
};

export default RestoreJobsPage;
