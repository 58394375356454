import { RequestStatus } from "types/common.types";
import * as types from "./clients.types";

const initState: types.ClientPageStateSlice = {
  clients: {
    list: [],
    filters: { clientName: "", application: "", currentSize: "", labels: "" },
    sortField: "",
    sortOrder: "desc",
    currentPage: 1,
    perPage: 10,
    requestStatus: RequestStatus.UNCALLED,
  },
  labels: [],
  clientDetails: {
    backupJobsStatuses: {
      successCount: 0,
      successRate: 0,
      warningCount: 0,
      warningRate: 0,
      failedCount: 0,
      failedRate: 0,
      killedCount: 0,
      killedRate: 0,
    },
    backupJobs: {
      list: [],
      sortField: "",
      sortOrder: "desc",
      currentPage: 1,
      perPage: 10,
      requestStatus: RequestStatus.UNCALLED,
    },
    restoreJobs: {
      list: [],
      sortField: "",
      sortOrder: "desc",
      currentPage: 1,
      perPage: 10,
      requestStatus: RequestStatus.UNCALLED,
    },
    usage: { list: [], requestStatus: RequestStatus.UNCALLED },
  },
};

export default function contractReducer(
  state: types.ClientPageStateSlice = initState,
  action: types.ClientPageAction
): types.ClientPageStateSlice {
  switch (action.type) {
    case types.CLIENT_LIST_REQUEST: {
      return {
        ...state,
        clients: {
          ...initState.clients,
          requestStatus: RequestStatus.PENDING,
        },
      };
    }
    case types.CLIENT_LIST_REQUEST_SUCCESS: {
      return {
        ...state,
        clients: { ...state.clients, list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.CLIENT_LIST_REQUEST_FAILURE: {
      return {
        ...state,
        clients: {
          ...initState.clients,
          requestStatus: RequestStatus.FAILURE,
        },
      };
    }
    case types.SET_CLIENT_LIST_FILTERS: {
      return {
        ...state,
        clients: { ...state.clients, filters: action.payload },
      };
    }
    case types.LABEL_LIST_REQUEST_SUCCESS: {
      return {
        ...state,
        labels: action.payload,
      };
    }
    case types.CLIENT_USAGE_REQUEST: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          usage: {
            ...initState.clientDetails.usage,
            requestStatus: RequestStatus.PENDING,
          },
        },
      };
    }
    case types.CLIENT_USAGE_REQUEST_SUCCESS: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          usage: {
            list: action.payload,
            requestStatus: RequestStatus.SUCCESS,
          },
        },
      };
    }
    case types.CLIENT_USAGE_REQUEST_FAILURE: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          usage: {
            ...initState.clientDetails.usage,
            requestStatus: RequestStatus.FAILURE,
          },
        },
      };
    }
    case types.CLIENT_BACKUP_JOBS_REQUEST: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          backupJobsStatuses: {
            ...initState.clientDetails.backupJobsStatuses,
          },
          backupJobs: {
            ...initState.clientDetails.backupJobs,
            requestStatus: RequestStatus.PENDING,
          },
        },
      };
    }
    case types.CLIENT_BACKUP_JOBS_REQUEST_SUCCESS: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          backupJobsStatuses: action.payload.statuses,
          backupJobs: {
            ...state.clientDetails.backupJobs,
            list: action.payload.backupJobs,
            requestStatus: RequestStatus.SUCCESS,
          },
        },
      };
    }
    case types.CLIENT_BACKUP_JOBS_REQUEST_FAILURE: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          backupJobs: {
            ...initState.clientDetails.backupJobs,
            requestStatus: RequestStatus.FAILURE,
          },
        },
      };
    }
    case types.CLIENT_RESTORE_JOBS_REQUEST: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          restoreJobs: {
            ...initState.clientDetails.restoreJobs,
            requestStatus: RequestStatus.PENDING,
          },
        },
      };
    }
    case types.CLIENT_RESTORE_JOBS_REQUEST_SUCCESS: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          restoreJobs: {
            ...state.clientDetails.restoreJobs,
            list: action.payload,
            requestStatus: RequestStatus.SUCCESS,
          },
        },
      };
    }
    case types.CLIENT_RESTORE_JOBS_REQUEST_FAILURE: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          restoreJobs: {
            ...initState.clientDetails.restoreJobs,
            requestStatus: RequestStatus.FAILURE,
          },
        },
      };
    }
    case types.SET_CLIENT_LIST_SORT_FIELD: {
      return {
        ...state,
        clients: {
          ...state.clients,
          sortField:
            state.clients.sortField === action.payload && state.clients.sortOrder === "asc" ? "" : action.payload,
          sortOrder: state.clients.sortField === action.payload && state.clients.sortOrder === "desc" ? "asc" : "desc",
          currentPage: 1,
        },
      };
    }
    case types.SET_CLIENT_LIST_PER_PAGE: {
      return {
        ...state,
        clients: {
          ...state.clients,
          perPage: action.payload,
          currentPage: 1,
        },
      };
    }
    case types.SET_CLIENT_LIST_CURRENT_PAGE: {
      return {
        ...state,
        clients: {
          ...state.clients,
          currentPage: action.payload,
        },
      };
    }
    case types.SET_CLIENT_BACKUP_JOBS_SORT_FIELD: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          backupJobs: {
            ...state.clientDetails.backupJobs,
            sortField:
              state.clientDetails.backupJobs.sortField === action.payload &&
              state.clientDetails.backupJobs.sortOrder === "asc"
                ? ""
                : action.payload,
            sortOrder:
              state.clientDetails.backupJobs.sortField === action.payload &&
              state.clientDetails.backupJobs.sortOrder === "desc"
                ? "asc"
                : "desc",
            currentPage: 1,
          },
        },
      };
    }
    case types.SET_CLIENT_BACKUP_JOBS_PER_PAGE: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          backupJobs: {
            ...state.clientDetails.backupJobs,
            perPage: action.payload,
            currentPage: 1,
          },
        },
      };
    }
    case types.SET_CLIENT_BACKUP_JOBS_CURRENT_PAGE: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          backupJobs: {
            ...state.clientDetails.backupJobs,
            currentPage: action.payload,
          },
        },
      };
    }
    case types.SET_CLIENT_RESTORE_JOBS_SORT_FIELD: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          restoreJobs: {
            ...state.clientDetails.restoreJobs,
            sortField:
              state.clientDetails.restoreJobs.sortField === action.payload &&
              state.clientDetails.restoreJobs.sortOrder === "asc"
                ? ""
                : action.payload,
            sortOrder:
              state.clientDetails.restoreJobs.sortField === action.payload &&
              state.clientDetails.restoreJobs.sortOrder === "desc"
                ? "asc"
                : "desc",
            currentPage: 1,
          },
        },
      };
    }
    case types.SET_CLIENT_RESTORE_JOBS_PER_PAGE: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          restoreJobs: {
            ...state.clientDetails.restoreJobs,
            perPage: action.payload,
            currentPage: 1,
          },
        },
      };
    }
    case types.SET_CLIENT_RESTORE_JOBS_CURRENT_PAGE: {
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          restoreJobs: {
            ...state.clientDetails.restoreJobs,
            currentPage: action.payload,
          },
        },
      };
    }
    default:
      return state;
  }
}
