import { UsageAndClientsModel } from "types/startPageApi.types";
import { convertMegabytes } from "utils/helpers";

export function areaSplineLegendLabelFormatter(this: {
  itemDistance: number;
  alignColumns: boolean;
  useHTML: boolean;
  labelFormatters: () => string;
}) {
  return `
    <div style="font-weight: normal; font-size: 14px; line-height: 1.2; margin-bottom: 6px;">
      <div>${(this as any).name}</div>
    </div>`;
}

export const composeChartOptions = (usageAndClientsData: UsageAndClientsModel[]) => {
  usageAndClientsData.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));

  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      spacingTop: 20,
      spacingBottom: 0,
      plotShadow: false,
      type: "line",
      height: 350,
      style: {
        fontFamily: "Dustin Sans",
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      split: true,
    },
    accessibility: {
      point: {
        valueSuffix: "MB",
      },
    },
    yAxis: [
      {
        title: { text: "Size" },
        min: 0,
        labels: {
          formatter: function (): string {
            return convertMegabytes((this as any).value);
          },
        },
        gridLineWidth: 0,
      },
      {
        title: { text: "Clients" },
        min: 0,
        gridLineWidth: 0,
        opposite: true,
      },
    ],
    xAxis: {
      title: null,
      type: "datetime",
      gridLineWidth: 1,
    },
    plotOptions: {
      line: {
        animation: {
          duration: 1500,
        },
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Size",
        yAxis: 0,
        data: usageAndClientsData.map((item) => {
          const timestamp = Number(new Date(item.date));
          return { x: timestamp, y: item.size };
        }),
        color: "#19495e",
        lineWidth: 3,
        tooltip: {
          pointFormatter: function (): string {
            return `${(this as any).series.name}: <b>${convertMegabytes((this as any).y)}</b>`;
          },
        },
      },
      {
        name: "Clients",
        yAxis: 1,
        data: usageAndClientsData.map((item) => {
          const timestamp = Number(new Date(item.date));
          return { x: timestamp, y: item.numberOfClients };
        }),
        color: "#c48df7",
        lineWidth: 3,
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}</b><br>`,
        },
      },
    ],
    legend: {
      itemDistance: 20,
      alignColumns: true,
      useHTML: true,
      labelFormatter: areaSplineLegendLabelFormatter,
    },
  };
};
