import React, { useCallback, useMemo, useState } from "react";
import { Button, Card, Select, Spinner, Table, TextInput } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useThunkDispatch } from "store";
import { RequestStatus } from "types/common.types";
import { JobStatus } from "types/clients.types";
import { contractSelectors } from "store/contracts";
import { backupJobsActions, backupJobsSelectors, backupJobsThunks } from "store/backupJobs";
import {
  setBackupJobsCurrentPageAC,
  setBackupJobsPerPageAC,
  setBackupJobsSortFieldAC,
} from "store/backupJobs/backupJobs.actions";
import DatePicker from "containers/DatePicker";
import { BackupJobsModel } from "types/jobs.types";
import { SelectOptionItem } from "components/O365UsagePageContent/tabs/O365UsageReport/O365UsageReport";
import PaginationBox from "containers/PaginationBox";
import CardFallback from "containers/CardFallback";
import {
  date7DaysAgoAtNoon,
  dateTodayAtNoon,
  formatDateAndTime,
  formatHoursAndMinutes,
  isDateEarlierThanTodaysNoon,
  isFirstDateNotLaterThanSecond,
} from "utils/time";
import { areFiltersEmpty } from "utils/helpers";

import styles from "./styles.module.css";

export const jobStatusColor = {
  [JobStatus.Success]: styles.successBadge,
  [JobStatus.Warning]: styles.warningBadge,
  [JobStatus.Failed]: styles.failedBadge,
  [JobStatus.Killed]: styles.killedBadge,
};

export const jobStatusOptions: SelectOptionItem[] = [
  { label: "", value: "" },
  { label: "Success", value: JobStatus.Success },
  { label: "Warning", value: JobStatus.Warning },
  { label: "Failed", value: JobStatus.Failed },
  { label: "Killed", value: JobStatus.Killed },
];

export const dummyBackupJob: BackupJobsModel = {
  jobStatus: "_",
  clientId: "_",
  client: "_",
  application: "_",
  labels: "_",
  level: "_",
  backupSize: "_",
  startTime: "",
  endTime: "",
  elapsedTime: "",
};

const BackupJobsTable = () => {
  const { t } = useTranslation("backupJobsPage");
  const dispatch = useDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { config, filters, sortField, sortOrder, perPage, currentPage, requestStatus } = useSelector(
    backupJobsSelectors.getSlice
  );
  const { list, pageCount } = useSelector(backupJobsSelectors.getBackupJobsTable);

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(config.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(config.endDate);

  const handleSelectFilterChange = useCallback(
    (value, fieldName) => {
      dispatch(backupJobsActions.setBackupJobsFiltersAC({ ...filters, [fieldName]: value }));
      dispatch(backupJobsActions.setBackupJobsCurrentPageAC(1));
    },
    [dispatch, filters]
  );
  const handleFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, fieldName) => {
      dispatch(backupJobsActions.setBackupJobsFiltersAC({ ...filters, [fieldName]: event.target.value }));
      dispatch(backupJobsActions.setBackupJobsCurrentPageAC(1));
    },
    [dispatch, filters]
  );

  const columnsConfig = useMemo(
    () => [
      {
        key: "jobStatus",
        dataIndex: "jobStatus",
        title: t("backupJobsTable.jobStatus"),
        width: 150,
        render: (jobStatus: BackupJobsModel["jobStatus"]) =>
          jobStatus !== "_" ? (
            <span className={cn(styles.jobStatusBadge, { [jobStatusColor[jobStatus]]: jobStatus })}>{jobStatus}</span>
          ) : (
            <Select
              data-testid="tableFilter-jobStatus"
              className={styles.select}
              options={jobStatusOptions}
              value={filters.jobStatus}
              onChange={(value) => handleSelectFilterChange(value, "jobStatus")}
            />
          ),
      },
      {
        key: "client",
        dataIndex: "client",
        title: t("backupJobsTable.client"),
        render: (client: BackupJobsModel["client"], job: BackupJobsModel) =>
          client !== "_" ? (
            <Link to={`/baas/clients/${job.clientId}?contractId=${selectedContractId}`} className={styles.clientLink}>
              {client}
            </Link>
          ) : (
            <TextInput
              data-testid="tableFilter-client"
              className={styles.backupJobsFilterInput}
              value={filters.client}
              onChange={(e) => handleFilterChange(e, "client")}
            />
          ),
      },
      {
        key: "application",
        dataIndex: "application",
        title: t("backupJobsTable.application"),
        render: (application: BackupJobsModel["application"]) =>
          application !== "_" ? (
            application
          ) : (
            <TextInput
              data-testid="tableFilter-application"
              className={styles.backupJobsFilterInput}
              value={filters.application}
              onChange={(e) => handleFilterChange(e, "application")}
            />
          ),
      },
      {
        key: "labels",
        dataIndex: "labels",
        title: t("backupJobsTable.labels"),
        render: (labels: string) =>
          labels !== "_" ? (
            labels
          ) : (
            <TextInput
              data-testid="tableFilter-labels"
              className={styles.backupJobsFilterInput}
              value={filters.labels}
              onChange={(e) => handleFilterChange(e, "labels")}
            />
          ),
      },
      {
        key: "level",
        dataIndex: "level",
        title: t("backupJobsTable.level"),
        render: (level: BackupJobsModel["level"]) =>
          level !== "_" ? (
            level
          ) : (
            <TextInput
              data-testid="tableFilter-level"
              className={styles.backupJobsFilterInput}
              value={filters.level}
              onChange={(e) => handleFilterChange(e, "level")}
            />
          ),
      },
      {
        key: "backupSize",
        dataIndex: "backupSize",
        title: t("backupJobsTable.backupSize"),
        render: (backupSize: BackupJobsModel["backupSize"]) =>
          backupSize !== "_" ? (
            backupSize
          ) : (
            <TextInput
              data-testid="tableFilter-backupSize"
              className={styles.backupJobsFilterInput}
              value={filters.backupSize}
              onChange={(e) => handleFilterChange(e, "backupSize")}
            />
          ),
      },
      {
        key: "startTime",
        dataIndex: "startTime",
        title: t("backupJobsTable.startTime"),
        render: (time: BackupJobsModel["startTime"]) => (time ? formatDateAndTime(time) : null),
      },
      {
        key: "endTime",
        dataIndex: "endTime",
        title: t("backupJobsTable.endTime"),
        render: (time: BackupJobsModel["endTime"]) => (time ? formatDateAndTime(time) : null),
      },
      {
        key: "elapsedTime",
        dataIndex: "elapsedTime",
        title: t("backupJobsTable.elapsedTime"),
        render: (elapsedTime: BackupJobsModel["elapsedTime"]) =>
          elapsedTime ? formatHoursAndMinutes(elapsedTime) : null,
      },
    ],
    [filters, handleFilterChange, handleSelectFilterChange, selectedContractId, t]
  );

  const isStartEarlierThanEndDate = useMemo(
    () => isFirstDateNotLaterThanSecond(selectedStartDate, selectedEndDate),
    [selectedEndDate, selectedStartDate]
  );
  const isStartDateValid = useMemo(() => isDateEarlierThanTodaysNoon(selectedStartDate), [selectedStartDate]);
  const isEndDateValid = useMemo(() => isDateEarlierThanTodaysNoon(selectedEndDate), [selectedEndDate]);
  const isLoading = useMemo(() => requestStatus === RequestStatus.PENDING, [requestStatus]);

  const handleStartDateChange = useCallback((value: Date) => setSelectedStartDate(value), []);
  const handleEndDateChange = useCallback((value: Date) => setSelectedEndDate(value), []);
  const handleShowJobsBtnClick = useCallback(() => {
    dispatch(backupJobsActions.setBackupJobsConfigAC({ startDate: selectedStartDate, endDate: selectedEndDate }));
  }, [dispatch, selectedEndDate, selectedStartDate]);

  const handleSortFieldChange = useCallback((field) => dispatch(setBackupJobsSortFieldAC(field)), [dispatch]);
  const handlePerPageChange = useCallback((value) => dispatch(setBackupJobsPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setBackupJobsCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const reloadData = useCallback(() => {
    thunkDispatch(
      backupJobsThunks.getBackupJobsInfo(selectedContractId, {
        startDate: date7DaysAgoAtNoon,
        endDate: dateTodayAtNoon,
      })
    );
  }, [selectedContractId, thunkDispatch]);

  return (
    <Card className={styles.card} data-testid="backupJobsPageContent">
      <div className={styles.cardContent}>
        <div className={styles.filtersBlock}>
          <div className={styles.filterBar} data-testid="startDatePicker">
            <div className={styles.filterLabel}>{t("filterLabels.from")}</div>
            <DatePicker
              value={selectedStartDate}
              onDayChange={handleStartDateChange}
              isError={!isStartEarlierThanEndDate || !isStartDateValid}
            />
          </div>
          <div className={styles.filterBar} data-testid="endDatePicker">
            <div className={styles.filterLabel}>{t("filterLabels.to")}</div>
            <DatePicker
              value={selectedEndDate}
              onDayChange={handleEndDateChange}
              isError={!isStartEarlierThanEndDate || !isEndDateValid}
            />
          </div>
          <div className={styles.showJobsBtnBlock}>
            <Button
              disabled={!isStartEarlierThanEndDate || !isStartDateValid || !isEndDateValid || isLoading}
              onClick={handleShowJobsBtnClick}
            >
              {t("showJobsBtn")}
            </Button>
          </div>
        </div>
        <div className={styles.table}>
          {(requestStatus === RequestStatus.PENDING || requestStatus === RequestStatus.UNCALLED) && <Spinner show />}
          {requestStatus === RequestStatus.SUCCESS && (
            <>
              <div className={styles.tableBlock}>
                <Table
                  dataSource={!list.length && areFiltersEmpty(filters) ? [] : [dummyBackupJob, ...list]}
                  columns={columnsConfig}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  onSortFieldChange={handleSortFieldChange}
                />
              </div>
              <PaginationBox
                perPage={perPage}
                currentPage={currentPage}
                pageCount={pageCount}
                onPerPageChange={handlePerPageChange}
                onChangePage={handlePageChange}
              />
            </>
          )}
          {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
        </div>
      </div>
    </Card>
  );
};

export default BackupJobsTable;
