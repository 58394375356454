import React, { useCallback, useMemo, useState } from "react";
import { Button, Card, Select, Spinner, Table, TextInput } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useThunkDispatch } from "store";
import { RequestStatus } from "types/common.types";
import { JobStatus } from "types/clients.types";
import { contractSelectors } from "store/contracts";
import { restoreJobsActions, restoreJobsSelectors, restoreJobsThunks } from "store/restoreJobs";
import {
  setRestoreJobsCurrentPageAC,
  setRestoreJobsPerPageAC,
  setRestoreJobsSortFieldAC,
} from "store/restoreJobs/restoreJobs.actions";
import DatePicker from "containers/DatePicker";
import { RestoreJobsModel } from "types/jobs.types";
import { SelectOptionItem } from "components/O365UsagePageContent/tabs/O365UsageReport/O365UsageReport";
import PaginationBox from "containers/PaginationBox";
import CardFallback from "containers/CardFallback";
import {
  date30DaysAgoAtNoon,
  dateTodayAtNoon,
  formatDateAndTime,
  formatHoursAndMinutes,
  isDateEarlierThanTodaysNoon,
  isFirstDateNotLaterThanSecond,
} from "utils/time";
import { areFiltersEmpty, convertGigabytes } from "utils/helpers";

import styles from "./styles.module.css";

export const jobStatusColor = {
  [JobStatus.Success]: styles.successBadge,
  [JobStatus.Warning]: styles.warningBadge,
  [JobStatus.Failed]: styles.failedBadge,
  [JobStatus.Killed]: styles.killedBadge,
};

export const jobStatusOptions: SelectOptionItem[] = [
  { label: "", value: "" },
  { label: "Success", value: JobStatus.Success },
  { label: "Warning", value: JobStatus.Warning },
  { label: "Failed", value: JobStatus.Failed },
  { label: "Killed", value: JobStatus.Killed },
];

export const dummyRestoreJob: RestoreJobsModel = {
  jobStatus: "_",
  sourceClient: "_",
  destinationClient: "_",
  application: "_",
  instance: "_",
  user: "_",
  restoreSize: "_",
  startTime: "",
  endTime: "",
  elapsedTime: "",
};

const RestoreJobsTable = () => {
  const { t } = useTranslation("restoreJobsPage");
  const dispatch = useDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { config, filters, sortField, sortOrder, perPage, currentPage, requestStatus } = useSelector(
    restoreJobsSelectors.getSlice
  );
  const { list, pageCount } = useSelector(restoreJobsSelectors.getRestoreJobsTable);

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(config.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(config.endDate);

  const handleSelectFilterChange = useCallback(
    (value, fieldName) => {
      dispatch(restoreJobsActions.setRestoreJobsFiltersAC({ ...filters, [fieldName]: value }));
      dispatch(restoreJobsActions.setRestoreJobsCurrentPageAC(1));
    },
    [dispatch, filters]
  );
  const handleFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, fieldName) => {
      dispatch(restoreJobsActions.setRestoreJobsFiltersAC({ ...filters, [fieldName]: event.target.value }));
      dispatch(restoreJobsActions.setRestoreJobsCurrentPageAC(1));
    },
    [dispatch, filters]
  );

  const columnsConfig = useMemo(
    () => [
      {
        key: "jobStatus",
        dataIndex: "jobStatus",
        title: t("restoreJobsTable.jobStatus"),
        width: 150,
        render: (jobStatus: RestoreJobsModel["jobStatus"]) =>
          jobStatus !== "_" ? (
            <span className={cn(styles.jobStatusBadge, { [jobStatusColor[jobStatus]]: jobStatus })}>{jobStatus}</span>
          ) : (
            <Select
              data-testid="tableFilter-jobStatus"
              className={styles.select}
              options={jobStatusOptions}
              value={filters.jobStatus}
              onChange={(value) => handleSelectFilterChange(value, "jobStatus")}
            />
          ),
      },
      {
        key: "sourceClient",
        dataIndex: "sourceClient",
        title: t("restoreJobsTable.sourceClient"),
        render: (sourceClient: RestoreJobsModel["sourceClient"]) =>
          sourceClient !== "_" ? (
            sourceClient
          ) : (
            <TextInput
              data-testid="tableFilter-sourceClient"
              className={styles.restoreJobsFilterInput}
              value={filters.sourceClient}
              onChange={(e) => handleFilterChange(e, "sourceClient")}
            />
          ),
      },
      {
        key: "destinationClient",
        dataIndex: "destinationClient",
        title: t("restoreJobsTable.destinationClient"),
        render: (destinationClient: RestoreJobsModel["destinationClient"]) =>
          destinationClient !== "_" ? (
            destinationClient
          ) : (
            <TextInput
              data-testid="tableFilter-destinationClient"
              className={styles.restoreJobsFilterInput}
              value={filters.destinationClient}
              onChange={(e) => handleFilterChange(e, "destinationClient")}
            />
          ),
      },
      {
        key: "application",
        dataIndex: "application",
        title: t("restoreJobsTable.application"),
        render: (application: RestoreJobsModel["application"]) =>
          application !== "_" ? (
            application
          ) : (
            <TextInput
              data-testid="tableFilter-application"
              className={styles.restoreJobsFilterInput}
              value={filters.application}
              onChange={(e) => handleFilterChange(e, "application")}
            />
          ),
      },
      {
        key: "instance",
        dataIndex: "instance",
        title: t("restoreJobsTable.instance"),
        render: (instance: RestoreJobsModel["instance"]) =>
          instance !== "_" ? (
            instance
          ) : (
            <TextInput
              data-testid="tableFilter-instance"
              className={styles.restoreJobsFilterInput}
              value={filters.instance}
              onChange={(e) => handleFilterChange(e, "instance")}
            />
          ),
      },
      {
        key: "user",
        dataIndex: "user",
        title: t("restoreJobsTable.user"),
        render: (user: RestoreJobsModel["user"]) =>
          user !== "_" ? (
            user
          ) : (
            <TextInput
              data-testid="tableFilter-user"
              className={styles.restoreJobsFilterInput}
              value={filters.user}
              onChange={(e) => handleFilterChange(e, "user")}
            />
          ),
      },
      {
        key: "restoreSize",
        dataIndex: "restoreSize",
        title: t("restoreJobsTable.restoreSize"),
        render: (restoreSize: RestoreJobsModel["restoreSize"]) =>
          restoreSize !== "_" ? (
            convertGigabytes(restoreSize)
          ) : (
            <TextInput
              data-testid="tableFilter-restoreSize"
              className={styles.restoreJobsFilterInput}
              value={filters.restoreSize}
              onChange={(e) => handleFilterChange(e, "restoreSize")}
            />
          ),
      },
      {
        key: "startTime",
        dataIndex: "startTime",
        title: t("restoreJobsTable.startTime"),
        render: (time: RestoreJobsModel["startTime"]) => (time ? formatDateAndTime(time) : null),
      },
      {
        key: "endTime",
        dataIndex: "endTime",
        title: t("restoreJobsTable.endTime"),
        render: (time: RestoreJobsModel["endTime"]) => (time ? formatDateAndTime(time) : null),
      },
      {
        key: "elapsedTime",
        dataIndex: "elapsedTime",
        title: t("restoreJobsTable.elapsedTime"),
        render: (elapsedTime: RestoreJobsModel["elapsedTime"]) =>
          elapsedTime ? formatHoursAndMinutes(elapsedTime) : null,
      },
    ],
    [filters, handleFilterChange, handleSelectFilterChange, t]
  );

  const isStartEarlierThanEndDate = useMemo(
    () => isFirstDateNotLaterThanSecond(selectedStartDate, selectedEndDate),
    [selectedEndDate, selectedStartDate]
  );
  const isStartDateValid = useMemo(() => isDateEarlierThanTodaysNoon(selectedStartDate), [selectedStartDate]);
  const isEndDateValid = useMemo(() => isDateEarlierThanTodaysNoon(selectedEndDate), [selectedEndDate]);
  const isLoading = useMemo(() => requestStatus === RequestStatus.PENDING, [requestStatus]);

  const handleStartDateChange = useCallback((value: Date) => setSelectedStartDate(value), []);
  const handleEndDateChange = useCallback((value: Date) => setSelectedEndDate(value), []);
  const handleShowJobsBtnClick = useCallback(() => {
    dispatch(restoreJobsActions.setRestoreJobsConfigAC({ startDate: selectedStartDate, endDate: selectedEndDate }));
  }, [dispatch, selectedEndDate, selectedStartDate]);

  const handleSortFieldChange = useCallback((field) => dispatch(setRestoreJobsSortFieldAC(field)), [dispatch]);
  const handlePerPageChange = useCallback((value) => dispatch(setRestoreJobsPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setRestoreJobsCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const reloadData = useCallback(() => {
    thunkDispatch(
      restoreJobsThunks.getRestoreJobsInfo(selectedContractId, {
        startDate: date30DaysAgoAtNoon,
        endDate: dateTodayAtNoon,
      })
    );
  }, [selectedContractId, thunkDispatch]);

  return (
    <Card className={styles.card} data-testid="restoreJobsPageContent">
      <div className={styles.cardContent}>
        <div className={styles.filtersBlock}>
          <div className={styles.filterBar} data-testid="startDatePicker">
            <div className={styles.filterLabel}>{t("filterLabels.from")}</div>
            <DatePicker
              value={selectedStartDate}
              onDayChange={handleStartDateChange}
              isError={!isStartEarlierThanEndDate || !isStartDateValid}
            />
          </div>
          <div className={styles.filterBar} data-testid="endDatePicker">
            <div className={styles.filterLabel}>{t("filterLabels.to")}</div>
            <DatePicker
              value={selectedEndDate}
              onDayChange={handleEndDateChange}
              isError={!isStartEarlierThanEndDate || !isEndDateValid}
            />
          </div>
          <div className={styles.showJobsBtnBlock}>
            <Button
              disabled={!isStartEarlierThanEndDate || !isStartDateValid || !isEndDateValid || isLoading}
              onClick={handleShowJobsBtnClick}
            >
              {t("showJobsBtn")}
            </Button>
          </div>
        </div>
        <div className={styles.table}>
          {(requestStatus === RequestStatus.PENDING || requestStatus === RequestStatus.UNCALLED) && <Spinner show />}
          {requestStatus === RequestStatus.SUCCESS && (
            <>
              <div className={styles.tableBlock}>
                <Table
                  dataSource={!list.length && areFiltersEmpty(filters) ? [] : [dummyRestoreJob, ...list]}
                  columns={columnsConfig}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  onSortFieldChange={handleSortFieldChange}
                />
              </div>
              <PaginationBox
                perPage={perPage}
                currentPage={currentPage}
                pageCount={pageCount}
                onPerPageChange={handlePerPageChange}
                onChangePage={handlePageChange}
              />
            </>
          )}
          {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
        </div>
      </div>
    </Card>
  );
};

export default RestoreJobsTable;
