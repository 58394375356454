import * as types from "./clients.types";

export const clientListRequestAC: types.ClientListRequestAC = () => ({
  type: types.CLIENT_LIST_REQUEST,
});

export const clientListRequestSuccessAC: types.ClientListRequestSuccessAC = (payload) => ({
  type: types.CLIENT_LIST_REQUEST_SUCCESS,
  payload,
});

export const clientListRequestFailureAC: types.ClientListRequestFailureAC = () => ({
  type: types.CLIENT_LIST_REQUEST_FAILURE,
});

export const setClientListFiltersAC: types.SetClientFiltersAC = (payload) => ({
  type: types.SET_CLIENT_LIST_FILTERS,
  payload,
});

export const labelListRequestSuccessAC: types.LabelListRequestSuccessAC = (payload) => ({
  type: types.LABEL_LIST_REQUEST_SUCCESS,
  payload,
});

export const clientUsageRequestAC: types.ClientUsageRequestAC = () => ({
  type: types.CLIENT_USAGE_REQUEST,
});

export const clientUsageRequestSuccessAC: types.ClientUsageRequestSuccessAC = (payload) => ({
  type: types.CLIENT_USAGE_REQUEST_SUCCESS,
  payload,
});

export const clientUsageRequestFailureAC: types.ClientUsageRequestFailureAC = () => ({
  type: types.CLIENT_USAGE_REQUEST_FAILURE,
});

export const clientBackupJobsRequestAC: types.ClientBackupJobsRequestAC = () => ({
  type: types.CLIENT_BACKUP_JOBS_REQUEST,
});

export const clientBackupJobsRequestSuccessAC: types.ClientBackupJobsRequestSuccessAC = (payload) => ({
  type: types.CLIENT_BACKUP_JOBS_REQUEST_SUCCESS,
  payload,
});

export const clientBackupJobsRequestFailureAC: types.ClientBackupJobsRequestFailureAC = () => ({
  type: types.CLIENT_BACKUP_JOBS_REQUEST_FAILURE,
});

export const clientRestoreJobsRequestAC: types.ClientRestoreJobsRequestAC = () => ({
  type: types.CLIENT_RESTORE_JOBS_REQUEST,
});

export const clientRestoreJobsRequestSuccessAC: types.ClientRestoreJobsRequestSuccessAC = (payload) => ({
  type: types.CLIENT_RESTORE_JOBS_REQUEST_SUCCESS,
  payload,
});

export const clientRestoreJobsRequestFailureAC: types.ClientRestoreJobsRequestFailureAC = () => ({
  type: types.CLIENT_RESTORE_JOBS_REQUEST_FAILURE,
});

export const setClientListSortFieldAC: types.SetClientListSortFieldAC = (payload) => ({
  type: types.SET_CLIENT_LIST_SORT_FIELD,
  payload,
});

export const setClientListPerPageAC: types.SetClientListPerPageAC = (payload) => ({
  type: types.SET_CLIENT_LIST_PER_PAGE,
  payload,
});

export const setClientListCurrentPageAC: types.SetClientListCurrentPageAC = (payload) => ({
  type: types.SET_CLIENT_LIST_CURRENT_PAGE,
  payload,
});

export const setClientBackupJobsSortFieldAC: types.SetClientBackupJobsSortFieldAC = (payload) => ({
  type: types.SET_CLIENT_BACKUP_JOBS_SORT_FIELD,
  payload,
});

export const setClientBackupJobsPerPageAC: types.SetClientBackupJobsPerPageAC = (payload) => ({
  type: types.SET_CLIENT_BACKUP_JOBS_PER_PAGE,
  payload,
});

export const setClientBackupJobsCurrentPageAC: types.SetClientBackupJobsCurrentPageAC = (payload) => ({
  type: types.SET_CLIENT_BACKUP_JOBS_CURRENT_PAGE,
  payload,
});

export const setClientRestoreJobsSortFieldAC: types.SetClientRestoreJobsSortFieldAC = (payload) => ({
  type: types.SET_CLIENT_RESTORE_JOBS_SORT_FIELD,
  payload,
});

export const setClientRestoreJobsPerPageAC: types.SetClientRestoreJobsPerPageAC = (payload) => ({
  type: types.SET_CLIENT_RESTORE_JOBS_PER_PAGE,
  payload,
});

export const setClientRestoreJobsCurrentPageAC: types.SetClientRestoreJobsCurrentPageAC = (payload) => ({
  type: types.SET_CLIENT_RESTORE_JOBS_CURRENT_PAGE,
  payload,
});
