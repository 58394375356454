export const formatDate = (inputDate: string | number | Date): string => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
};

export const formatDateAndTime = (inputDate: string | number | Date): string => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")} ${String(hour).padStart(
    2,
    "0"
  )}:${String(minute).padStart(2, "0")}:${String(second).padStart(2, "0")}`;
};

export const formatAMPM = (inputDate: string | number | Date) => {
  const date = new Date(inputDate);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours %= 12;
  hours = hours !== 0 ? hours : 12; // the hour '0' should be '12'
  return `${hours}:${String(minutes).padStart(2, "0")} ${ampm}`;
};

export const formatHoursAndMinutes = (input: string) => {
  const inputNum = Number(input);
  const minutes = (inputNum % 60).toString().padStart(2, "0");
  const hours = Math.floor(inputNum / 60)
    .toString()
    .padStart(2, "0");

  return `${hours}h, ${minutes}m`;
};

export const dateTodayAtNoon = new Date(new Date().setHours(12, 0, 0, 0));
export const date7DaysAgoAtNoon = new Date(
  new Date(new Date().setDate(new Date().getDate() - 7)).setHours(12, 0, 0, 0)
);
export const date30DaysAgoAtNoon = new Date(
  new Date(new Date().setDate(new Date().getDate() - 30)).setHours(12, 0, 0, 0)
);
export const dateYearAgoAtNoon = new Date(
  new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(12, 0, 0, 0)
);

export const isFirstDateNotLaterThanSecond = (firstDate: Date, secondDate: Date) =>
  new Date(firstDate).valueOf() <= new Date(secondDate).valueOf();

export const isDateEarlierThanTodaysNoon = (date: Date) => new Date(date).valueOf() <= new Date().setHours(12, 0, 0, 0);
