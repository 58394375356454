import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader";
import O365UsagePageContent from "components/O365UsagePageContent";

const O365UsagePage: React.FC = () => {
  const { t } = useTranslation("o365UsagePage");

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      <O365UsagePageContent />
    </PageLayout>
  );
};

export default O365UsagePage;
