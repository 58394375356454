// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_card__CWG1y {\n  overflow: hidden;\n  border-color: var(--color-dark-blue) !important;\n  margin-bottom: 16px;\n}\n\n.styles_card__CWG1y h3 {\n  padding: 8px 16px;\n  font-weight: 700 !important;\n}\n\n.styles_cardContent__zaTQH {\n  position: relative;\n  overflow: auto;\n  height: 350px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ClientDetailsPageContent/ClientUsageChart/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,+CAA+C;EAC/C,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,aAAa;AACf","sourcesContent":[".card {\n  overflow: hidden;\n  border-color: var(--color-dark-blue) !important;\n  margin-bottom: 16px;\n}\n\n.card h3 {\n  padding: 8px 16px;\n  font-weight: 700 !important;\n}\n\n.cardContent {\n  position: relative;\n  overflow: auto;\n  height: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "styles_card__CWG1y",
	"cardContent": "styles_cardContent__zaTQH"
};
export default ___CSS_LOADER_EXPORT___;
