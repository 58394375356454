import { backupApiClient } from "./clients";
import { O365UsageResponse, UsageAndClientsResponse } from "types/startPageApi.types";
import { DetailedUsageResponse, UsageRequestConfig, O365UsersResponse } from "types/usageReports.types";
import { formatDate } from "utils/time";

export const getUsageSummary = (contractId: string, requestConfig: UsageRequestConfig) =>
  backupApiClient.request<UsageAndClientsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/usage-and-clients?startDate=${formatDate(
      requestConfig.startDate
    )}&endDate=${formatDate(requestConfig.endDate)}&reportType=${requestConfig.reportType}`,
  });

export const getUsageDetailed = (contractId: string, requestConfig: Omit<UsageRequestConfig, "reportType">) =>
  backupApiClient.request<DetailedUsageResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/detailed-usage?startDate=${formatDate(
      requestConfig.startDate
    )}&endDate=${formatDate(requestConfig.endDate)}`,
  });

export const getO365Usage = (contractId: string, requestConfig: UsageRequestConfig) =>
  backupApiClient.request<O365UsageResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/o365-usage?startDate=${formatDate(
      requestConfig.startDate
    )}&endDate=${formatDate(requestConfig.endDate)}&reportType=${requestConfig.reportType}`,
  });

export const getO365Users = (contractId: string, date: Date) =>
  backupApiClient.request<O365UsersResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/o365-users?date=${formatDate(date)}`,
  });

export const sendReportViaEmail = (contractId: string, type: string, params: object) =>
  backupApiClient.request({
    url: `/v1/contracts/${contractId}/backup/${type}`,
    method: "POST",
    params,
    timeout: 30000,
  });
