import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader";
import UsagePageContent from "components/UsagePageContent";
import useFetchUsageReport from "hooks/useFetchUsageReport";

const UsagePage: React.FC = () => {
  const { t } = useTranslation("usagePage");
  useFetchUsageReport();

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      <UsagePageContent />
    </PageLayout>
  );
};

export default UsagePage;
