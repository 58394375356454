import { JobStatusModel } from "types/startPageApi.types";

export function pieChartLegendLabelFormatter(this: {
  itemDistance: number;
  alignColumns: boolean;
  useHTML: boolean;
  labelFormatters: () => string;
}) {
  return `
    <div style="font-weight: normal; font-size: 14px; line-height: 1.4; font-family: Dustin Sans; margin-bottom: 4px; ">
      <div>${(this as any).name}</div>
    </div>`;
}

const CHART_SECONDARY_COLORS = ["#44d9fc", "#e59a7f", "#c48df7", "#f2c94c", "#b4b4b4"];
const popGreenColor = "#4ef09d";
const popRedColor = "#ff5a5a";

const getStatusColor = (status: string, colors: string[]) => {
  switch (status) {
    case "Completed":
      return popGreenColor;
    case "Failed":
      return popRedColor;
    default:
      return colors.shift();
  }
};

export const composeChartOptions = (JobStatusDay: JobStatusModel[]) => {
  let colors = [...CHART_SECONDARY_COLORS, ...CHART_SECONDARY_COLORS, ...CHART_SECONDARY_COLORS];

  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      spacingBottom: 0,
      plotShadow: false,
      type: "pie",
      height: 350,
      style: {
        fontFamily: "Dustin Sans",
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: "Quantity: <b>{point.y}</b><br> Distribution: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data: JobStatusDay.map((item) => ({
          name: item.status,
          y: item.count,
          color: getStatusColor(item.status, colors),
        })),
      },
    ],
    legend: {
      itemDistance: 16,
      alignColumns: true,
      useHTML: true,
      labelFormatter: pieChartLegendLabelFormatter,
    },
  };
};
