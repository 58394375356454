import { AppThunk } from "store";
import * as clientsApi from "api/clients.api";
import * as clientsActions from "./clients.actions";
import i18n from "../../locales";
import { notificationErrorAC } from "../notification/notification.actions";
import { parseApiErrorMessage } from "utils/helpers";
import { JobsRequestConfig } from "types/clients.types";

export const getClientListInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clientsActions.clientListRequestAC());
      const { data } = await clientsApi.getClientList(selectedContractId);
      dispatch(clientsActions.clientListRequestSuccessAC(data.clients));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "CLIENT LIST REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:clientsListRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(clientsActions.clientListRequestFailureAC());
    }
  };

export const getLabelListInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      const { data } = await clientsApi.getAllLabels(selectedContractId);
      dispatch(clientsActions.labelListRequestSuccessAC(data.labels));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "LABEL LIST REQUEST");
    }
  };

export const getClientUsageInfo =
  (selectedContractId: string, clientId: number | string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clientsActions.clientUsageRequestAC());
      const { data } = await clientsApi.getClientUsage(selectedContractId, clientId);
      dispatch(clientsActions.clientUsageRequestSuccessAC(data.usage));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "CLIENT USAGE REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:clientsUsageRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(clientsActions.clientUsageRequestFailureAC());
    }
  };

export const getClientBackupJobsInfo =
  (selectedContractId: string, clientId: number | string, requestConfig: JobsRequestConfig): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clientsActions.clientBackupJobsRequestAC());
      const { data } = await clientsApi.getClientBackupJobs(selectedContractId, clientId, requestConfig);
      dispatch(clientsActions.clientBackupJobsRequestSuccessAC(data));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "CLIENT BACKUP JOBS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:clientsBackupJobsRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(clientsActions.clientBackupJobsRequestFailureAC());
    }
  };

export const getClientRestoreJobsInfo =
  (selectedContractId: string, clientName: string, requestConfig: JobsRequestConfig): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clientsActions.clientRestoreJobsRequestAC());
      const { data } = await clientsApi.getClientRestoreJobs(selectedContractId, clientName, requestConfig);
      dispatch(clientsActions.clientRestoreJobsRequestSuccessAC(data.restoreJobs));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "CLIENT RESTORE JOBS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:clientsRestoreJobsRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(clientsActions.clientRestoreJobsRequestFailureAC());
    }
  };
