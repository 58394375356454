import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, Select } from "@skyportal/ui-kit";
import { useSelector } from "react-redux";
import { contractSelectors } from "store/contracts";
import { generateReportUrl } from "utils/helpers";

import styles from "./styles.module.css";

export const PDF_REPORT_ACCEPT_TYPE = "pdf";
export const CSV_REPORT_ACCEPT_TYPE = "csv";
export const HTML_REPORT_ACCEPT_TYPE = "html";
const EXPORT_TYPES = [PDF_REPORT_ACCEPT_TYPE, CSV_REPORT_ACCEPT_TYPE];

export interface ExportModalProps {
  open: boolean;
  onClose: () => void;
  reportType: string;
  reportParams: Record<string, string | number>;
}

const ExportModal = ({ open, onClose, reportType, reportParams }: ExportModalProps) => {
  const { t } = useTranslation("exportReportModal");
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const [acceptType, setAcceptType] = useState(PDF_REPORT_ACCEPT_TYPE);
  const typeOptions = EXPORT_TYPES.map((type) => ({
    label: t(type),
    value: type,
  }));

  const handleAcceptTypeChange = (value: string) => setAcceptType(value);

  return (
    <Modal open={open} onClose={onClose} size="small" title={t("title")}>
      <div className={styles.content}>
        <span className={styles.controlLabel}>{t("Format")}</span>
        <Select
          data-testid="reportTypeSelect"
          options={typeOptions}
          value={acceptType}
          onChange={handleAcceptTypeChange}
        />
      </div>
      <div className={styles.cardFooter}>
        <Button type="secondary" onClick={onClose}>
          {t("common:Cancel")}
        </Button>
        <a href={generateReportUrl(selectedContractId, reportType, acceptType, reportParams)} download>
          <Button onClick={onClose} type="primary">
            <span>{t("common:Export")}</span>
          </Button>
        </a>
      </div>
    </Modal>
  );
};

export default ExportModal;
