import React from "react";
import useFetchClientDetails from "hooks/useFetchClientDetails";
import PageLayout from "layouts/PageLayout";
import ClientDetailsPageHeader from "components/ClientDetailsPageContent/ClientDetailsPageHeader";
import ClientDetailsPageContent from "components/ClientDetailsPageContent";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { clientsSelectors } from "store/clients";
import ClientDetailsPageFallback from "components/ClientDetailsPageContent/ClientDetailsPageFallback/ClientDetailsPageFallback";

const ClientDetailsPage: React.FC = () => {
  useFetchClientDetails();

  const { pathname } = useLocation();
  const { list: clientList } = useSelector(clientsSelectors.getClients);
  const clientId = Number(pathname.replace("/baas/clients/", ""));
  const client = clientList.find((item) => item.clientId === clientId);

  return client ? (
    <PageLayout>
      <ClientDetailsPageHeader client={client} />
      <ClientDetailsPageContent client={client} />
    </PageLayout>
  ) : (
    <ClientDetailsPageFallback />
  );
};

export default ClientDetailsPage;
