import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { usageReportsSelectors } from "store/usageReports";
import {
  setUsageReportCurrentPageAC,
  setUsageReportPerPageAC,
  setUsageReportSortFieldAC,
} from "store/usageReports/usageReports.actions";
import PaginationBox from "containers/PaginationBox";
import { O365UsageModel } from "types/startPageApi.types";
import { ReportType } from "types/usageReports.types";
import { RequestStatus } from "types/common.types";
import { convertMegabytes } from "utils/helpers";

import styles from "../styles.module.css";

const dateColumnNameConfig = {
  [ReportType.Daily]: "Day",
  [ReportType.Weekly]: "Week",
  [ReportType.Monthly]: "Month",
  [ReportType.Quarterly]: "Quarter",
  [ReportType.Yearly]: "Year",
};

const UsageSummaryTable = () => {
  const { t } = useTranslation("usagePage");
  const dispatch = useDispatch();
  const { config, requestStatus } = useSelector(usageReportsSelectors.getUsage);
  const { sortField, sortOrder, perPage, currentPage } = useSelector(usageReportsSelectors.getSlice);
  const { list, pageCount } = useSelector(usageReportsSelectors.getUsageSummaryTable);

  const columnsConfig = useMemo(
    () => [
      { key: "date", dataIndex: "date", title: dateColumnNameConfig[config.reportType] },
      {
        key: "numberOfClients",
        dataIndex: "numberOfClients",
        title: t("usageSummaryTable.numberOfClients"),
      },
      {
        key: "size",
        dataIndex: "size",
        title: t("usageSummaryTable.size"),
        render: (size: O365UsageModel["size"]) => convertMegabytes(size),
      },
    ],
    [config.reportType, t]
  );

  const handleSortFieldChange = useCallback((field) => dispatch(setUsageReportSortFieldAC(field)), [dispatch]);
  const handlePerPageChange = useCallback((value) => dispatch(setUsageReportPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setUsageReportCurrentPageAC(value)), [dispatch]);

  return requestStatus === RequestStatus.SUCCESS ? (
    <>
      <div className={styles.tableBlock}>
        <Table
          dataSource={list}
          columns={columnsConfig}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortFieldChange={handleSortFieldChange}
        />
      </div>
      <PaginationBox
        perPage={perPage}
        currentPage={currentPage}
        pageCount={pageCount}
        onPerPageChange={handlePerPageChange}
        onChangePage={handlePageChange}
      />
    </>
  ) : null;
};

export default UsageSummaryTable;
