import { RequestStatus } from "types/common.types";
import {
  ClientBackupJobModel,
  JobsStatusModel,
  ClientBackupJobsResponse,
  ClientModel,
  ClientUsageModel,
  LabelModel,
  ClientRestoreJobModel,
} from "types/clients.types";

export interface WithRequestStatus {
  requestStatus: RequestStatus;
}

export type SortFieldType = keyof ClientModel | "";
export type SortOrderType = "asc" | "desc";

export interface Client {
  list: ClientModel[];
  sortField: SortFieldType;
  sortOrder: SortOrderType;
  currentPage: number;
  perPage: number;
  filters: Record<keyof Omit<ClientModel, "clientId">, string>;
}

export interface ClientDetails {
  backupJobsStatuses: JobsStatusModel;
  backupJobs: {
    list: ClientBackupJobModel[];
    sortField: SortFieldType;
    sortOrder: SortOrderType;
    currentPage: number;
    perPage: number;
  } & WithRequestStatus;
  restoreJobs: {
    list: ClientRestoreJobModel[];
    sortField: SortFieldType;
    sortOrder: SortOrderType;
    currentPage: number;
    perPage: number;
  } & WithRequestStatus;
  usage: { list: ClientUsageModel[] } & WithRequestStatus;
}

export interface ClientPageStateSlice {
  readonly clients: Client & WithRequestStatus;
  readonly labels: LabelModel[];
  readonly clientDetails: ClientDetails;
}

export const CLIENT_LIST_REQUEST = "CLIENT_LIST_REQUEST";
export type ClientListRequestAction = {
  type: typeof CLIENT_LIST_REQUEST;
};
export type ClientListRequestAC = () => ClientListRequestAction;

export const CLIENT_LIST_REQUEST_SUCCESS = "CLIENT_LIST_REQUEST_SUCCESS";
export type ClientListRequestSuccessAction = {
  type: typeof CLIENT_LIST_REQUEST_SUCCESS;
  payload: ClientModel[];
};
export type ClientListRequestSuccessAC = (payload: ClientModel[]) => ClientListRequestSuccessAction;

export const CLIENT_LIST_REQUEST_FAILURE = "CLIENT_LIST_REQUEST_FAILURE";
export type ClientListRequestFailureAction = {
  type: typeof CLIENT_LIST_REQUEST_FAILURE;
};
export type ClientListRequestFailureAC = () => ClientListRequestFailureAction;

export const SET_CLIENT_LIST_FILTERS = "SET_CLIENT_LIST_FILTERS";
export type SetClientFiltersAction = { type: typeof SET_CLIENT_LIST_FILTERS; payload: Client["filters"] };
export type SetClientFiltersAC = (payload: Client["filters"]) => SetClientFiltersAction;

export const LABEL_LIST_REQUEST_SUCCESS = "LABEL_LIST_REQUEST_SUCCESS";
export type LabelListRequestSuccessAction = { type: typeof LABEL_LIST_REQUEST_SUCCESS; payload: LabelModel[] };
export type LabelListRequestSuccessAC = (payload: LabelModel[]) => LabelListRequestSuccessAction;

export const CLIENT_USAGE_REQUEST = "CLIENT_USAGE_REQUEST";
export type ClientUsageRequestAction = {
  type: typeof CLIENT_USAGE_REQUEST;
};
export type ClientUsageRequestAC = () => ClientUsageRequestAction;

export const CLIENT_USAGE_REQUEST_SUCCESS = "CLIENT_USAGE_REQUEST_SUCCESS";
export type ClientUsageRequestSuccessAction = {
  type: typeof CLIENT_USAGE_REQUEST_SUCCESS;
  payload: ClientUsageModel[];
};
export type ClientUsageRequestSuccessAC = (payload: ClientUsageModel[]) => ClientUsageRequestSuccessAction;

export const CLIENT_USAGE_REQUEST_FAILURE = "CLIENT_USAGE_REQUEST_FAILURE";
export type ClientUsageRequestFailureAction = {
  type: typeof CLIENT_USAGE_REQUEST_FAILURE;
};
export type ClientUsageRequestFailureAC = () => ClientUsageRequestFailureAction;

export const CLIENT_BACKUP_JOBS_REQUEST = "CLIENT_BACKUP_JOBS_REQUEST";
export type ClientBackupJobsRequestAction = {
  type: typeof CLIENT_BACKUP_JOBS_REQUEST;
};
export type ClientBackupJobsRequestAC = () => ClientBackupJobsRequestAction;

export const CLIENT_BACKUP_JOBS_REQUEST_SUCCESS = "CLIENT_BACKUP_JOBS_REQUEST_SUCCESS";
export type ClientBackupJobsRequestSuccessAction = {
  type: typeof CLIENT_BACKUP_JOBS_REQUEST_SUCCESS;
  payload: ClientBackupJobsResponse;
};
export type ClientBackupJobsRequestSuccessAC = (
  payload: ClientBackupJobsResponse
) => ClientBackupJobsRequestSuccessAction;

export const CLIENT_BACKUP_JOBS_REQUEST_FAILURE = "CLIENT_BACKUP_JOBS_REQUEST_FAILURE";
export type ClientBackupJobsRequestFailureAction = {
  type: typeof CLIENT_BACKUP_JOBS_REQUEST_FAILURE;
};
export type ClientBackupJobsRequestFailureAC = () => ClientBackupJobsRequestFailureAction;

export const CLIENT_RESTORE_JOBS_REQUEST = "CLIENT_RESTORE_JOBS_REQUEST";
export type ClientRestoreJobsRequestAction = {
  type: typeof CLIENT_RESTORE_JOBS_REQUEST;
};
export type ClientRestoreJobsRequestAC = () => ClientRestoreJobsRequestAction;

export const CLIENT_RESTORE_JOBS_REQUEST_SUCCESS = "CLIENT_RESTORE_JOBS_REQUEST_SUCCESS";
export type ClientRestoreJobsRequestSuccessAction = {
  type: typeof CLIENT_RESTORE_JOBS_REQUEST_SUCCESS;
  payload: ClientRestoreJobModel[];
};
export type ClientRestoreJobsRequestSuccessAC = (
  payload: ClientRestoreJobModel[]
) => ClientRestoreJobsRequestSuccessAction;

export const CLIENT_RESTORE_JOBS_REQUEST_FAILURE = "CLIENT_RESTORE_JOBS_REQUEST_FAILURE";
export type ClientRestoreJobsRequestFailureAction = {
  type: typeof CLIENT_RESTORE_JOBS_REQUEST_FAILURE;
};
export type ClientRestoreJobsRequestFailureAC = () => ClientRestoreJobsRequestFailureAction;

export const SET_CLIENT_LIST_SORT_FIELD = "SET_CLIENT_LIST_SORT_FIELD";
export type SetClientListSortFieldAction = {
  type: typeof SET_CLIENT_LIST_SORT_FIELD;
  payload: SortFieldType;
};
export type SetClientListSortFieldAC = (payload: SortFieldType) => SetClientListSortFieldAction;

export const SET_CLIENT_LIST_PER_PAGE = "SET_CLIENT_LIST_PER_PAGE";
export type SetClientListPerPageAction = {
  type: typeof SET_CLIENT_LIST_PER_PAGE;
  payload: number;
};
export type SetClientListPerPageAC = (payload: number) => SetClientListPerPageAction;

export const SET_CLIENT_LIST_CURRENT_PAGE = "SET_CLIENT_LIST_CURRENT_PAGE";
export type SetClientListCurrentPageAction = {
  type: typeof SET_CLIENT_LIST_CURRENT_PAGE;
  payload: number;
};
export type SetClientListCurrentPageAC = (payload: number) => SetClientListCurrentPageAction;

export const SET_CLIENT_BACKUP_JOBS_SORT_FIELD = "SET_CLIENT_BACKUP_JOBS_SORT_FIELD";
export type SetClientBackupJobsSortFieldAction = {
  type: typeof SET_CLIENT_BACKUP_JOBS_SORT_FIELD;
  payload: SortFieldType;
};
export type SetClientBackupJobsSortFieldAC = (payload: SortFieldType) => SetClientBackupJobsSortFieldAction;

export const SET_CLIENT_BACKUP_JOBS_PER_PAGE = "SET_CLIENT_BACKUP_JOBS_PER_PAGE";
export type SetClientBackupJobsPerPageAction = {
  type: typeof SET_CLIENT_BACKUP_JOBS_PER_PAGE;
  payload: number;
};
export type SetClientBackupJobsPerPageAC = (payload: number) => SetClientBackupJobsPerPageAction;

export const SET_CLIENT_BACKUP_JOBS_CURRENT_PAGE = "SET_CLIENT_BACKUP_JOBS_CURRENT_PAGE";
export type SetClientBackupJobsCurrentPageAction = {
  type: typeof SET_CLIENT_BACKUP_JOBS_CURRENT_PAGE;
  payload: number;
};
export type SetClientBackupJobsCurrentPageAC = (payload: number) => SetClientBackupJobsCurrentPageAction;

export const SET_CLIENT_RESTORE_JOBS_SORT_FIELD = "SET_CLIENT_RESTORE_JOBS_SORT_FIELD";
export type SetClientRestoreJobsSortFieldAction = {
  type: typeof SET_CLIENT_RESTORE_JOBS_SORT_FIELD;
  payload: SortFieldType;
};
export type SetClientRestoreJobsSortFieldAC = (payload: SortFieldType) => SetClientRestoreJobsSortFieldAction;

export const SET_CLIENT_RESTORE_JOBS_PER_PAGE = "SET_CLIENT_RESTORE_JOBS_PER_PAGE";
export type SetClientRestoreJobsPerPageAction = {
  type: typeof SET_CLIENT_RESTORE_JOBS_PER_PAGE;
  payload: number;
};
export type SetClientRestoreJobsPerPageAC = (payload: number) => SetClientRestoreJobsPerPageAction;

export const SET_CLIENT_RESTORE_JOBS_CURRENT_PAGE = "SET_CLIENT_RESTORE_JOBS_CURRENT_PAGE";
export type SetClientRestoreJobsCurrentPageAction = {
  type: typeof SET_CLIENT_RESTORE_JOBS_CURRENT_PAGE;
  payload: number;
};
export type SetClientRestoreJobsCurrentPageAC = (payload: number) => SetClientRestoreJobsCurrentPageAction;

export type ClientPageAction =
  | ClientListRequestAction
  | ClientListRequestSuccessAction
  | ClientListRequestFailureAction
  | SetClientFiltersAction
  | LabelListRequestSuccessAction
  | ClientUsageRequestAction
  | ClientUsageRequestSuccessAction
  | ClientUsageRequestFailureAction
  | ClientBackupJobsRequestAction
  | ClientBackupJobsRequestSuccessAction
  | ClientBackupJobsRequestFailureAction
  | ClientRestoreJobsRequestAction
  | ClientRestoreJobsRequestSuccessAction
  | ClientRestoreJobsRequestFailureAction
  | SetClientListSortFieldAction
  | SetClientListPerPageAction
  | SetClientListCurrentPageAction
  | SetClientBackupJobsSortFieldAction
  | SetClientBackupJobsPerPageAction
  | SetClientBackupJobsCurrentPageAction
  | SetClientRestoreJobsSortFieldAction
  | SetClientRestoreJobsPerPageAction
  | SetClientRestoreJobsCurrentPageAction;
