import React from "react";
import cn from "classnames";
import { Icon, Spinner } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import CardFallback from "containers/CardFallback";
import { RequestStatus } from "types/common.types";
import { JobsStatusModel } from "types/clients.types";

import styles from "./styles.module.css";

interface AlertBoxCardsProps {
  statuses: JobsStatusModel;
  requestStatus: RequestStatus;
  reloadDataFunc: (...args: any) => void;
  last30Days?: boolean;
}

const AlertBoxCards = ({ statuses, requestStatus, reloadDataFunc, last30Days = false }: AlertBoxCardsProps) => {
  const { t } = useTranslation("backupStartPage");

  return (
    <div className={styles.alertBoxContainer}>
      <div className={styles.alertBoxItem} data-testid="alertBoxSuccess">
        <div className={cn(styles.root, styles.successBox)}>
          {(requestStatus === RequestStatus.PENDING || requestStatus === RequestStatus.UNCALLED) && (
            <Spinner data-testid="alertBoxSpinner" show />
          )}
          {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadDataFunc} />}
          <div className={styles.alertBox}>
            <Icon icon="thumbs-up" className={styles.icon} />
            <div className={styles.contentBlock}>
              <h4>{t("alertBoxesBlock.SUCCESS")}</h4>
              <span className={styles.jobsQtty}>{t("alertBoxesBlock.jobsQtty", { count: statuses.successCount })}</span>
              <div className={styles.horizontalLine} />
              <div className={styles.rateLast30Days}>
                {last30Days
                  ? t("alertBoxesBlock.rateInLast30days", {
                      number: statuses.successRate?.toFixed(2) ?? "0.00",
                      status: "success",
                    })
                  : t("alertBoxesBlock.rate", {
                      number: statuses.successRate?.toFixed(2) ?? "0.00",
                      status: "success",
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.alertBoxItem} data-testid="alertBoxWarning">
        <div className={cn(styles.root, styles.warningBox)}>
          {(requestStatus === RequestStatus.PENDING || requestStatus === RequestStatus.UNCALLED) && (
            <Spinner data-testid="alertBoxSpinner" show />
          )}
          {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadDataFunc} />}
          <div className={styles.alertBox}>
            <Icon icon="info-attention" className={styles.icon} />
            <div className={styles.contentBlock}>
              <h4>{t("alertBoxesBlock.WARNING")}</h4>
              <span className={styles.jobsQtty}>{t("alertBoxesBlock.jobsQtty", { count: statuses.warningCount })}</span>
              <div className={styles.horizontalLine} />
              <div className={styles.rateLast30Days}>
                {last30Days
                  ? t("alertBoxesBlock.rateInLast30days", {
                      number: statuses.warningRate?.toFixed(2) ?? "0.00",
                      status: "warning",
                    })
                  : t("alertBoxesBlock.rate", {
                      number: statuses.warningRate?.toFixed(2) ?? "0.00",
                      status: "warning",
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.alertBoxItem} data-testid="alertBoxFailed">
        <div className={cn(styles.root, styles.failedBox)}>
          {(requestStatus === RequestStatus.PENDING || requestStatus === RequestStatus.UNCALLED) && (
            <Spinner data-testid="alertBoxSpinner" show />
          )}
          {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadDataFunc} />}
          <div className={styles.alertBox}>
            <Icon icon="thumbs-down" className={styles.icon} />
            <div className={styles.contentBlock}>
              <h4>{t("alertBoxesBlock.FAILED")}</h4>
              <span className={styles.jobsQtty}>{t("alertBoxesBlock.jobsQtty", { count: statuses.failedCount })}</span>
              <div className={styles.horizontalLine} />
              <div className={styles.rateLast30Days}>
                {last30Days
                  ? t("alertBoxesBlock.rateInLast30days", {
                      number: statuses.failedRate?.toFixed(2) ?? "0.00",
                      status: "failed",
                    })
                  : t("alertBoxesBlock.rate", { number: statuses.failedRate?.toFixed(2) ?? "0.00", status: "failed" })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.alertBoxItem} data-testid="alertBoxKilled">
        <div className={cn(styles.root, styles.killedBox)}>
          {(requestStatus === RequestStatus.PENDING || requestStatus === RequestStatus.UNCALLED) && (
            <Spinner data-testid="alertBoxSpinner" show />
          )}
          {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadDataFunc} />}
          <div className={styles.alertBox}>
            <Icon icon="stop" className={styles.icon} />
            <div className={styles.contentBlock}>
              <h4>{t("alertBoxesBlock.KILLED")}</h4>
              <span className={styles.jobsQtty}>{t("alertBoxesBlock.jobsQtty", { count: statuses.killedCount })}</span>
              <div className={styles.horizontalLine} />
              <div className={styles.rateLast30Days}>
                {last30Days
                  ? t("alertBoxesBlock.rateInLast30days", {
                      number: statuses.killedRate?.toFixed(2) ?? "0.00",
                      status: "killed",
                    })
                  : t("alertBoxesBlock.rate", { number: statuses.killedRate?.toFixed(2) ?? "0.00", status: "killed" })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertBoxCards;
