import { AppThunk } from "store";
import * as jobsApi from "api/jobs.api";
import * as backupJobsActions from "./backupJobs.actions";
import i18n from "../../locales";
import { notificationErrorAC } from "../notification/notification.actions";
import { parseApiErrorMessage } from "utils/helpers";
import { JobsRequestConfig } from "types/clients.types";

export const getBackupJobsInfo =
  (selectedContractId: string, requestConfig: JobsRequestConfig): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(backupJobsActions.backupJobsRequestAC());
      const { data } = await jobsApi.getBackupJobs(selectedContractId, requestConfig);
      dispatch(backupJobsActions.backupJobsRequestSuccessAC(data));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "BACKUP JOBS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:backupJobsRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(backupJobsActions.backupJobsRequestFailureAC());
    }
  };
