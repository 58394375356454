import { RequestStatus } from "types/common.types";
import {
  ClientSizeModel,
  ClientsModel,
  JobStatusModel,
  O365UsageModel,
  SizeModel,
  SuspiciousFilesModel,
  UsageAndClientsModel,
} from "types/startPageApi.types";

export interface WithRequestStatus {
  requestStatus: RequestStatus;
}

export interface JobStatus {
  list: JobStatusModel[];
}
export interface JobStatusMonth extends JobStatus {
  successRate?: number;
  totalJobs: number;
}
export interface ClientSize extends WithRequestStatus {
  list: ClientSizeModel[];
}

export interface UsageAndClients {
  list: UsageAndClientsModel[];
}
export interface SuspiciousFiles {
  list: SuspiciousFilesModel[];
}
export interface O365Usage {
  list: O365UsageModel[];
}

export interface StartPageStateSlice {
  readonly commCellLinkUrl: string;
  readonly backupSize: SizeModel & WithRequestStatus;
  readonly jobStatusDay: JobStatus & WithRequestStatus;
  readonly jobStatusWeek: JobStatus & WithRequestStatus;
  readonly jobStatusMonth: JobStatusMonth & WithRequestStatus;
  readonly clients: ClientsModel & WithRequestStatus;
  readonly usageAndClients: UsageAndClients & WithRequestStatus;
  readonly suspiciousFiles: SuspiciousFiles & WithRequestStatus;
  readonly clientSize: ClientSize;
  readonly o365Usage: O365Usage & WithRequestStatus;
}

export const SET_COMMCELL_LINK_URL = "SET_COMMCELL_LINK_URL";
export type SetCommCellLinkUrlAction = {
  type: typeof SET_COMMCELL_LINK_URL;
  payload: string;
};
export type SetCommCellLinkUrlAC = (payload: string) => SetCommCellLinkUrlAction;

export const SIZE_REQUEST = "SIZE_REQUEST";
export type SizeRequestAction = {
  type: typeof SIZE_REQUEST;
};
export type SizeRequestAC = () => SizeRequestAction;

export const SIZE_REQUEST_SUCCESS = "SIZE_REQUEST_SUCCESS";
export type SizeRequestSuccessAction = {
  type: typeof SIZE_REQUEST_SUCCESS;
  payload: SizeModel;
};
export type SizeRequestSuccessAC = (payload: SizeModel) => SizeRequestSuccessAction;

export const SIZE_REQUEST_FAILURE = "SIZE_REQUEST_FAILURE";
export type SizeRequestFailureAction = {
  type: typeof SIZE_REQUEST_FAILURE;
};
export type SizeRequestFailureAC = () => SizeRequestFailureAction;

export const JOB_STATUS_DAY_REQUEST = "JOB_STATUS_DAY_REQUEST";
export type JobStatusDayRequestAction = {
  type: typeof JOB_STATUS_DAY_REQUEST;
};
export type JobStatusDayRequestAC = () => JobStatusDayRequestAction;

export const JOB_STATUS_DAY_REQUEST_SUCCESS = "JOB_STATUS_DAY_REQUEST_SUCCESS";
export type JobStatusDayRequestSuccessAction = {
  type: typeof JOB_STATUS_DAY_REQUEST_SUCCESS;
  payload: JobStatusModel[];
};
export type JobStatusDayRequestSuccessAC = (payload: JobStatusModel[]) => JobStatusDayRequestSuccessAction;

export const JOB_STATUS_DAY_REQUEST_FAILURE = "JOB_STATUS_DAY_REQUEST_FAILURE";
export type JobStatusDayRequestFailureAction = {
  type: typeof JOB_STATUS_DAY_REQUEST_FAILURE;
};
export type JobStatusDayRequestFailureAC = () => JobStatusDayRequestFailureAction;

export const JOB_STATUS_WEEK_REQUEST = "JOB_STATUS_WEEK_REQUEST";
export type JobStatusWeekRequestAction = {
  type: typeof JOB_STATUS_WEEK_REQUEST;
};
export type JobStatusWeekRequestAC = () => JobStatusWeekRequestAction;

export const JOB_STATUS_WEEK_REQUEST_SUCCESS = "JOB_STATUS_WEEK_REQUEST_SUCCESS";
export type JobStatusWeekRequestSuccessAction = {
  type: typeof JOB_STATUS_WEEK_REQUEST_SUCCESS;
  payload: JobStatusModel[];
};
export type JobStatusWeekRequestSuccessAC = (payload: JobStatusModel[]) => JobStatusWeekRequestSuccessAction;

export const JOB_STATUS_WEEK_REQUEST_FAILURE = "JOB_STATUS_WEEK_REQUEST_FAILURE";
export type JobStatusWeekRequestFailureAction = {
  type: typeof JOB_STATUS_WEEK_REQUEST_FAILURE;
};
export type JobStatusWeekRequestFailureAC = () => JobStatusWeekRequestFailureAction;

export const JOB_STATUS_MONTH_REQUEST = "JOB_STATUS_MONTH_REQUEST";
export type JobStatusMonthRequestAction = {
  type: typeof JOB_STATUS_MONTH_REQUEST;
};
export type JobStatusMonthRequestAC = () => JobStatusMonthRequestAction;

export const JOB_STATUS_MONTH_REQUEST_SUCCESS = "JOB_STATUS_MONTH_REQUEST_SUCCESS";
export type JobStatusMonthRequestSuccessAction = {
  type: typeof JOB_STATUS_MONTH_REQUEST_SUCCESS;
  payload: JobStatusMonth;
};
export type JobStatusMonthRequestSuccessAC = (payload: JobStatusMonth) => JobStatusMonthRequestSuccessAction;

export const JOB_STATUS_MONTH_REQUEST_FAILURE = "JOB_STATUS_MONTH_REQUEST_FAILURE";
export type JobStatusMonthRequestFailureAction = {
  type: typeof JOB_STATUS_MONTH_REQUEST_FAILURE;
};
export type JobStatusMonthRequestFailureAC = () => JobStatusMonthRequestFailureAction;

export const CLIENTS_REQUEST = "CLIENTS_REQUEST";
export type ClientsRequestAction = {
  type: typeof CLIENTS_REQUEST;
};
export type ClientsRequestAC = () => ClientsRequestAction;

export const CLIENTS_REQUEST_SUCCESS = "CLIENTS_REQUEST_SUCCESS";
export type ClientsRequestSuccessAction = {
  type: typeof CLIENTS_REQUEST_SUCCESS;
  payload: ClientsModel;
};
export type ClientsRequestSuccessAC = (payload: ClientsModel) => ClientsRequestSuccessAction;

export const CLIENTS_REQUEST_FAILURE = "CLIENTS_REQUEST_FAILURE";
export type ClientsRequestFailureAction = {
  type: typeof CLIENTS_REQUEST_FAILURE;
};
export type ClientsRequestFailureAC = () => ClientsRequestFailureAction;

export const SUSPICIOUS_FILES_REQUEST = "SUSPICIOUS_FILES_REQUEST";
export type SuspiciousFilesRequestAction = {
  type: typeof SUSPICIOUS_FILES_REQUEST;
};
export type SuspiciousFilesRequestAC = () => SuspiciousFilesRequestAction;

export const SUSPICIOUS_FILES_REQUEST_SUCCESS = "SUSPICIOUS_FILES_REQUEST_SUCCESS";
export type SuspiciousFilesRequestSuccessAction = {
  type: typeof SUSPICIOUS_FILES_REQUEST_SUCCESS;
  payload: SuspiciousFilesModel[];
};
export type SuspiciousFilesRequestSuccessAC = (payload: SuspiciousFilesModel[]) => SuspiciousFilesRequestSuccessAction;

export const SUSPICIOUS_FILES_REQUEST_FAILURE = "SUSPICIOUS_FILES_REQUEST_FAILURE";
export type SuspiciousFilesRequestFailureAction = {
  type: typeof SUSPICIOUS_FILES_REQUEST_FAILURE;
};
export type SuspiciousFilesRequestFailureAC = () => SuspiciousFilesRequestFailureAction;

export const USAGE_AND_CLIENTS_REQUEST = "USAGE_AND_CLIENTS_REQUEST";
export type UsageAndClientsRequestAction = {
  type: typeof USAGE_AND_CLIENTS_REQUEST;
};
export type UsageAndClientsRequestAC = () => UsageAndClientsRequestAction;

export const USAGE_AND_CLIENTS_REQUEST_SUCCESS = "USAGE_AND_CLIENTS_REQUEST_SUCCESS";
export type UsageAndClientsRequestSuccessAction = {
  type: typeof USAGE_AND_CLIENTS_REQUEST_SUCCESS;
  payload: UsageAndClientsModel[];
};
export type UsageAndClientsRequestSuccessAC = (payload: UsageAndClientsModel[]) => UsageAndClientsRequestSuccessAction;

export const USAGE_AND_CLIENTS_REQUEST_FAILURE = "USAGE_AND_CLIENTS_REQUEST_FAILURE";
export type UsageAndClientsRequestFailureAction = {
  type: typeof USAGE_AND_CLIENTS_REQUEST_FAILURE;
};
export type UsageAndClientsRequestFailureAC = () => UsageAndClientsRequestFailureAction;

export const CLIENT_SIZE_REQUEST = "CLIENT_SIZE_REQUEST";
export type ClientSizeRequestAction = {
  type: typeof CLIENT_SIZE_REQUEST;
};
export type ClientSizeRequestAC = () => ClientSizeRequestAction;

export const CLIENT_SIZE_REQUEST_SUCCESS = "CLIENT_SIZE_REQUEST_SUCCESS";
export type ClientSizeRequestSuccessAction = {
  type: typeof CLIENT_SIZE_REQUEST_SUCCESS;
  payload: ClientSizeModel[];
};
export type ClientSizeRequestSuccessAC = (payload: ClientSizeModel[]) => ClientSizeRequestSuccessAction;

export const CLIENT_SIZE_REQUEST_FAILURE = "CLIENT_SIZE_REQUEST_FAILURE";
export type ClientSizeRequestFailureAction = {
  type: typeof CLIENT_SIZE_REQUEST_FAILURE;
};
export type ClientSizeRequestFailureAC = () => ClientSizeRequestFailureAction;

export const O365_USAGE_REQUEST = "O365_USAGE_REQUEST";
export type O365UsageRequestAction = {
  type: typeof O365_USAGE_REQUEST;
};
export type O365UsageRequestAC = () => O365UsageRequestAction;

export const O365_USAGE_REQUEST_SUCCESS = "O365_USAGE_REQUEST_SUCCESS";
export type O365UsageRequestSuccessAction = {
  type: typeof O365_USAGE_REQUEST_SUCCESS;
  payload: O365UsageModel[];
};
export type O365UsageRequestSuccessAC = (payload: O365UsageModel[]) => O365UsageRequestSuccessAction;

export const O365_USAGE_REQUEST_FAILURE = "O365_USAGE_REQUEST_FAILURE";
export type O365UsageRequestFailureAction = {
  type: typeof O365_USAGE_REQUEST_FAILURE;
};
export type O365UsageRequestFailureAC = () => O365UsageRequestFailureAction;

export type StartPageAction =
  | SetCommCellLinkUrlAction
  | SizeRequestAction
  | SizeRequestSuccessAction
  | SizeRequestFailureAction
  | JobStatusDayRequestAction
  | JobStatusDayRequestSuccessAction
  | JobStatusDayRequestFailureAction
  | JobStatusWeekRequestAction
  | JobStatusWeekRequestSuccessAction
  | JobStatusWeekRequestFailureAction
  | JobStatusMonthRequestAction
  | JobStatusMonthRequestSuccessAction
  | JobStatusMonthRequestFailureAction
  | ClientsRequestAction
  | ClientsRequestSuccessAction
  | ClientsRequestFailureAction
  | SuspiciousFilesRequestAction
  | SuspiciousFilesRequestSuccessAction
  | SuspiciousFilesRequestFailureAction
  | UsageAndClientsRequestAction
  | UsageAndClientsRequestSuccessAction
  | UsageAndClientsRequestFailureAction
  | ClientSizeRequestAction
  | ClientSizeRequestSuccessAction
  | ClientSizeRequestFailureAction
  | O365UsageRequestAction
  | O365UsageRequestSuccessAction
  | O365UsageRequestFailureAction;
