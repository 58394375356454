import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { usageReportsSelectors, usageReportsThunks } from "store/usageReports";
import { UsageType } from "types/usageReports.types";

const useFetchUsageReport = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { type, config } = useSelector(usageReportsSelectors.getUsage);

  useEffect(() => {
    if (selectedContractId) {
      switch (type) {
        case UsageType.Summary:
          thunkDispatch(usageReportsThunks.getUsageSummaryInfo(selectedContractId, config));
          break;
        case UsageType.Detailed:
          thunkDispatch(usageReportsThunks.getUsageDetailedInfo(selectedContractId, config));
          break;
      }
    }
  }, [thunkDispatch, selectedContractId, type, config]);

  return useSelector(usageReportsSelectors.getSlice);
};

export default useFetchUsageReport;
