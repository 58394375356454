import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card } from "@skyportal/ui-kit";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import CardTabs from "containers/CardTabs";
import { TabsConfigModel } from "containers/CardTabs/CardTabs";
import O365UsageReport from "./tabs/O365UsageReport";
import O365Users from "./tabs/O365Users";
import useFetchO365UsageReport from "hooks/useFetchO365UsageReport";
import useFetchO365Users from "hooks/useFetchO365Users";
import { usageReportsActions } from "store/usageReports";

import styles from "./styles.module.css";

const tabsConfig: TabsConfigModel[] = [
  { name: "usageReportTab", path: "/baas/o365-usage/report" },
  { name: "usersTab", path: "/baas/o365-usage/users" },
];

const O365UsagePageContent = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState<string>(
    tabsConfig.find((tab) => tab.path === pathname)?.name || tabsConfig[0].name
  );

  useFetchO365UsageReport();
  useFetchO365Users();

  useEffect(() => {
    setActiveTab(tabsConfig.find((tab) => tab.path === pathname)?.name || tabsConfig[0].name);
    dispatch(usageReportsActions.setUsageReportCurrentPageAC(1));
  }, [dispatch, pathname]);

  return (
    <Card className={styles.card} data-testid="o365UsagePageContent">
      <CardTabs tabsConfig={tabsConfig} localeObjectName="o365UsagePage" activeTab={activeTab} />
      <div className={styles.contentBlock}>
        <Switch>
          <Route path={`${path}/report`} component={O365UsageReport} />
          <Route path={`${path}/users`} component={O365Users} />
          <Route path="*">
            <Redirect to={`${path}/report`} />
          </Route>
        </Switch>
      </div>
    </Card>
  );
};

export default O365UsagePageContent;
