import { JobsStatusModel, JobsRequestConfig } from "types/clients.types";
import { RequestStatus } from "types/common.types";
import { RestoreJobsModel, RestoreJobsResponse } from "types/jobs.types";

export type SortFieldType = keyof RestoreJobsModel | "";
export type SortOrderType = "asc" | "desc";

export type RestoreJobsFiltersType = Omit<Record<keyof RestoreJobsModel, string>, "startTime" | "endTime">;

export interface RestoreJobsPageStateSlice {
  readonly statuses: JobsStatusModel;
  readonly list: RestoreJobsModel[];
  readonly config: JobsRequestConfig;
  readonly filters: RestoreJobsFiltersType;
  readonly requestStatus: RequestStatus;
  readonly sortField: SortFieldType;
  readonly sortOrder: SortOrderType;
  readonly currentPage: number;
  readonly perPage: number;
}

export const RESTORE_JOBS_REQUEST = "RESTORE_JOBS_REQUEST";
export type RestoreJobsRequestAction = {
  type: typeof RESTORE_JOBS_REQUEST;
};
export type RestoreJobsRequestAC = () => RestoreJobsRequestAction;

export const RESTORE_JOBS_REQUEST_SUCCESS = "RESTORE_JOBS_REQUEST_SUCCESS";
export type RestoreJobsRequestSuccessAction = {
  type: typeof RESTORE_JOBS_REQUEST_SUCCESS;
  payload: RestoreJobsResponse;
};
export type RestoreJobsRequestSuccessAC = (payload: RestoreJobsResponse) => RestoreJobsRequestSuccessAction;

export const RESTORE_JOBS_REQUEST_FAILURE = "RESTORE_JOBS_REQUEST_FAILURE";
export type RestoreJobsRequestFailureAction = {
  type: typeof RESTORE_JOBS_REQUEST_FAILURE;
};
export type RestoreJobsRequestFailureAC = () => RestoreJobsRequestFailureAction;

export const SET_RESTORE_JOBS_CONFIG = "SET_RESTORE_JOBS_CONFIG";
export type SetRestoreJobsConfigAction = { type: typeof SET_RESTORE_JOBS_CONFIG; payload: JobsRequestConfig };
export type SetRestoreJobsConfigAC = (payload: JobsRequestConfig) => SetRestoreJobsConfigAction;

export const SET_RESTORE_JOBS_FILTERS = "SET_RESTORE_JOBS_FILTERS";
export type SetRestoreJobsFiltersAction = { type: typeof SET_RESTORE_JOBS_FILTERS; payload: RestoreJobsFiltersType };
export type SetRestoreJobsFiltersAC = (payload: RestoreJobsFiltersType) => SetRestoreJobsFiltersAction;

export const SET_RESTORE_JOBS_SORT_FIELD = "SET_RESTORE_JOBS_SORT_FIELD";
export type SetRestoreJobsSortFieldAction = {
  type: typeof SET_RESTORE_JOBS_SORT_FIELD;
  payload: SortFieldType;
};
export type SetRestoreJobsSortFieldAC = (payload: SortFieldType) => SetRestoreJobsSortFieldAction;

export const SET_RESTORE_JOBS_PER_PAGE = "SET_RESTORE_JOBS_PER_PAGE";
export type SetRestoreJobsPerPageAction = {
  type: typeof SET_RESTORE_JOBS_PER_PAGE;
  payload: number;
};
export type SetRestoreJobsPerPageAC = (payload: number) => SetRestoreJobsPerPageAction;

export const SET_RESTORE_JOBS_CURRENT_PAGE = "SET_RESTORE_JOBS_CURRENT_PAGE";
export type SetRestoreJobsCurrentPageAction = {
  type: typeof SET_RESTORE_JOBS_CURRENT_PAGE;
  payload: number;
};
export type SetRestoreJobsCurrentPageAC = (payload: number) => SetRestoreJobsCurrentPageAction;

export type UsageReportPageAction =
  | RestoreJobsRequestAction
  | RestoreJobsRequestSuccessAction
  | RestoreJobsRequestFailureAction
  | SetRestoreJobsConfigAction
  | SetRestoreJobsFiltersAction
  | SetRestoreJobsSortFieldAction
  | SetRestoreJobsPerPageAction
  | SetRestoreJobsCurrentPageAction;
