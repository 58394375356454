export interface BreadcrumbsItem {
  label: string;
  path: string;
  dustinIcon?: string;
}

export interface BreadcrumbsConfigItem {
  label: string;
  crumbs?: BreadcrumbsItem[];
  dustinIcon?: string;
  match: { path: string; exact?: boolean };
}

const Backup: BreadcrumbsItem = {
  label: "Backup",
  path: "/baas",
  dustinIcon: "security-2",
};

const breadcrumbsConfig: BreadcrumbsConfigItem[] = [
  {
    label: Backup.label,
    dustinIcon: Backup.dustinIcon,
    match: {
      path: Backup.path,
      exact: true,
    },
  },
  {
    label: "O365 Usage",
    crumbs: [Backup],
    dustinIcon: "workplace",
    match: {
      path: "/baas/o365-usage",
    },
  },
  {
    label: "Usage Report",
    crumbs: [Backup],
    dustinIcon: "statistic",
    match: {
      path: "/baas/usage-report",
    },
  },
  {
    label: "Clients",
    crumbs: [Backup],
    dustinIcon: "stationary-box",
    match: {
      path: "/baas/clients",
      exact: true,
    },
  },
  {
    label: "Client Details",
    crumbs: [Backup],
    dustinIcon: "presentation",
    match: {
      path: "/baas/clients",
    },
  },
  {
    label: "Backup Jobs",
    crumbs: [Backup],
    dustinIcon: "docs",
    match: {
      path: "/baas/backup-jobs",
    },
  },
  {
    label: "Restore Jobs",
    crumbs: [Backup],
    dustinIcon: "time-ongoing",
    match: {
      path: "/baas/restore-jobs",
    },
  },
];

export default breadcrumbsConfig;
