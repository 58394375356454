import { backupApiClient } from "./clients";
import { JobsRequestConfig } from "types/clients.types";
import { BackupJobsResponse, RestoreJobsResponse } from "types/jobs.types";
import { formatDate } from "utils/time";

export const getBackupJobs = (contractId: string, requestConfig: JobsRequestConfig) =>
  backupApiClient.request<BackupJobsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/backup-jobs?startDate=${formatDate(
      requestConfig.startDate
    )}&endDate=${formatDate(requestConfig.endDate)}`,
  });

export const getRestoreJobs = (contractId: string, requestConfig: JobsRequestConfig) =>
  backupApiClient.request<RestoreJobsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/backup/restore-jobs?startDate=${formatDate(
      requestConfig.startDate
    )}&endDate=${formatDate(requestConfig.endDate)}`,
  });
