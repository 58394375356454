import config from "config";
import { SelectedContractId } from "store/contracts/contracts.types";

export const parseApiErrorMessage = (error: any): string | null => {
  if (!error || !error.isAxiosError) {
    return null;
  }

  try {
    const { msg: message } = error.response?.data;
    return message || null;
  } catch (e) {
    return null;
  }
};

export const getFilterOptions = <Type>(list: Type[], fieldName: keyof Type) => {
  const options = Array.from(new Set(list.flatMap((item) => item[fieldName]))).filter(Boolean);

  return options.map((option) => ({
    label: String(option),
    value: String(option),
  }));
};

export const generateCsvExportUrl = (selectedContractId: SelectedContractId, path: string) => {
  return [(window as any).BACKUP_API_URL, `/v1/contracts/${selectedContractId}/${path}?accept=csv`].join("");
};

export const generateReportUrl = (
  contractId: string,
  type: string,
  acceptType: string,
  params: Record<string, string | number> = {}
) => {
  return [
    config.backupApiUrl,
    `/v1/contracts/${contractId}/backup/${type}?`,
    [
      ...Object.keys(params)
        .filter((key) => Boolean(params[key]) || params[key] === 0)
        .map((key) => `${key}=${params[key]}`),
      `accept=${acceptType}`,
    ].join("&"),
  ].join("");
};

export const convertBytes = (bytes: string | number): string => {
  if (typeof bytes === "string") {
    bytes = Number(bytes);
  }
  if (isNaN(Number(bytes)) || bytes === null || bytes === undefined) {
    return "";
  }

  const units = ["bytes", "KB", "MB", "GB", "TB"];
  let i = 0;
  while (bytes >= 1024 && i < units.length - 1) {
    bytes /= 1024;
    i++;
  }
  return `${bytes.toFixed(2)} ${units[i]}`;
};

export const convertMegabytes = (megabytes: string | number): string => {
  if (typeof megabytes === "string") {
    megabytes = Number(megabytes);
  }
  if (isNaN(Number(megabytes)) || megabytes === null || megabytes === undefined) {
    return "";
  }

  const units = ["MB", "GB", "TB"];
  let i = 0;
  while (megabytes >= 1024 && i < units.length - 1) {
    megabytes /= 1024;
    i++;
  }
  return `${megabytes.toFixed(2)} ${units[i]}`;
};

export const convertGigabytes = (gigabytes: string | number): string => {
  if (typeof gigabytes === "string") {
    gigabytes = Number(gigabytes);
  }
  if (isNaN(Number(gigabytes)) || gigabytes === null || gigabytes === undefined) {
    return "";
  }

  const units = ["GB", "TB"];
  let i = 0;
  while (gigabytes >= 1024 && i < units.length - 1) {
    gigabytes /= 1024;
    i++;
  }
  return `${gigabytes.toFixed(2)} ${units[i]}`;
};

export const areFiltersEmpty = (filters: Object) => Object.values(filters).every((value) => value === "");
