import { BackupJobsModel } from "types/jobs.types";
import { convertBytes } from "utils/helpers";
import { getPaginationSlice, makeUniversalSort } from "utils/table";
import { RootState } from "../index";
import { BackupJobsFiltersType, BackupJobsPageStateSlice } from "./backupJobs.types";

export const getSlice = (state: RootState): BackupJobsPageStateSlice => state.backupJobs;

export const getBackupJobsList = (state: RootState) => getSlice(state).list;
export const getBackupJobsStatuses = (state: RootState) => getSlice(state).statuses;

const makeJobsStatusSortNormalizer = (item: BackupJobsModel) => item.jobStatus;
const makeClientSortNormalizer = (item: BackupJobsModel) => item.client;
const makeApplicationSortNormalizer = (item: BackupJobsModel) => item.application;
const makeLabelsSortNormalizer = (item: BackupJobsModel) =>
  Array.isArray(item.labels) ? item.labels.map((label) => label.name).join(", ") : item.labels;
const makeLevelSortNormalizer = (item: BackupJobsModel) => item.level;
const makeBackupSizeSortNormalizer = (item: BackupJobsModel) => Number(item.backupSize);
const makeStartTimeSortNormalizer = (item: BackupJobsModel) => item.startTime;
const makeEndTimeSortNormalizer = (item: BackupJobsModel) => item.endTime;
const makeElapsedTimeSizeSortNormalizer = (item: BackupJobsModel) => Number(item.elapsedTime);

const backupJobsComposer = ({
  list,
  sortField,
  sortOrder,
  currentPage,
  perPage,
  filters,
}: BackupJobsPageStateSlice) => {
  let _list: BackupJobsModel[] = [...list];

  const sortHandler: any = {
    jobStatus: makeUniversalSort(makeJobsStatusSortNormalizer),
    client: makeUniversalSort(makeClientSortNormalizer),
    application: makeUniversalSort(makeApplicationSortNormalizer),
    labels: makeUniversalSort(makeLabelsSortNormalizer),
    level: makeUniversalSort(makeLevelSortNormalizer),
    backupSize: makeUniversalSort(makeBackupSizeSortNormalizer),
    startTime: makeUniversalSort(makeStartTimeSortNormalizer),
    endTime: makeUniversalSort(makeEndTimeSortNormalizer),
    elapsedTime: makeUniversalSort(makeElapsedTimeSizeSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  _list = [..._list].map((job) => ({
    ...job,
    // convert size to suitable units of measure to show in table and use for filtering
    backupSize: convertBytes(job.backupSize),
    // convert labels to string to show in table and use for filtering
    labels: Array.isArray(job.labels) ? job.labels.map((label) => label.name).join(", ") : job.labels,
  }));

  Object.keys(filters).forEach((filterName) => {
    if (filters[filterName as keyof BackupJobsFiltersType]) {
      _list = _list.filter((item) =>
        item[filterName as keyof BackupJobsFiltersType]
          ? item[filterName as keyof BackupJobsFiltersType]
              .toString()
              .toLowerCase()
              .includes(filters[filterName as keyof BackupJobsFiltersType].toLowerCase())
          : null
      );
    }
  });

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getBackupJobsTable = (state: RootState) => {
  const backupJobsSlice = getSlice(state);
  return backupJobsComposer(backupJobsSlice);
};
