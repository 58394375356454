import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner, Table, TextInput } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useThunkDispatch } from "store";
import { clientsActions, clientsSelectors, clientsThunks } from "store/clients";
import { contractSelectors } from "store/contracts";
import {
  setClientListCurrentPageAC,
  setClientListPerPageAC,
  setClientListSortFieldAC,
} from "store/clients/clients.actions";
import PaginationBox from "containers/PaginationBox";
import { RequestStatus } from "types/common.types";
import CardFallback from "containers/CardFallback";
import { ClientModel } from "types/clients.types";
import { areFiltersEmpty, convertMegabytes } from "utils/helpers";

import styles from "../styles.module.css";

interface ClientListTableProps {
  setSelectedClient: (client: ClientModel) => void;
  setIsLabelsModalOpen: (isOpen: boolean) => void;
}

export const dummyClient: ClientModel = {
  clientId: "_",
  clientName: "_",
  application: "_",
  currentSize: "_",
  labels: "_",
};

const ClientListTable = ({ setSelectedClient, setIsLabelsModalOpen }: ClientListTableProps) => {
  const { t } = useTranslation("clientListPage");
  const dispatch = useDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { filters, sortField, sortOrder, perPage, currentPage, requestStatus } = useSelector(
    clientsSelectors.getClients
  );
  const { list, pageCount } = useSelector(clientsSelectors.getClientsListTable);

  const handleFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, fieldName) => {
      dispatch(clientsActions.setClientListFiltersAC({ ...filters, [fieldName]: event.target.value }));
      dispatch(clientsActions.setClientListCurrentPageAC(1));
    },
    [dispatch, filters]
  );

  const columnsConfig = useMemo(
    () => [
      {
        key: "clientName",
        dataIndex: "clientName",
        title: t("table.clientName"),
        width: "20%",
        render: (clientName: ClientModel["clientName"], item: ClientModel) =>
          clientName !== "_" ? (
            <Link
              to={{ pathname: `/baas/clients/${item.clientId}`, search: `?contractId=${selectedContractId}` }}
              className={styles.clientLink}
            >
              {clientName}
            </Link>
          ) : (
            <TextInput
              data-testid="tableFilter-clientName"
              value={filters.clientName}
              onChange={(e) => handleFilterChange(e, "clientName")}
            />
          ),
      },
      {
        key: "application",
        dataIndex: "application",
        title: t("table.application"),
        width: "20%",
        render: (application: ClientModel["application"]) =>
          application !== "_" ? (
            application
          ) : (
            <TextInput
              data-testid="tableFilter-application"
              value={filters.application}
              onChange={(e) => handleFilterChange(e, "application")}
            />
          ),
      },
      {
        key: "currentSize",
        dataIndex: "currentSize",
        title: t("table.currentSize"),
        width: "20%",
        render: (currentSize: ClientModel["currentSize"]) =>
          currentSize !== "_" ? (
            convertMegabytes(currentSize as number)
          ) : (
            <TextInput
              data-testid="tableFilter-currentSize"
              value={filters.currentSize}
              onChange={(e) => handleFilterChange(e, "currentSize")}
            />
          ),
      },
      {
        key: "labels",
        dataIndex: "labels",
        title: t("table.labels"),
        render: (labels: ClientModel["labels"]) =>
          labels !== "_" ? (
            labels
          ) : (
            <TextInput
              data-testid="tableFilter-labels"
              value={filters.labels}
              onChange={(e) => handleFilterChange(e, "labels")}
            />
          ),
      },
      {
        key: "actionButtons",
        dataIndex: "actionButtons",
        title: "",
        sortDisabled: true,
        width: "20%",
        render: (_: undefined, item: ClientModel) =>
          item.clientId !== "_" ? (
            <div className={styles.actionBtns}>
              <Button
                data-testid={`addLabelsBtn-${item.clientId}`}
                size="small"
                onClick={() => {
                  setSelectedClient(item);
                  setIsLabelsModalOpen(true);
                }}
              >
                {t("table.addLabels")}
              </Button>
              <Link to={{ pathname: `/baas/clients/${item.clientId}`, search: `?contractId=${selectedContractId}` }}>
                <Button type="secondary" size="small">
                  {t("table.viewDetails")}
                </Button>
              </Link>
            </div>
          ) : null,
      },
    ],
    [
      t,
      filters.clientName,
      filters.application,
      filters.currentSize,
      filters.labels,
      handleFilterChange,
      selectedContractId,
      setSelectedClient,
      setIsLabelsModalOpen,
    ]
  );

  const handleSortFieldChange = useCallback((field) => dispatch(setClientListSortFieldAC(field)), [dispatch]);
  const handlePerPageChange = useCallback((value) => dispatch(setClientListPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setClientListCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const reloadData = useCallback(() => {
    thunkDispatch(clientsThunks.getClientListInfo(selectedContractId));
    thunkDispatch(clientsThunks.getLabelListInfo(selectedContractId));
  }, [selectedContractId, thunkDispatch]);

  useEffect(() => {
    //set title for "labels" cell to show tooltip on hover
    document.querySelectorAll("tr td:nth-of-type(4)").forEach((cell, index) => {
      if (index !== 0) cell.setAttribute("title", list[index - 1]?.labels);
    });
  }, [list]);

  return (
    <>
      {requestStatus === RequestStatus.PENDING && <Spinner show />}
      {requestStatus === RequestStatus.SUCCESS && (
        <>
          <div className={styles.tableBlock}>
            <Table
              dataSource={!list.length && areFiltersEmpty(filters) ? [] : [dummyClient, ...list]}
              columns={columnsConfig}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortFieldChange={handleSortFieldChange}
            />
          </div>
          <PaginationBox
            perPage={perPage}
            currentPage={currentPage}
            pageCount={pageCount}
            onPerPageChange={handlePerPageChange}
            onChangePage={handlePageChange}
          />
        </>
      )}
      {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
    </>
  );
};

export default ClientListTable;
