import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import { composeChartOptions } from "./utils";
import { Card, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";
import { startPageSelectors, startPageThunks } from "store/startPage";

import styles from "../../styles.module.css";

const O365UsageChart = () => {
  const { t } = useTranslation("backupStartPage");
  const { list, requestStatus } = useSelector(startPageSelectors.getO365Usage);

  const chartOptions = useMemo(() => composeChartOptions(list), [list]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(
    () => thunkDispatch(startPageThunks.getO365UsageInfo(selectedContractId)),
    [selectedContractId, thunkDispatch]
  );

  return (
    <Card
      className={styles.card}
      title={
        <h3>
          <Link to="/baas/o365-usage" className={styles.cardTitle}>
            {t("o365UsageAndClientsChartTitle")}
          </Link>
        </h3>
      }
      data-testid="o365UsageAndClientsChartContent"
    >
      <div className={styles.cardContent}>
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && <HighchartsReact highcharts={Highcharts} options={chartOptions} />}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default O365UsageChart;
