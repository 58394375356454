import { AppThunk } from "store";
import * as startPageApi from "api/startPage.api";
import * as startPageActions from "./startPage.actions";
import i18n from "../../locales";
import { notificationErrorAC } from "../notification/notification.actions";
import { parseApiErrorMessage } from "utils/helpers";

export const getCommCellLinkUrlInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      const { data } = await startPageApi.getCommCellLinkUrl(selectedContractId);
      dispatch(startPageActions.setCommCellLinkUrlAC(data.link.url));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "COMMCELL LINK URL REQUEST");
    }
  };

export const getBackupSizeInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.sizeRequestAC());
      const { data } = await startPageApi.getSize(selectedContractId);
      dispatch(startPageActions.sizeRequestSuccessAC(data.backupSize));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "BACKUP SIZE REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:backupSizeRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.sizeRequestFailureAC());
    }
  };

export const getJobStatusDayInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.jobStatusDayRequestAC());
      const { data } = await startPageApi.getJobStatus(selectedContractId, "24hours");
      dispatch(startPageActions.jobStatusDayRequestSuccessAC(data.jobStatus));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "JOB STATUS DAY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:jobStatusDayRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.jobStatusDayRequestFailureAC());
    }
  };

export const getJobStatusWeekInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.jobStatusWeekRequestAC());
      const { data } = await startPageApi.getJobStatus(selectedContractId, "7days");
      dispatch(startPageActions.jobStatusWeekRequestSuccessAC(data.jobStatus));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "JOB STATUS WEEK REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:jobStatusWeekRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.jobStatusWeekRequestFailureAC());
    }
  };

export const getJobStatusMonthInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.jobStatusMonthRequestAC());
      const { data } = await startPageApi.getJobStatus(selectedContractId, "4weeks");
      dispatch(
        startPageActions.jobStatusMonthRequestSuccessAC({
          list: data.jobStatus,
          successRate: data.jobStatus.find((category) => category.status === "Completed")?.rate,
          totalJobs: data.jobStatus.reduce((acc, cur) => acc + cur.count, 0),
        })
      );
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "JOB STATUS MONTH REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:jobStatusMonthRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.jobStatusMonthRequestFailureAC());
    }
  };

export const getClientsInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.clientsRequestAC());
      const { data } = await startPageApi.getClients(selectedContractId);
      dispatch(startPageActions.clientsRequestSuccessAC(data.clients));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "CLIENTS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:clientsRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.clientsRequestFailureAC());
    }
  };

export const getSuspiciousFilesInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.suspiciousFilesRequestAC());
      const { data } = await startPageApi.getSuspiciousFiles(selectedContractId);
      dispatch(startPageActions.suspiciousFilesRequestSuccessAC(data.suspiciousFiles));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "SUSPICIOUS FILES REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:suspiciousFilesRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.suspiciousFilesRequestFailureAC());
    }
  };

export const getUsageAndClientsInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.usageAndClientsRequestAC());
      const { data } = await startPageApi.getUsageAndClients(selectedContractId);
      dispatch(startPageActions.usageAndClientsRequestSuccessAC(data.usage));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "USAGE AND CLIENTS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:usageAndClientsRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.usageAndClientsRequestFailureAC());
    }
  };

export const getClientSizeInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.clietSizeRequestAC());
      const { data } = await startPageApi.getClientSize(selectedContractId);
      dispatch(startPageActions.clietSizeRequestSuccessAC(data.clientSize));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "CLIENT SIZE REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:clientSizeRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.clietSizeRequestFailureAC());
    }
  };

export const getO365UsageInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startPageActions.o365UsageRequestAC());
      const { data } = await startPageApi.getO365Usage(selectedContractId);
      dispatch(startPageActions.o365UsageRequestSuccessAC(data.usage));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "O365 USAGE REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:o365UsageRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(startPageActions.o365UsageRequestFailureAC());
    }
  };
