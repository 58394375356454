import React from "react";
import { Button } from "@skyportal/ui-kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ContractDropdown from "containers/ContractDropdown";
import Breadcrumbs from "containers/Breadcrumbs";
import { startPageSelectors } from "store/startPage";

import styles from "./styles.module.css";

export interface PageHeaderProps {
  title: React.ReactNode;
  withNavigationLinks?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, withNavigationLinks = false }) => {
  const { t } = useTranslation("backupStartPage");

  const commCellLinkUrl = useSelector(startPageSelectors.getCommCellLinkUrl);
  return (
    <header className={styles.root}>
      {withNavigationLinks ? (
        <div className={styles.titleBlock}>
          <h1 className={styles.title}>{title}</h1>
          <Breadcrumbs />
        </div>
      ) : (
        <h1 className={styles.title}>{title}</h1>
      )}
      <div className={styles.subtitleBlock}>
        <ContractDropdown className={styles.contractDropdown} />
        {withNavigationLinks && (
          <div>
            <a href={commCellLinkUrl} target="_blank" rel="noreferrer">
              <Button type="secondary" rightIcon="arrow-right" disabled={!commCellLinkUrl}>
                {t("commcellLinkText")}
              </Button>
            </a>
          </div>
        )}
        {!withNavigationLinks ? <Breadcrumbs /> : null}
      </div>
    </header>
  );
};

export default PageHeader;
