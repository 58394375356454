// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_clientDetailsErrorFallback__xEl3B {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-top: 250px;\n  gap: 16px;\n}\n\n.styles_clientDetailsErrorFallbackButtons__Z4D6- {\n  display: flex;\n  gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ClientDetailsPageContent/ClientDetailsPageFallback/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":[".clientDetailsErrorFallback {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-top: 250px;\n  gap: 16px;\n}\n\n.clientDetailsErrorFallbackButtons {\n  display: flex;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clientDetailsErrorFallback": "styles_clientDetailsErrorFallback__xEl3B",
	"clientDetailsErrorFallbackButtons": "styles_clientDetailsErrorFallbackButtons__Z4D6-"
};
export default ___CSS_LOADER_EXPORT___;
