import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import StartPage from "pages/StartPage";
import UsagePage from "pages/UsagePage";
import O365UsagePage from "pages/O365UsagePage";
import ClientListPage from "pages/ClientListPage";
import ClientDetailsPage from "pages/ClientDetailsPage";
import BackupJobsPage from "pages/BackupJobsPage";
import RestoreJobsPage from "pages/RestoreJobsPage";

export const AppRouter: React.FC = () => (
  <Switch>
    <Route path="/baas" exact component={StartPage} />
    <Route path="/baas/usage-report" component={UsagePage} />
    <Route path="/baas/o365-usage" component={O365UsagePage} />
    <Route path="/baas/clients" exact component={ClientListPage} />
    <Route path="/baas/clients/:id" exact component={ClientDetailsPage} />
    <Route path="/baas/backup-jobs" component={BackupJobsPage} />
    <Route path="/baas/restore-jobs" component={RestoreJobsPage} />
    <Route path="*">
      <Redirect to="/baas" />
    </Route>
  </Switch>
);

export default AppRouter;
