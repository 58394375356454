import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Table, TextInput } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { usageReportsActions, usageReportsSelectors, usageReportsThunks } from "store/usageReports";
import {
  setUsageReportCurrentPageAC,
  setUsageReportPerPageAC,
  setUsageReportSortFieldAC,
} from "store/usageReports/usageReports.actions";
import PaginationBox from "containers/PaginationBox";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";
import { DetailedUsageModel } from "types/usageReports.types";
import { areFiltersEmpty, convertMegabytes } from "utils/helpers";

import styles from "./styles.module.css";

export const dummyDetailedUsage: DetailedUsageModel = {
  client: "_",
  application: "_",
  instance: "_",
  minSize: "_",
  maxSize: "_",
};

const UsageDetailedReport = () => {
  const { t } = useTranslation("usagePage");
  const dispatch = useDispatch();
  const { config, filters, requestStatus } = useSelector(usageReportsSelectors.getUsage);
  const { sortField, sortOrder, perPage, currentPage } = useSelector(usageReportsSelectors.getSlice);
  const { list, pageCount } = useSelector(usageReportsSelectors.getUsageDetailedTable);

  const handleFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, fieldName) => {
      dispatch(usageReportsActions.setUsageFiltersAC({ ...filters, [fieldName]: event.target.value }));
      dispatch(usageReportsActions.setUsageReportCurrentPageAC(1));
    },
    [dispatch, filters]
  );

  const columnsConfig = useMemo(
    () => [
      {
        key: "client",
        dataIndex: "client",
        title: t("usageDetailedTable.client"),
        render: (client: DetailedUsageModel["client"]) =>
          client !== "_" ? (
            client
          ) : (
            <TextInput
              data-testid="tableFilter-client"
              value={filters.client}
              onChange={(e) => handleFilterChange(e, "client")}
            />
          ),
      },
      {
        key: "application",
        dataIndex: "application",
        title: t("usageDetailedTable.application"),
        render: (application: DetailedUsageModel["application"]) =>
          application !== "_" ? (
            application
          ) : (
            <TextInput
              data-testid="tableFilter-application"
              value={filters.application}
              onChange={(e) => handleFilterChange(e, "application")}
            />
          ),
      },
      {
        key: "instance",
        dataIndex: "instance",
        title: t("usageDetailedTable.instance"),
        render: (instance: DetailedUsageModel["instance"]) =>
          instance !== "_" ? (
            instance
          ) : (
            <TextInput
              data-testid="tableFilter-instance"
              value={filters.instance}
              onChange={(e) => handleFilterChange(e, "instance")}
            />
          ),
      },
      {
        key: "minSize",
        dataIndex: "minSize",
        title: t("usageDetailedTable.minSize"),
        render: (minSize: DetailedUsageModel["minSize"]) =>
          minSize !== "_" ? (
            convertMegabytes(minSize as number)
          ) : (
            <TextInput
              data-testid="tableFilter-minSize"
              value={filters.minSize}
              onChange={(e) => handleFilterChange(e, "minSize")}
            />
          ),
      },
      {
        key: "maxSize",
        dataIndex: "maxSize",
        title: t("usageDetailedTable.maxSize"),
        render: (maxSize: DetailedUsageModel["maxSize"]) =>
          maxSize !== "_" ? (
            convertMegabytes(maxSize as number)
          ) : (
            <TextInput
              data-testid="tableFilter-maxSize"
              value={filters.maxSize}
              onChange={(e) => handleFilterChange(e, "maxSize")}
            />
          ),
      },
    ],
    [filters, handleFilterChange, t]
  );

  const handleSortFieldChange = useCallback((field) => dispatch(setUsageReportSortFieldAC(field)), [dispatch]);
  const handlePerPageChange = useCallback((value) => dispatch(setUsageReportPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setUsageReportCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(
    () => thunkDispatch(usageReportsThunks.getUsageDetailedInfo(selectedContractId, config)),
    [selectedContractId, config, thunkDispatch]
  );

  return (
    <>
      {requestStatus === RequestStatus.PENDING && (
        <div className={styles.table}>
          <Spinner show />
        </div>
      )}
      {requestStatus === RequestStatus.SUCCESS && (
        <>
          <div className={styles.tableBlock}>
            <Table
              dataSource={!list.length && areFiltersEmpty(filters) ? [] : [dummyDetailedUsage, ...list]}
              columns={columnsConfig}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortFieldChange={handleSortFieldChange}
            />
          </div>
          <PaginationBox
            perPage={perPage}
            currentPage={currentPage}
            pageCount={pageCount}
            onPerPageChange={handlePerPageChange}
            onChangePage={handlePageChange}
          />
        </>
      )}
      {requestStatus === RequestStatus.FAILURE && (
        <div className={styles.table}>
          <CardFallback onReload={reloadData} />
        </div>
      )}
    </>
  );
};

export default UsageDetailedReport;
